// React-Table-6 Draggable Tr Custom Component
import React from 'react';
import PropTypes from 'prop-types';
import RTTrComp from './RTTrComp';

// import ReactTable from "react-table";
import { /*DragDropContext, Droppable,*/ Draggable } from 'react-beautiful-dnd';

class RTTrCompDraggable extends React.Component {
	render() {
		const { rowInfo, uniqueKey, isDraggable, ...rest } = this.props;
		if (rowInfo) {
			const { original, index } = rowInfo;
			const uniqueVal = original[uniqueKey];
			return (
				<Draggable
					key={uniqueVal}
					index={index}
					draggableId={uniqueVal}
					isDragDisabled={!isDraggable}
				>
					{(draggableProvided, draggableSnapshot) => (
						<div
							ref={draggableProvided.innerRef}
							{...draggableProvided.draggableProps}
							// {...draggableProvided.dragHandleProps}
						>
							<RTTrComp
								{...rest}
								// overriding the default 'draggable' flag due to apparent bug in the library. It seems to always return draggable false
								dragHandleProps={{
									...(draggableProvided.dragHandleProps || {}),
									draggable: isDraggable,
								}}
								disabled={!isDraggable}
								isDragging={isDraggable ? draggableSnapshot.isDragging : undefined}
							/>
						</div>
					)}
				</Draggable>
			);
		} else {
			return <RTTrComp {...rest} />;
		}
	}
}

RTTrCompDraggable.propTypes = {
	// classes: PropTypes.object.isRequired,
	// isRowSelected: PropTypes.bool,
	// editAction: PropTypes.func,
	// deleteAction: PropTypes.func,
	// saveAction: PropTypes.func,
	// cancelAction: PropTypes.func,
	rowInfo: PropTypes.object,
	uniqueKey: PropTypes.string, // the unique key name in the original row data
	isDraggable: PropTypes.bool,
};

RTTrCompDraggable.defaultProps = {
	// isRowSelected: false,
	// editAction: undefined,
	// deleteAction: undefined,
	// saveAction: undefined,
	// cancelAction: undefined,
	isDraggable: true,
};

export default RTTrCompDraggable;
