import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, ListItemIcon, ListItemText, Collapse, List, Link } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import { NavLink } from 'react-router-dom';
import {
	Person as AccountIcon,
	AccountBox as ProfileIcon,
	PowerSettingsNew as LogoutIcon,
	OpenInNew as OpenInNewIcon,
	ViewComfy as CategoryIcon,
	// Language as LangIcon,
	ArrowDropDown as DropDownIcon,
} from '@mui/icons-material';
import cx from 'classnames';
import { AppLocales } from 'lang';

import PerfectScrollWrapper from 'components/PerfectScrollWrapper/PerfectScrollWrapper.jsx';

// Intl Lang
import { useIntl } from 'react-intl';

// redux
import { connect } from 'react-redux';
import { changeLocale, userLogout } from 'redux/actions'; // actions

// styles
import useStyles from './HeaderLinksStyle';

const categoryData = [
	{ name: 'cate0' },
	{
		name: 'cate1',
		children: [{ name: 'cate1-sub1' }, { name: 'cate1-sub2' }],
	},
	{
		name: 'cate2',
		children: [
			{
				name: 'cate2-sub1',
				children: [{ name: 'cate1-sub1-child1' }, { name: 'cate1-sub2-child2' }],
			},
			{ name: 'cate2-sub2' },
		],
	},
	{ name: 'cate3' },
	{
		name: 'cate4',
		children: [{ name: 'cate4-sub1' }, { name: 'cate4-sub2' }],
	},
	{ name: 'cate5' },
];

function HeaderLinks(props) {
	let classes = useStyles();
	let intl = useIntl();
	let { lang, changeLocale, userLogout, startleLoginUrl, enableCategory, enableLangSwitch } = props;
	let appLocale = AppLocales[lang.locale];

	// local
	let [profileMenu, setProfileMenu] = useState(null);
	let [categoryMenu, setCategoryMenu] = useState(null);
	let [langMenu, setLangMenu] = useState(null);
	// var [isSearchOpen, setSearchOpen] = useState(false);
	let [openedCateCollapse, setOpenedCateCollapse] = useState('');

	let isCateCollapseIn = (cate) => {
		return cate.name === openedCateCollapse;
	};

	let profile = (
		<List className={classes.list}>
			{
				// 	<ListItemButton className={classes.item}>
				// 	<NavLink
				// 		to={'#'}
				// 		className={classes.itemLink}
				// 		onClick={() => {
				// 			setProfileMenu(null);
				// 		}}
				// 	>
				// 		<ListItemIcon className={classes.itemIcon}>
				// 			<ProfileIcon />
				// 		</ListItemIcon>
				// 		<ListItemText
				// 			primary={intl.formatMessage({ id: 'components.HeaderBar.HeaderLinks.ProfileText' })}
				// 			disableTypography
				// 			className={classes.itemText}
				// 		/>
				// 	</NavLink>
				// </ListItemButton>
			}
			{startleLoginUrl && (
				<ListItemButton className={classes.item}>
					<Link
						href={startleLoginUrl}
						rel="noreferrer"
						target="_blank"
						underline="none"
						className={classes.itemLink}
					>
						<ListItemIcon className={classes.itemIcon}>
							<OpenInNewIcon />
						</ListItemIcon>
						<ListItemText
							primary={intl.formatMessage({
								id: 'components.HeaderBar.HeaderLinks.startleLoginText',
							})}
							disableTypography
							className={classes.itemText}
						/>
					</Link>
				</ListItemButton>
			)}

			<ListItemButton className={classes.item}>
				<NavLink
					to={'#'}
					className={classes.itemLink}
					onClick={() => {
						setProfileMenu(null);
						userLogout();
					}}
				>
					<ListItemIcon className={classes.itemIcon}>
						<LogoutIcon />
					</ListItemIcon>
					<ListItemText
						primary={intl.formatMessage({ id: 'components.HeaderBar.HeaderLinks.LogoutText' })}
						disableTypography
						className={classes.itemText}
					/>
				</NavLink>
			</ListItemButton>
		</List>
	);

	let languages = (
		<List className={classes.list}>
			{Object.keys(AppLocales).map((locale) => {
				return (
					<ListItemButton
						key={`list-lang-${locale}`}
						className={cx(classes.item, classes.itemLink)}
						onClick={() => {
							setLangMenu(null);
							changeLocale(locale);
						}}
					>
						<ListItemIcon className={classes.itemIcon}>
							<img src={AppLocales[locale].flag} height="20" alt={`${locale}-icon`} />
						</ListItemIcon>
						<ListItemText
							primary={AppLocales[locale].name}
							disableTypography
							className={classes.itemText}
						/>
					</ListItemButton>
				);
			})}
		</List>
	);

	let categories = (
		<List className={classes.list}>
			{categoryData.map((cate) => {
				if (cate.children && Array.isArray(cate.children) && cate.children.length > 0) {
					// category has children. Use Collapse
					return (
						<ListItemButton key={`list-item-${cate.name}`} className={classes.item}>
							<NavLink
								to={'#'}
								className={classes.itemLink}
								onClick={() => {
									setOpenedCateCollapse(cate.name);
								}}
							>
								<ListItemIcon className={classes.itemIcon}>
									<ProfileIcon />
								</ListItemIcon>
								<ListItemText
									primary={cate.name}
									secondary={
										<div
											className={cx(classes.caret, {
												[classes.caretActive]: isCateCollapseIn(cate),
											})}
											// onClick={e => {
											// 	// setOpenedCateCollapse(cate.name);
											// 	// // e.preventDefault();
											// 	// // e.stopImmediatePropagation();
											// 	// e.stopPropagation();
											// 	// return false;
											// }}
										/>
									}
									disableTypography
									className={classes.itemText}
								/>
							</NavLink>
							<Collapse in={isCateCollapseIn(cate)} unmountOnExit>
								<List
									className={cx(classes.list, classes.collapseList, classes.collapsesListNormal)}
								>
									{cate.children.map((subCate) => {
										return (
											<ListItemButton
												key={`list-item-collapse-${subCate.name}`}
												className={classes.collapseItem}
											>
												<NavLink
													to={'#'}
													className={classes.collapseItemLink}
													onClick={() => setCategoryMenu(null)}
												>
													<ListItemIcon className={classes.collapseItemIcon}>
														<ProfileIcon />
													</ListItemIcon>

													<ListItemText
														primary={subCate.name}
														disableTypography
														className={classes.collapseItemText}
													/>
												</NavLink>
											</ListItemButton>
										);
									})}
								</List>
							</Collapse>
						</ListItemButton>
					);
				}
				return (
					<ListItemButton key={`list-${cate.name}`} className={classes.item}>
						<NavLink
							to={'#'}
							className={classes.itemLink}
							onClick={() => {
								setCategoryMenu(null);
							}}
						>
							<ListItemIcon className={classes.itemIcon}>
								<ProfileIcon />
							</ListItemIcon>
							<ListItemText primary={cate.name} disableTypography className={classes.itemText} />
						</NavLink>
					</ListItemButton>
				);
			})}
		</List>
	);

	return (
		<div>
			{enableCategory && (
				<IconButton
					aria-haspopup="true"
					color="inherit"
					className={classes.headerMenuButton}
					style={{ borderRadius: '4px' }}
					aria-controls="category-menu"
					onClick={(e) => setCategoryMenu(e.currentTarget)}
				>
					<CategoryIcon className={classes.headerIcon} />
				</IconButton>
			)}

			{enableLangSwitch && (
				<IconButton
					aria-haspopup="true"
					color="inherit"
					style={{ borderRadius: '4px' }}
					aria-controls="lang-menu"
					className={cx(classes.headerMenuButton)}
					onClick={(e) => setLangMenu(e.currentTarget)}
				>
					<img src={appLocale.flag} height="20" alt="current-lang-icon" />
					<DropDownIcon className={classes.headerIcon} />
				</IconButton>
			)}
			<IconButton
				aria-haspopup="true"
				color="inherit"
				className={classes.headerMenuButton}
				aria-controls="profile-menu"
				onClick={(e) => setProfileMenu(e.currentTarget)}
			>
				<AccountIcon className={classes.headerIcon} />
			</IconButton>
			<Menu
				id="profile-menu"
				open={Boolean(profileMenu)}
				anchorEl={profileMenu}
				onClose={() => setProfileMenu(null)}
				// getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				disableAutoFocusItem
			>
				{
					// profile
					<div>
						<PerfectScrollWrapper component={profile} className={cx(classes.profileWrapper)} />
					</div>
				}
			</Menu>
			<Menu
				id="category-menu"
				open={Boolean(categoryMenu)}
				anchorEl={categoryMenu}
				onClose={() => setCategoryMenu(null)}
				// getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				disableAutoFocusItem
			>
				{
					// categories
					<div>
						<PerfectScrollWrapper component={categories} className={cx(classes.categoryWrapper)} />
					</div>
				}
			</Menu>
			<Menu
				id="lang-menu"
				open={Boolean(langMenu)}
				anchorEl={langMenu}
				onClose={() => setLangMenu(null)}
				// getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				disableAutoFocusItem
			>
				{
					// languages
					<div>
						<PerfectScrollWrapper component={languages} className={cx(classes.langWrapper)} />
					</div>
				}
			</Menu>
		</div>
	);
}

HeaderLinks.propTypes = {
	lang: PropTypes.object.isRequired,
	startleLoginUrl: PropTypes.string,
	changeLocale: PropTypes.func.isRequired,
	userLogout: PropTypes.func.isRequired,
	enableCategory: PropTypes.bool,
	enableLangSwitch: PropTypes.bool,
};

HeaderLinks.defaultProps = {
	enableCategory: false,
	enableLangSwitch: false,
};

const mapStateToProps = (state) => {
	return {
		lang: state.lang,
		startleLoginUrl: state.usermanager.userData.startleLoginUrl,
	};
};

export default connect(mapStateToProps, { changeLocale, userLogout })(HeaderLinks);
