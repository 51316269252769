import React, { useState, useEffect } from 'react';

// import cx from 'classnames';
import PropTypes from 'prop-types';

// Constants
import { ART_VARIABLES, ESIGN_PROOF_MIN_UL, ESIGN_ADMIN_MIN_UL } from '../../Constants';
import { ROUTES_PATH_ARTWORK_CREATOR, getRouteManageSpreadsheet } from 'routes';

import makeStyles from '@mui/styles/makeStyles';
import {
	Typography,
	Button,
	// IconButton,
	ButtonGroup,
	// Select,
	Checkbox,
	// Collapse,
	Chip,
	Menu,
	MenuItem,
	ListItemText,
	Tooltip,
	ListSubheader,
	Box,
} from '@mui/material';

import {
	FilterList as FilterIcon,
	Help as HelpIcon,
	ShoppingCartOutlined as SelectionIcon,
	ImageOutlined as CreateOwnIcon,
	PrintOutlined as PrintIcon,
	Clear as ClearIcon,
	FormatListBulletedOutlined as SpreadsheetIcon,
	CheckBoxOutlined as ProofIcon,
	AddToPhotos as SelectArtTemplateIcon,
	Save as BatchSaveIcon,
} from '@mui/icons-material';

// custom components
import { EnhancedRTable, SearchV2 } from 'components';
import { DividerVer, StyledOutlinedTextFieldSelection } from '../CustomMUI/CustomMUI';

// Esign components
import ArtLoading from '../ArtLoading/ArtLoading';
import EditableTextCell from './EditableTextCell';
import NumInput from './NumInput';
import { Filters, FilterChips } from './Filters';
import ProofDialog from './ProofDialog';
import PrintDialog from './PrintDialog';
import BatchDialog from './BatchDialog';

// react-table hooks
import { usePagination, useRowState, useSortBy, useTable } from 'react-table';

// API
import { fileMgrFetchSSById, fileMgrFetchSSEsignFilters } from 'restful';

import { format as dateFormat } from 'date-fns';
import { _ } from 'utils/libHelper';
import { getDomainConfig } from 'utils/appHelper';
import { batchingEsignForServerProcess } from 'utils/artwork/artworkGenerator';
import { genRandomStr } from 'utils/generalHelper';

import {
	initEsignData,
	getArtworkTemplate,
	buildPrintData,
	buildFieldsInputData,
	convertSSContentToRTableData,
} from './esignUtils.jsx';

// intl lang
import { useIntl } from 'react-intl';

// redux
import { connect } from 'react-redux';
import {
	notifyGeneral,
	/* fetchAWSCredential, resetAWSCredential, */ openGlobalDialog,
} from 'redux/actions'; // actions

// CSS
const useStyles = makeStyles((theme) => ({
	esignRoot: {
		...theme.pageWrapper,
	},
	esignBody: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		// flexWrap: 'wrap',
	},
	nonTableArea: {
		width: '100%',
		height: ART_VARIABLES.cssStyles.nonTableAreaHeight,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		// flex: '0 1 auto',
		fontSize: 14,
		overflowY: 'hidden',
		overflowX: 'auto',
	},
	tableWrapper: {
		width: '100%',
		height: `calc(100% - ${ART_VARIABLES.cssStyles.nonTableAreaHeight}px)`,
		// overflow: 'auto',
	},
	titleRow: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	controlRow: {
		padding: theme.spacing(0.25, 0),
		display: 'flex',
		// overflow: 'auto',
		alignItems: 'center',
		// justifyContent: 'initial',
		fontSize: 'inherit',
	},
	infoRow: {
		padding: theme.spacing(0.25, 0),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		fontSize: 'inherit',
	},
	adjustWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	selectOnRight: {
		margin: 0,
		padding: 0,
		paddingLeft: theme.spacing(1),
	},
	selectOnRightInputMarginDense: {
		paddingTop: theme.spacing(0.7),
		paddingBottom: theme.spacing(0.7),
	},
	button: {
		padding: theme.spacing(0.5, 1),
		fontSize: '0.8rem',
		whiteSpace: 'nowrap',
	},
	searchWrapper: {
		textAlign: 'left',
		flex: '1 0 auto',
		fontSize: 'inherit',
		display: 'flex',
		alignItems: 'center',
		maxWidth: 500,
		marginRight: 'auto',
	},
	searchSpacer: {
		flex: '1 1 100%',
	},
	actionButtons: {
		marginLeft: theme.spacing(1),
		// flex: '0 1 auto',
		'& button': {
			padding: theme.spacing(0.5, 1),
			fontSize: '0.8rem',
			whiteSpace: 'nowrap',
		},
	},
	// artTemplatesWrapper: {
	// 	display: 'flex',
	// 	alignItems: 'center',
	// 	flex: ' 1 0 auto',
	// 	paddingLeft: theme.spacing(1),
	// },
	artTemplatesSection: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flex: '1 0 auto',
		paddingLeft: theme.spacing(1),
		// position: 'relative',
	},
	selectedArtTemplatesChip: {
		maxWidth: 200,
		marginLeft: 1,
		marginRight: 1,
	},
	// artTemplatesAllSelections: {
	// 	position: 'absolute',
	// 	top: 0,
	// 	bottom: 0,
	// 	left: '100%',
	// 	right: 0,
	// 	overflow: 'hidden',
	// 	zIndex: 2,
	// 	// visibility: 'hidden',
	// 	display: 'flex',
	// 	alignItems: 'center',
	// 	justifyContent: 'center',
	// 	backgroundColor: '#fefefe',
	// 	transition: 'left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
	// 	borderRadius: 32,
	// },
	// artTemplatesAllSelectionsExpand: {
	// 	left: 0,
	// 	// visibility: 'visible',
	// },
	// collapseContainer: {
	// 	width: 0,
	// 	height: 'auto',
	// 	transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
	// },
	// collapseEntered: {
	// 	width: 'auto',
	// 	height: 'auto',
	// },
}));

// constant variable

function Esign({
	history,
	location,
	notifyGeneral,
	openGlobalDialog,
	userLevel,
	uid,
	userEmail,
	// artworkExtra,
	// spreadsheetContentById,
	// userData,
	domainName,
	// fetchAWSCredential,
	// resetAWSCredential,
	// saveArtworkFile,
	// resetSaveArtworkFileStatus,
	// saveDesignerTemplate,
	// retrieveDraftDesignTemplate,
	// openGlobalDialog,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	const initPageSize = 25;
	// constants
	// const S3_RESOURCE = { resource: 's3' }; // used to fetch s3 credential

	// ##############################
	// refs
	// #############################
	// selectedRTableData is used for final printing
	// to prevent redundant data fetching, it stores all history selections
	// it is updated by following cases:
	//	- row data was edited
	//	- row is selected (including the rows were selected, but removed now, in case the row data was edited)
	// format: {[rowId]: <row data as in rTableData>}
	// NB: the actual selected number of each row is actually stored in rowState
	const selectedRTableData = React.useRef({});

	// ##############################
	// local states
	// #############################
	// template preparation status
	const [initStatus, setInitStatus] = useState({ status: 'OK', message: '' });
	const [showSelections, setShowSelections] = useState(false);
	const [spreadsheetId, setSpreadsheetId] = useState(null);
	const [artTemplateList, setArtTemplateList] = useState([]); // a list of artwork template that use the spreadsheet
	const [selectedTemplateIds, setSelectedTemplateIds] = useState([]); // Array of the template Id that were selected for printing
	const [selectArtTemplatesAnchor, setSelectArtTemplatesAnchor] = useState(null);
	const [cachedArtTemplates, setCachedArtTemplates] = useState([]); // an array of cached artwork template data
	// NB: activeArtTemplate contains metadata that is retrieved/processed from the template itself.
	const [activeArtTemplate, setActiveArtTemplate] = useState({
		metadata: {
			outputTemplate: {},
			ssId: null, // spreadsheet id. (it is not used at the moment, using spreadsheetId state instead, but could use it to validate the artwork template)
			primaryColIndex: -1, // product picker column. It has valid value after initialize (otherwise error is thrown). Treat it seperately, even it could be included in columnIndices
			// columnIndices is array of string. Columns that "auto-import" from product picker column.
			// NB1: because of virtual "reference" image columns, item in the array is string.
			// NB2: product picker col is not included, but it could be in the array too if an "auto-import" is same as product picker column
			columnIndices: [],
			editableColumnIndices: [], // Array of integer. columns that are editable
			searchColumnIndices: [], // Array of integer. columns that are searchable
			filterColumnIndices: [], // Array of integer. columns that are filterable
			maxQtyPerRow: 10,
		},
	});
	const [artworkExtra, setArtworkExtra] = useState({});
	const [isFetchingSSContent, setIsFetchingSSContent] = useState(true);
	const [controlledPageCount, setControlledPageCount] = useState(-1);

	// ssContent is original spreadsheet data by current sort, page, filter, etc. from api
	const [ssContent, setSSContent] = useState();
	const [rTableData, setRTableData] = useState([]);
	// rTableColumns contains "columns" for react-table, and the compitible artwork template id (as identifier)
	// NB: diff artwork template may use different columns (even with same primary col)
	const [rTableColumns, setRTableColumns] = useState({ columns: [], artTemplateId: '' });
	const [skipPageReset, setSkipPageReset] = React.useState(false);
	const [searchKeywords, setSearchKeywords] = React.useState('');
	// prepared filter data for <Filters /> Comp. fetchStatus possible value: 'PROCESSING', 'OK', 'FAILED', ''
	// filters: [{id: columnIndex, title: columnName, value: '', options: [{ label: val, value: val }]}, ...]
	const [esignFilters, setEsignFilters] = React.useState({ filters: [], fetchStatus: '' });
	const [filterAnchor, setFilterAnchor] = React.useState(null);
	const [adjustOutput, setAdjustOutput] = React.useState(0);

	const [openProof, setOpenProof] = React.useState(false);
	/**
	 * printData contains ready-to-print data. Format:
	 	{
			 mode: ''|'PRINT_ALL'|'PRINT_SELECTIONS'|'PRINT_SEARCH_RESULT',
			 totalItems: NUMBER,
			 selectedArtTemplates: [{ artTemplate, multiplePrintData }, ...], // mandatary in PRINT_SELECTIONS & PRINT_SEARCH_RESULT mode
			 activeArtTemplate: {}, // mandatary in PRINT_ALL mode. The current active artwork template object
		}
	 */
	const [printData, setPrintData] = React.useState({});

	const [openBatchDialog, setOpenBatchDialog] = React.useState(false);
	// exporting progress status. {status: 'OK'|'DONE'|'FAILED', message: 'xxx'} or {} (to reset it)
	const [exportArtworkStatus, setExportArtworkStatus] = useState({});
	/**
	 * input data of each field. Called fieldInputData
	 * Format: {FIELD_ID: {}}
	 * NOTE-1: fieldInputData only contains user's input data through preview panel. Initial value is null/undefined; set to '' when it is cleared
	 * 				 We will have a merged dataset to merge fieldInputData & field default data, then use the merged dataset to output
	 * NOTE-2: When the value in a field is cleared by user, no data will be used for output the field (preview or pdf generation), hence the following rules:
	 * 				 - if field data in this fieldInputData is null/undefined, we will use field default data (in the merged dataset)
	 * 				 - if field data in this fieldInputData is '', we will leave it empty (in the merged dataset), even there is default data for the field
	 fieldInputData: {
		TEXT_FIELD_ID: {
			// TEXT Preview Data
			value: 'xxxx', // value of this text field. Possible values: null/undefined, '', 'text'
			fontsize: NUMBER | undefined,
			horizontalAlign: 'left' | 'center' | 'right' | 'justified' | undefined,
			verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
		},
		IMAGE_FIELD_ID: {
			// IMAGE Preview Data
			mediafileId: 'xxxxxx', // user selected mediafile ID (not used for output, but used to save content). Possible values: null/undefined, '', '32345';
			previewUrl: 'xxxxxxx', // preview url of this image. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			optimisedUrl: 'xxxx', // optimised url with good quality for fast loading on webpage. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			highResUrl: 'xxxx', // best quality mediafile url. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			
			// imageUrl: 'https://xxxxx', // undefined or original image url???
			croppedImgUrl: 'xxx', // base64 image url (crop image only) Possible values: null/undefined, '', 'base64:jpeg;xxxxxxxxyyyyyzzzzz'
			horizontalAlign: 'left' | 'center' | 'right' | undefined,
			verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
			// user customized field position.
			// NB: it is only used as part of file content, it is never used in preview or file generation, so do not merge it into output data. When not undefined, it is guaranteed to have left|top|width|height|angle
			// NB: In the case of "continue editing previously saved artwork file (not template)", this data must be retrieved and used to change the field "designed" position (we always use field.position to render field's preview)
			position: {left: num, top: num, width: num, height: num, angle: num},
		},
		BARCODE_FIELD_ID: {
			// BARCODE Preview Data
			value: 'xxxxxxx', // value of this barcode field. Possible values: null/undefined, '', 'barcode'
			EAN13Value: 'xxx', // processed data from "value", only in output data
			EAN5Value: 'xxx', // processed data from "value", only in output data
			horizontalAlign: 'left' | 'center' | 'right' | undefined,
			verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
		},
		PDF_FIELD_ID:{
			// Artwork field Preview Data
			mediafileId: 'xxxxxx', // user selected mediafile ID (not used for output, but used to save content). Possible values: null/undefined, '', '32345';
			previewUrl: 'xxxxxxx', // preview url of this pdf/svg. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			optimisedUrl: 'xxxx', // optimised url with good quality for fast loading on webpage. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			highResUrl: 'xxxx', // best quality mediafile url. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			
			//svgUrl: 'xxxx',
			horizontalAlign: 'left' | 'center' | 'right' | undefined,
			verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
			
		},
		VIDEO_FIELD_ID: {
			// VIDEO Preview Data
			mediafileId: 'xxxxxx', // user selected mediafile ID (not used for output, but used to save content). Possible values: null/undefined, '', '32345';
			previewUrl: 'xxxxxxx', // preview url of this video. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			optimisedUrl: 'xxxx', // optimised url with good quality for fast loading on webpage. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			highResUrl: 'xxxx', // best quality mediafile url. Possible values: null/undefined, '', 'https://xxxxx.com/xxx.jpg'
			
			// videoUrl: 'https://xxxxx', // undefined or original video url???
			horizontalAlign: 'left' | 'center' | 'right' | undefined,
			verticalAlign: 'top' | 'middle' | 'bottom' | undefined,
			videoLoop: true | false | undefined,
		},
		GRID_FIELD_ID: {
			// tableData format: [RowData, RowData, ...]; RowData: [cellText, cellText, ...]; cellText may be empty string, may contain multiple lines by '\n'. Get non-empty lines by `.split(/\r?\n|\r/g).filter((s) => s)`
			// sample data of tableData: [["Product","Small","Large"],["Cappuccino\n\nsubtitle","€1.99\n\nsubtitle","€2.99"],["Mocha","€2.49","€3.99"]]
			// possible value: null/undefined (no input data), [['xxx', 'xxx'], ...]
			// when its value is null/undefined, the code logic in "getFieldOutputData" to decide using field.defaultEditorHtml to render or not
			tableData: [["Product","Small","Large"],["Cappuccino\n\ngghgh","€1.99\n\nnew line","€2.99"],["Mocha","€2.49","€3.99"]],
			hasHeader: true/false, // indicate if there is header in the table
			// editorHtml possible values: null/undefined, 'xxxx'
			editorHtml, // the html string in the editor.
		}
	}
	 */
	// const [fieldInputData, setFieldInputData] = useState({});

	// ##############################
	// Variables
	// #############################
	const selectedTemplateProductPickerColumns = React.useMemo(() => {
		if (!ssContent) return [];
		return cachedArtTemplates
			.map((cachedTemp) => {
				if (selectedTemplateIds.includes(cachedTemp.mediaId)) {
					// this is selected artwork template
					const templateProductPickerColumns = {
						templateId: cachedTemp.mediaId,
						templateName: cachedTemp.name,
						productPickerColumns: [],
					};
					for (const f of cachedTemp.fields) {
						if (f.predefinedValue?.fromColumn) {
							const headerColumn = ssContent.columnHeaders.find(
								(column) => `${column.columnId}` === `${f.predefinedValue.fromColumn}`
							);
							if (headerColumn)
								templateProductPickerColumns.productPickerColumns.push(headerColumn);
						}
					}
					return templateProductPickerColumns;
				}
				return null;
			})
			.filter((item) => item);
	}, [cachedArtTemplates, selectedTemplateIds, ssContent]);
	// ##############################
	// React-Table (treat it as part of local states)
	// #############################
	// When our cell renderer calls updateRTableData, we'll use
	// the rowId, columnId and new value to update the rTable data
	const updateRTableData = (rowId, columnId, value) => {
		// We also turn on the flag to not reset the page
		setSkipPageReset(true);
		setRTableData((old) =>
			old.map((rowData /* index */) => {
				if (rowData.rowId === rowId) {
					return {
						...rowData,
						isMod: true, // mark this row is modified
						[columnId]: value,
					};
				}
				return rowData;
			})
		);
	};
	// function to select rows that require highlight
	const rowHighlightPicker = (row) => (row.state.qty ?? 0) > 0;

	const tableInst = useTable(
		{
			columns: rTableColumns.columns,
			data: rTableData,
			initialState: {
				pageSize: initPageSize,
				pageIndex: 0,
			},
			pageCount: controlledPageCount,
			manualPagination: true,
			manualSortBy: true,
			disableMultiSort: true,
			// defaultColumn,
			// use the skipPageReset option to disable page resetting temporarily, e.g. edit cell data
			autoResetPage: !skipPageReset,
			// autoResetGlobalFilter: !skipPageReset,
			// autoResetExpanded: !skipPageReset,
			// autoResetSelectedRows: !skipPageReset,
			autoResetSortBy: !skipPageReset,
			// autoResetFilters: !skipPageReset,
			autoResetRowState: !skipPageReset,
			// updateRTableData is used to edit cell value
			// it isn't part of the API, but anything we put into these options will
			// automatically be available on the instance.
			// That way we can call this function from our cell renderer!
			updateRTableData,
			// maxQtyPerRow,
			// rowIdAccessor,
			// ...rTableOptions,
			getRowId: (rowData /* , relativeIndex, parent */) => {
				return rowData.rowId;
			},
			stateReducer: (newState, action /* prevState */) => {
				if (action.type === 'toggleSortBy') {
					// in case of sorting, we also reset page at the same time to prevent unnecessary side effects
					return { ...newState, pageIndex: 0 };
				}
				return newState;
			},
			useControlledState: (state) => {
				return React.useMemo(() => {
					// remove non-selected row from rowState
					let currentRowState = { ...state.rowState };
					Object.keys(currentRowState).forEach((rowId) => {
						if (!currentRowState[rowId].qty) delete currentRowState[rowId];
					});
					return {
						...state,
						rowState: currentRowState,
					};
				}, [state]);
			},
		},
		useSortBy,
		useRowState,
		usePagination,
		(hooks) => {
			hooks.allColumns.push((columns) => {
				let qtyInputCol = {
					id: 'qtyInput',
					disableSortBy: true,
					Header: 'Quantity',
					Cell: function qtyInputHeader({ row }) {
						return (
							<div>
								<NumInput
									{...row.getRowProps()}
									onCommitted={(value) => {
										row.setState((currentState) => {
											return { ...currentState, qty: value };
										});
									}}
									onChange={(value) => {
										row.setState((currentState) => {
											return { ...currentState, qty: value };
										});
									}}
									min={0}
									step={1}
									max={activeArtTemplate.metadata.maxQtyPerRow}
									value={`${row.state.qty || 0}`}
								/>
							</div>
						);
					},
				};
				return [qtyInputCol, ...columns];
			});
		}
	);
	// NB: rowState below contains history row states until unmounted
	const {
		gotoPage,
		setPageSize,
		setRowState,
		state: { pageIndex, pageSize, sortBy, rowState },
	} = tableInst;

	const resetTableState = () => {
		setSearchKeywords('');
		setEsignFilters({ filters: [], fetchStatus: '' });
		gotoPage(0);
	};

	// ##############################
	// variables
	// #############################
	const adjustOutputOptions = React.useMemo(() => {
		return _.range(
			ART_VARIABLES.minAdjustOutput,
			ART_VARIABLES.maxAdjustOutput + 1,
			ART_VARIABLES.stepAdjustOutput
		).map((adjust) => ({
			label: `${adjust} mm`,
			value: `${adjust}`,
		}));
	}, []);
	const totalSelections = React.useMemo(() => {
		return Object.keys(rowState).reduce((accu, rowId) => {
			return (accu += rowState[rowId].qty ?? 0);
		}, 0);
	}, [rowState]);

	// key of cached adjustOutput in localstorage (per user template)
	const adjustCachaKey = React.useMemo(() => {
		return activeArtTemplate.mediaId ? `esadj_u${uid}_t${activeArtTemplate.mediaId}` : null;
	}, [activeArtTemplate.mediaId, uid]);

	// ##############################
	// Side Effects
	// #############################
	/* init required data */
	useEffect(() => {
		// initialize necessary dataset
		const query = new URLSearchParams(location.search);
		const templateId = query.get('t'); // artwork template id
		if (!templateId) {
			setInitStatus({
				status: 'FAILED',
				message: 'Missing artwork template ID',
			});
		} else {
			initEsignData(templateId, /* ssApiQueryParams, */ setInitStatus)
				.then((esignInitData) => {
					// Final Step - have prepared all necessary data, now update local state
					setActiveArtTemplate(esignInitData.artTemplate);
					setCachedArtTemplates([esignInitData.artTemplate]);
					setArtworkExtra(esignInitData.artExtra);
					setArtTemplateList(esignInitData.artTemplateList);
					setSpreadsheetId(esignInitData.ssId);
					setSelectedTemplateIds([esignInitData.artTemplate.mediaId]);
				})
				.catch((err) => {
					setInitStatus({
						status: 'FAILED',
						message: err.error ? err.error.message : err.message,
					});
				});
		}

		return () => {
			// clean up when unmount
			// resetAWSCredential(S3_RESOURCE);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Set adjustOutput to cached value on the following cases:
	 * -	activeArtTemplate is changed
	 * -	number of selectedTemplateIds is changed
	 */
	React.useEffect(() => {
		// console.log(
		// 	`Update adjustOutput: adjustCachaKey ${adjustCachaKey}, selectedTemplateIds.length ${selectedTemplateIds.length}`
		// );
		if (selectedTemplateIds.length === 0 || !adjustCachaKey) return;
		// use default adjustOutput in case of multiple templates
		else if (selectedTemplateIds.length > 1) setAdjustOutput(0);
		else {
			const savedAdjustOutput = Number(localStorage.getItem(adjustCachaKey));
			if (
				!isNaN(savedAdjustOutput) &&
				savedAdjustOutput >= ART_VARIABLES.minAdjustOutput &&
				savedAdjustOutput <= ART_VARIABLES.maxAdjustOutput &&
				savedAdjustOutput !== 0
			) {
				// console.log(`Update adjustOutput: set to new value ${savedAdjustOutput}`);
				setAdjustOutput(savedAdjustOutput);
			} else {
				// console.log(`Update adjustOutput: remove cached value`);
				// here, the saved adjust value is either invalid or zero
				localStorage.removeItem(adjustCachaKey);
			}
		}
		// };
	}, [adjustCachaKey, selectedTemplateIds.length]);

	/**
	 * Cache adjustOutput (MUST be executed after the above "Set adjustOutput" side effect)
	 * -	when only one template is selected
	 */
	React.useEffect(() => {
		// console.log(
		// 	`Cache adjustOutput: adjustCachaKey ${adjustCachaKey}, adjustOutput ${adjustOutput}, selectedTemplateIds.length ${selectedTemplateIds.length}`
		// );
		if (
			adjustCachaKey &&
			selectedTemplateIds.length === 1
			// &&
			// // adjustOutput !== 0 &&
			// adjustOutput.toString() !== localStorage.getItem(adjustCachaKey)
		) {
			// console.log(
			// 	`Cache adjustOutput: ${
			// 		adjustOutput === 0 ? 'remove cache' : 'set new value'
			// 	}`
			// );
			if (adjustOutput === 0) localStorage.removeItem(adjustCachaKey);
			else localStorage.setItem(adjustCachaKey, adjustOutput);
		}
	}, [adjustCachaKey, adjustOutput, selectedTemplateIds.length]);

	/**
	 * Fetch esign filters
	 * NB: only fetch esign filters in two cases:
	 * 	- initial stage when the filters are not fetched at all
	 * 	- when initial fetch was failed and user opens "filters" popover
	 */
	React.useEffect(() => {
		if (
			(spreadsheetId && esignFilters.fetchStatus === '') ||
			(filterAnchor && esignFilters.fetchStatus === 'FAILED')
		) {
			if (activeArtTemplate.metadata.filterColumnIndices.length === 0) {
				// no filter columns, no need to fetch, but set fetchStatus to 'OK' if not done so
				if (esignFilters.fetchStatus !== 'OK')
					setEsignFilters((old) => ({ ...old, fetchStatus: 'OK' }));
				return null;
			}
			setEsignFilters((old) => ({ ...old, fetchStatus: 'PROCESSING' }));
			fileMgrFetchSSEsignFilters({
				ssId: spreadsheetId,
				queryParams: {
					esignFilterIndices: activeArtTemplate.metadata.filterColumnIndices.join(',') || null,
				},
			})
				.then((response) => {
					if (
						response.data.length > 0 &&
						response.data.some((item) => item.totalValuesFound > item.values.length)
					) {
						notifyGeneral(
							intl.formatMessage({
								id:
									response.data.length === 1
										? 'pages.Artwork.components.Esign.WarningMessageSingleFilter'
										: 'pages.Artwork.components.Esign.WarningMessageMultiFilter',
							}),
							'warning'
						);
					}
					setEsignFilters({
						filters: _.uniqBy(response.data, 'columnIndex').map((filter) => ({
							id: filter.columnIndex.toString(),
							title: filter.columnName,
							value: '',
							options: filter.values.map((val) => ({ label: val, value: val })),
						})),
						fetchStatus: 'OK',
					});
				})
				.catch((err) => {
					let msg = err.response?.data.message || err.message;
					notifyGeneral(`Can not fetch filters. [${msg}]`, 'error');
					if (esignFilters.fetchStatus !== 'FAILED')
						setEsignFilters((old) => ({ ...old, fetchStatus: 'FAILED' }));
				});
		}
	}, [
		activeArtTemplate.mediaId,
		activeArtTemplate.metadata.filterColumnIndices,
		spreadsheetId,
		esignFilters.fetchStatus,
		filterAnchor,
		notifyGeneral,
		intl,
	]);

	/**
	 * update "columns" for react-table on following conditions:
	 * 	- no columns
	 * 	- activeArtTemplate is changed
	 * NB: only editable columns are displaying in table, even the template uses more columns
	 */
	React.useEffect(() => {
		// create "columns" for react-table
		const getRTableColumns = (ssContent, primaryColIdx, editableColumns) => {
			let columns = [],
				primaryColId = primaryColIdx.toString(),
				stringifiedEditableColumns = editableColumns.map((item) => item.toString());
			// "displayColumns" is the required columns to show in table
			let displayColumns = [...stringifiedEditableColumns, primaryColId];
			ssContent.columnHeaders.forEach((header) => {
				if (!displayColumns.includes(header.columnId)) return;
				if (primaryColId === header.columnId) {
					// add primary column to the first position. Primary column is treated seperately even it is in "normal" columns
					let primaryColObj = {
						Header: `Product`,
						accessor: header.columnId,
						id: `primary_${header.columnId}`,
						disableSortBy: true,
					};
					primaryColObj.Cell = function renderCell({ value }) {
						return (
							<div style={{ minWidth: 300 }}>
								<Typography /* noWrap={true} */ variant="inherit">{value || ''}</Typography>
							</div>
						);
					};
					columns.unshift(primaryColObj);
				}

				if (stringifiedEditableColumns.includes(header.columnId)) {
					const colObj = {
						Header: header.columnName,
						accessor: header.columnId,
						id: header.columnId,
						colType: header.type,
					};
					colObj.Cell = function renderCell({
						value,
						row,
						updateRTableData,
						column,
						// /* columns, */ data,
						...rest
					}) {
						return column.colType === 'text' ? (
							<EditableTextCell
								value={(value || '').trim()}
								onCommitted={(value) => {
									updateRTableData(row.id, column.id, value);
									// default, we will select the row after editing if it is not selected
									if (!row.state.qty) {
										row.setState((currentState) => {
											return { ...currentState, qty: 1 };
										});
									}
								}}
							/>
						) : column.colType === 'image' ? (
							// column type: image. The value is an object with mediafileId & xxxxxUrl
							value.previewUrl ? (
								<Tooltip
									arrow
									placement="right"
									title={
										<img
											src={value.previewUrl}
											alt={value.mediafileId}
											style={{ maxWidth: '100%', maxHeight: 200 }}
										/>
									}
								>
									<img
										src={value.previewUrl}
										alt={value.mediafileId}
										style={{ maxWidth: '100%', maxHeight: 50 }}
									/>
								</Tooltip>
							) : (
								'N/A'
							)
						) : null;
					};
					columns.push(colObj);
				}
			});
			return { columns };
		};

		// only update rTableColumns when "no columns" or "artwork template is changed"
		if (
			((rTableColumns.columns.length === 0 && !rTableColumns.artTemplateId) ||
				rTableColumns.artTemplateId !== activeArtTemplate.mediaId) &&
			ssContent &&
			activeArtTemplate.metadata.primaryColIndex !== -1
		) {
			let { columns } = getRTableColumns(
				ssContent,
				activeArtTemplate.metadata.primaryColIndex,
				activeArtTemplate.metadata.editableColumnIndices
			);
			setRTableColumns({ columns, artTemplateId: activeArtTemplate.mediaId });
		}
	}, [
		activeArtTemplate.mediaId,
		rTableColumns,
		ssContent,
		activeArtTemplate.metadata.primaryColIndex,
		activeArtTemplate.metadata.editableColumnIndices,
	]);

	/**
	 * update "selected rows"
	 * NB: row is auto-selected after editing, so this side-effect will be triggered
	 * 			and the updated row data is stored in selectedRTableData
	 */
	React.useEffect(() => {
		let prevSelected = { ...selectedRTableData.current };
		let currentSelectedRows = Object.keys(rowState).reduce((accu, rowId) => {
			let selectedRowData =
				rTableData.find((rowData) => rowData.rowId === rowId) || prevSelected[rowId];
			if ((rowState[rowId].qty ?? 0) > 0 && selectedRowData) {
				return {
					...accu,
					[rowId]: selectedRowData,
				};
			}
			// DONOT remove the rows that were selected but removed from selection now, as the row data may be edited
			// if (rowState[rowId].qty === 0 && selectedRowData) {
			// 	// remove it from selected rows
			// 	delete prev[rowId];
			// 	return accu;
			// }
			else {
				return accu;
			}
		}, {});

		// find rows with modified data, no matter they are selected or not at the moment
		let modifiedRows = {};
		for (let selcRowId in prevSelected) {
			if (prevSelected[selcRowId].isMod) {
				modifiedRows[selcRowId] = prevSelected[selcRowId];
			}
		}

		// rows that have no modified data and not being selected, are ignored in the new selectedRTableData
		selectedRTableData.current = { ...modifiedRows, ...currentSelectedRows };
	}, [rTableData, rowState]);

	/**
	 * update "data" for react-table
	 */
	React.useEffect(() => {
		if (ssContent) {
			// let columnType = ssContent.columnHeaders.reduce((accu, colHeader) => {
			// 	return { ...accu, [colHeader.columnIndex]: colHeader.type };
			// }, {});
			// let data = ssContent.rows.map((row) => {
			// 	let rowId = row.rowId.toString();
			// 	let colObject =
			// 		selectedRTableData.current[rowId] ||
			// 		row.rowData.reduce(
			// 			(accu, colData) => {
			// 				return {
			// 					...accu,
			// 					[colData.columnIndex]:
			// 						columnType[colData.columnIndex] === 'text'
			// 							? colData.value?.trim() ?? ''
			// 							: {
			// 									previewUrl: 'xxx', // TODO: update when response data from api is decided
			// 									optimisedUrl: 'xxx',
			// 									highResUrl: 'xxx',
			// 									mediafileId: 'xxx',
			// 							  },
			// 				};
			// 			},
			// 			{ isMod: false } // add "isMod" field to each row to indicate if the row is edited
			// 		);
			// 	return {
			// 		...colObject,
			// 		rowId: rowId,
			// 	};
			// });
			let data = convertSSContentToRTableData(ssContent, selectedRTableData.current);
			// we want to prevent state reset of react-table on data change
			setSkipPageReset(true);
			setRTableData(data);
		}
		// else return { columns: [], data: [] };
	}, [ssContent]);

	// After data changes, we turn the flag back off
	// so that if data actually changes when we're not editing it, the page is reset
	React.useEffect(() => {
		setSkipPageReset(false);
		// reset showSelections when there is no selected rows
		if (showSelections && rTableData.length === 0) {
			setShowSelections(false);
		}
	}, [rTableData, showSelections]);

	/**
	 * update "rTableData" on selected qty changes in showSelections mode
	 * NB: this useEffect will re-setRTableData right after showSelections is changed to true
	 * 			it is necessary in case some of the "selectedRTableData" are not selected (qty is 0)
	 * NB: this side effect must be after previous useEffect
	 * 			as setSkipPageReset(false) in prev useEffect will cause table state resetted on data change
	 */
	React.useEffect(() => {
		if (showSelections) {
			setRTableData((old) => {
				let newRTableData = old.filter((rowData) => rowState[rowData.rowId]?.qty > 0);
				// only set rTableData when the number of selected rows are changed, to prevent unnecessary table renders
				if (old.length !== newRTableData.length) {
					// prevent state reset of react-table on data change
					setSkipPageReset(true);
					return newRTableData;
				} else return old;
			});
		}
	}, [rowState, showSelections]);

	/**
	 * Fetch spreadsheet content
	 * NB: we use the same selected data to print with all selected artwork templates, so we will request all columns in the spreadsheet
	 * 			to prevent missing columns in different templates, also to prevent unnecessary api requests
	 */
	React.useEffect(() => {
		if (spreadsheetId && ['OK', 'FAILED'].includes(esignFilters.fetchStatus) && !showSelections) {
			setIsFetchingSSContent(true);
			// let fetchingCols = activeArtTemplate.metadata.columnIndices;
			// let primaryCol = activeArtTemplate.metadata.primaryColIndex;
			let selectedFilters = esignFilters.filters.filter((filter) => Boolean(filter.value));
			fileMgrFetchSSById({
				ssId: spreadsheetId,
				queryParams: {
					...(sortBy.length > 0
						? { sortBy: sortBy[0].id, sortDirection: sortBy[0].desc ? 'desc' : 'asc' }
						: {}),
					...(searchKeywords //&& activeArtTemplate.metadata.searchColumnIndices.length > 0
						? {
								searchString: searchKeywords,
								searchColumnIndices: activeArtTemplate.metadata.searchColumnIndices.join(','),
						  }
						: {}),
					...(selectedFilters.length > 0
						? {
								esignFilterIndices: selectedFilters.map((filter) => filter.id).join('|'),
								esignFilterValues: selectedFilters
									.map((filter) => encodeURIComponent(filter.value)) // using encodeURIComponent() to escape the special chars (particularly "|"). There is another time of encodeURIComponent() invoked by axios automatically
									.join('|'),
						  }
						: {}),
					offset: pageIndex * pageSize,
					limit: pageSize,
					// columnIndices: fetchingCols.includes(primaryCol)
					// 	? fetchingCols.join(',') || null
					// 	: [...fetchingCols, primaryCol].join(','),
				},
			})
				.then((response) => {
					setControlledPageCount(Math.ceil(response.data.totalFound / pageSize));
					setSSContent(response.data);
				})
				.catch((err) => {
					let msg = err.response?.data.message || err.message;
					notifyGeneral(`Can not fetch page content, please try again later. [${msg}]`, 'error');
				})
				.then(() => setIsFetchingSSContent(false));
		}
	}, [
		// activeArtTemplate.metadata.columnIndices,
		notifyGeneral,
		pageIndex,
		pageSize,
		sortBy,
		spreadsheetId,
		// activeArtTemplate.metadata.primaryColIndex,
		esignFilters,
		showSelections,
		activeArtTemplate.metadata.searchColumnIndices,
		searchKeywords,
	]);

	React.useEffect(() => {
		if (totalSelections > ART_VARIABLES.maxSelectableItems) {
			let alertDialog = {
				size: 'sm',
				title: intl.formatMessage({
					id: 'pages.Artwork.components.Esign.TooManySelectionsDialogTitle',
				}),
				content: intl.formatMessage(
					{
						id: 'pages.Artwork.components.Esign.TooManySelectionsDialogContent',
					},
					{ maxItems: ART_VARIABLES.maxSelectableItems }
				),
			};
			openGlobalDialog(alertDialog);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totalSelections]);
	// ##############################
	// Handling functions
	// #############################
	// handler of Goback navigation
	const handleGoback = () => {
		if (location.state && location.state.fromPath) {
			history.push(location.state.fromPath);
		} else {
			history.go(-1);
		}
	};
	const handleGoToSpreadsheet = () => {
		window.location.assign(getRouteManageSpreadsheet(spreadsheetId));
	};
	const handleCreateOwn = () => {
		history.push({
			pathname: ROUTES_PATH_ARTWORK_CREATOR,
			search: `?t=${activeArtTemplate.mediaId}`,
		});
	};
	// handle table page changes (prev or next page)
	const handleChangePage = (event, newPage) => {
		gotoPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPageSize(Number(event.target.value));
	};

	const handleBatchingTask = React.useCallback(
		// templateFilenameColumnIdMap is record of filename columnId in a template
		// format: {[templateId]: filenameColumnId, [templateId]: filenameColumnId, ...}
		async (templateFilenameColumnIdMap, userOwnerColumnId) => {
			// validation
			const s3Bucket = getDomainConfig(domainName)?.s3Bucket;
			if (!s3Bucket) {
				notifyGeneral(
					intl.formatMessage({ id: 'utils.artwork.domainNameNotFoundErrorMsg' }),
					'error'
				);
				return;
			}

			// print selected rows
			const listOfTemplateFieldInputData = selectedTemplateIds
				.map((templateId) => {
					let artTemplate = cachedArtTemplates.find(
						(cachedTemp) => cachedTemp.mediaId === templateId
					);
					if (!artTemplate) return null;

					// itemsFieldInputData is an array of the field input data of all rows
					let itemsFieldInputData = buildFieldsInputData(
						Object.values(selectedRTableData.current),
						artTemplate,
						templateFilenameColumnIdMap[templateId],
						userOwnerColumnId,
						uid // fallback user id if can't find the user from spreadsheet by userOwnerColumnId
					);
					return {
						artTemplate,
						itemsFieldInputData,
					};
				})
				.filter((item) => item);
			if (listOfTemplateFieldInputData.length !== selectedTemplateIds.length) {
				// error
				notifyGeneral(
					`Some selected templates are missing, please refresh the page and try again`,
					'error'
				);
				return;
			}

			try {
				const {
					totalFailed,
					// invalid,
					totalFiles,
					totalInvalid,
				} = await batchingEsignForServerProcess({
					listOfTemplateFieldInputData,
					artworkAvailableFonts: artworkExtra.fonts,
					s3Bucket,
					s3BasePath: `${domainName}/${dateFormat(new Date(), 'yyyyMMdd')}/${genRandomStr(28)}/`,
					setExportArtworkStatus,
					domainName,
					intl,
				});
				//  batching was finished
				setExportArtworkStatus({});
				const notificationMsg = (
					<Box sx={{ flexDirection: 'column', display: 'flex' }}>
						<Typography>
							{intl.formatMessage({
								id: 'pages.Artwork.components.Esign.BatchingFinishMsg',
							})}
						</Typography>
						{(totalFailed > 0 || totalInvalid > 0) && (
							<Typography variant="body2">
								{intl.formatMessage(
									{ id: 'pages.Artwork.components.Esign.BatchingTotalFailedMsg' },
									{ totalFailed, totalFiles, totalInvalid }
								)}
							</Typography>
						)}
					</Box>
				);
				notifyGeneral(notificationMsg, totalFailed > 0 || totalInvalid > 0 ? 'error' : 'success');
			} catch (err) {
				const errMsg = err.response ? err.response.data.message : err.message;
				setExportArtworkStatus({
					status: 'FAILED',
					message: errMsg,
				});
			}
		},
		[
			artworkExtra.fonts,
			cachedArtTemplates,
			domainName,
			intl,
			notifyGeneral,
			selectedTemplateIds,
			uid,
		]
	);

	const debouncedSetSearchKeywords = React.useCallback(
		() =>
			_.debounce((e) => {
				// set searchKeywords to trigger the search call
				setSearchKeywords(e.target.value);
				// reset page index
				gotoPage(0);
			}, 300),
		[gotoPage]
	)();

	return (
		<div className={classes.esignRoot}>
			{spreadsheetId ? (
				<div className={classes.esignBody}>
					<div className={classes.nonTableArea}>
						<div className={classes.titleRow}>
							<Typography variant="h4" noWrap style={{ flex: '0 0 auto' }}>
								{intl.formatMessage({
									id: 'pages.Artwork.components.Esign.PageTitle',
								})}
							</Typography>

							<section className={classes.artTemplatesSection}>
								{
									// <span style={{ paddingRight: 4 }}>Active Template:</span>
								}
								{selectedTemplateIds.map((artTId) => {
									let selectedArtTemplate = artTemplateList.find((artT) => artT.id === artTId);
									let isActiveArtTemplate = activeArtTemplate.mediaId === selectedArtTemplate.id;
									if (selectedArtTemplate) {
										return (
											<Tooltip
												key={`selected-arttemplate-${artTId}`}
												arrow
												title={
													<img
														src={selectedArtTemplate.previewUrl}
														alt="thumbnail artwork template"
														style={{ maxWidth: '100%', maxHeight: 120 }}
													/>
												}
											>
												<Chip
													// key={`selected-arttemplate-${artTId}`}
													className={classes.selectedArtTemplatesChip}
													clickable={!isActiveArtTemplate}
													color={isActiveArtTemplate ? 'primary' : 'default'}
													// size="small"
													component="div"
													label={selectedArtTemplate.name}
													onDelete={
														isActiveArtTemplate
															? null
															: () =>
																	setSelectedTemplateIds(
																		selectedTemplateIds.filter(
																			(artTId) => artTId !== selectedArtTemplate.id
																		)
																	)
													}
													onClick={() => {
														if (!isActiveArtTemplate) {
															let newActiveArtTemplate = cachedArtTemplates.find(
																(cachedTemplate) =>
																	cachedTemplate.mediaId === selectedArtTemplate.id
															);
															if (newActiveArtTemplate) {
																setActiveArtTemplate(newActiveArtTemplate);
																resetTableState();
															} else {
																// this should be error, because all selected artwork template must have cached template data
																notifyGeneral(
																	intl.formatMessage({
																		id: 'pages.Artwork.components.Esign.NoTemplateDataErrorMsg',
																	}),
																	'error'
																);
															}
														}
													}}
												/>
											</Tooltip>
										);
									} else {
										return null;
									}
								})}
							</section>
						</div>
						<div className={classes.controlRow}>
							{/** Select artwork templates */}
							<Button
								// size="small"
								color="inherit"
								startIcon={<SelectArtTemplateIcon />}
								className={classes.button}
								onClick={(e) =>
									setSelectArtTemplatesAnchor(selectArtTemplatesAnchor ? null : e.currentTarget)
								}
								aria-controls="artwort-templates-menu"
								aria-haspopup="true"
							>
								{intl.formatMessage({ id: 'pages.Artwork.components.Esign.MoreTemplatesBtnText' })}
							</Button>
							<Menu
								id="artwort-templates-menu"
								// keepMounted
								anchorEl={selectArtTemplatesAnchor}
								open={Boolean(selectArtTemplatesAnchor)}
								onClose={() => setSelectArtTemplatesAnchor(null)}
							>
								<ListSubheader>{`Max ${ART_VARIABLES.maxSelectableArtTemplate} templates can be selected`}</ListSubheader>
								{artTemplateList.map((artTemp, idx) => {
									let isActiveArtTemplate = activeArtTemplate.mediaId === artTemp.id;
									let disableSelection =
										selectedTemplateIds.length >= ART_VARIABLES.maxSelectableArtTemplate;
									return (
										<MenuItem
											key={`${artTemp.id}-${idx}`}
											// value={artTemp.id}
											disabled={isActiveArtTemplate}
											dense
											onClick={() => {
												if (selectedTemplateIds.includes(artTemp.id)) {
													// remove the template from selection
													setSelectedTemplateIds(
														selectedTemplateIds.filter((item) => item !== artTemp.id)
													);
												} else {
													if (disableSelection) return null;

													if (
														!cachedArtTemplates.find(
															(cachedArtTemp) => cachedArtTemp.mediaId === artTemp.id
														)
													) {
														// this artwork template was not cached, let's fetch & cache the template data
														getArtworkTemplate(artTemp.id, artworkExtra)
															.then((fetchedArtTemplate) => {
																setCachedArtTemplates(
																	cachedArtTemplates.concat([fetchedArtTemplate])
																);
																// add the template to selection
																setSelectedTemplateIds(selectedTemplateIds.concat([artTemp.id]));
															})
															.catch((err) => {
																let msg = err.response ? err.response.data.message : err.message;
																notifyGeneral(`${msg}`, 'error');
															});
													} else {
														// no need to fetch the artwork template, just add it to selected list
														setSelectedTemplateIds(selectedTemplateIds.concat([artTemp.id]));
													}
												}
											}}
										>
											<Checkbox
												checked={selectedTemplateIds.includes(artTemp.id)}
												size="small"
												style={{ padding: 4, marginRight: 4 }}
											/>
											<ListItemText
												primary={artTemp.name}
												primaryTypographyProps={{ variant: 'body2' }}
											/>
										</MenuItem>
									);
								})}
							</Menu>
							<DividerVer />
							{activeArtTemplate.metadata.searchColumnIndices.length > 0 ? (
								<React.Fragment>
									<SearchV2
										className={classes.searchWrapper}
										placeholder="Search..."
										value={searchKeywords}
										OnEnterKeyPressed={(e) => {
											if (searchKeywords === (e.target.value || '')) return;
											setSearchKeywords(e.target.value || '');
											// reset page index
											gotoPage(0);
										}}
										onSearchClick={(keywords) => {
											if (searchKeywords === keywords) return;
											setSearchKeywords(keywords || '');
											// reset page index
											gotoPage(0);
											// }
										}}
										onChange={(e) => {
											debouncedSetSearchKeywords(e);
										}}
										onClearClick={() => {
											if (searchKeywords === '') return;
											setSearchKeywords('');
											// reset page index
											gotoPage(0);
										}}
									/>
									<DividerVer />
								</React.Fragment>
							) : (
								<div className={classes.searchSpacer}></div>
							)}

							<ButtonGroup className={classes.actionButtons} variant="contained" color="inherit">
								{totalSelections > 0 && (
									<Button
										variant="contained"
										endIcon={<SelectionIcon fontSize="small" />}
										onClick={() => {
											if (!showSelections) {
												setShowSelections(true);
												setSkipPageReset(true);
												setRTableData(Object.values(selectedRTableData.current));
												gotoPage(0);
											} else {
												setShowSelections(false);
											}
										}}
									>
										{intl.formatMessage({
											id: !showSelections
												? 'pages.Artwork.components.Esign.ShowMySelectionBtnText'
												: 'pages.Artwork.components.Esign.ShowAllBtnText',
										})}
									</Button>
								)}
								<Button
									variant="contained"
									endIcon={<CreateOwnIcon fontSize="small" />}
									onClick={() => {
										let confirmDialog = {
											size: 'sm',
											title: intl.formatMessage({
												id: 'pages.Artwork.components.Esign.CreateOwnDialogTitle',
											}),
											content: intl.formatMessage({
												id: 'pages.Artwork.components.Esign.CreateOwnDialogContent',
											}),
											confirmCB: () => handleCreateOwn(),
										};
										openGlobalDialog(confirmDialog);
									}}
								>
									{intl.formatMessage({
										id: 'pages.Artwork.components.Esign.CreateOwnBtnText',
									})}
								</Button>
								{userLevel >= ESIGN_ADMIN_MIN_UL && (
									<Button
										variant="contained"
										disabled={totalSelections === 0}
										color={totalSelections > 0 ? 'primary' : 'inherit'}
										endIcon={<BatchSaveIcon fontSize="small" />}
										onClick={() => {
											setOpenBatchDialog(true);
										}}
									>
										{intl.formatMessage({
											id: 'pages.Artwork.components.Esign.BatchBtnText',
										})}
									</Button>
								)}
								<Button
									variant="contained"
									disabled={!ssContent || (ssContent.recordsTotal || 0) === 0}
									color={totalSelections > 0 ? 'primary' : 'inherit'}
									endIcon={<PrintIcon fontSize="small" />}
									onClick={() => {
										if (totalSelections > 0) {
											// print selected rows
											let selectedArtTemplates = selectedTemplateIds
												.map((templateId) => {
													let artTemplate = cachedArtTemplates.find(
														(cachedTemp) => cachedTemp.mediaId === templateId
													);
													if (!artTemplate) return null;

													let rowQty = Object.keys(rowState).reduce((accu, rowId) => {
														return { ...accu, [rowId]: rowState[rowId].qty || 0 };
													}, {});
													let multiplePrintData = buildPrintData(
														Object.values(selectedRTableData.current),
														rowQty,
														artTemplate
													);
													return { artTemplate, multiplePrintData };
												})
												.filter((item) => item);
											if (selectedArtTemplates.length !== selectedTemplateIds.length) {
												// error
												notifyGeneral(
													`Some selected templates are missing, please refresh the page and try again`,
													'error'
												);
											} else {
												setPrintData({
													mode: 'PRINT_SELECTIONS',
													selectedArtTemplates,
													totalItems: Object.keys(rowState).reduce((accu, rowId) => {
														return accu + (rowState[rowId].qty || 0);
													}, 0),
												});
											}
										} else if (
											esignFilters.filters.filter((filter) => Boolean(filter.value)).length > 0 ||
											searchKeywords
											// (searchKeywords && activeArtTemplate.metadata.searchColumnIndices.length > 0)
										) {
											// print searched result
											setPrintData({
												mode: 'PRINT_SEARCH_RESULT',
												selectedArtTemplates: selectedTemplateIds
													.map((templateId) => {
														return {
															artTemplate: cachedArtTemplates.find(
																(cachedTemp) => cachedTemp.mediaId === templateId
															),
														};
													})
													.filter((item) => Boolean(item.artTemplate)),
												totalItems: ssContent.totalFound,
												activeArtTemplate: activeArtTemplate,
												ssId: spreadsheetId,
												filters: esignFilters.filters,
												keywords: searchKeywords,
												adjustOutput: adjustOutput,
											});
										} else {
											// print all
											setPrintData({
												mode: 'PRINT_ALL',
												selectedArtTemplates: [{ artTemplate: activeArtTemplate }],
												totalItems: ssContent.recordsTotal,
												activeArtTemplate: activeArtTemplate,
												ssId: spreadsheetId,
												filters: esignFilters.filters,
												keywords: searchKeywords,
												adjustOutput: adjustOutput,
											});
										}
									}}
								>
									{totalSelections > 0
										? intl.formatMessage(
												{
													id: 'pages.Artwork.components.Esign.PrintBtnText',
												},
												{ totalSelections }
										  )
										: esignFilters.filters.filter((filter) => Boolean(filter.value)).length > 0 ||
										  searchKeywords
										? // (searchKeywords && activeArtTemplate.metadata.searchColumnIndices.length > 0)
										  intl.formatMessage({
												id: 'pages.Artwork.components.Esign.PrintSearchBtnText',
										  })
										: intl.formatMessage({
												id: 'pages.Artwork.components.Esign.PrintAllBtnText',
										  })}
								</Button>
								{totalSelections > 0 && (
									<Button
										variant="contained"
										endIcon={<ClearIcon fontSize="small" />}
										onClick={() => {
											let confirmDialog = {
												size: 'sm',
												title: intl.formatMessage({
													id: 'pages.Artwork.components.Esign.ClearAllDialogTitle',
												}),
												content: intl.formatMessage({
													id: 'pages.Artwork.components.Esign.ClearAllDialogContent',
												}),
												confirmCB: () => {
													setShowSelections(false);
													Object.keys(rowState).forEach((rowId) => {
														setRowState([rowId], () => {
															return {};
														});
													});
												},
											};
											openGlobalDialog(confirmDialog);
										}}
									>
										{intl.formatMessage({
											id: 'pages.Artwork.components.Esign.ClearAllBtnText',
										})}
									</Button>
								)}
								{userLevel >= ESIGN_ADMIN_MIN_UL && (
									<Button
										variant="contained"
										endIcon={<SpreadsheetIcon fontSize="small" />}
										onClick={() => {
											let confirmDialog = {
												size: 'sm',
												title: intl.formatMessage({
													id: 'pages.Artwork.components.Esign.ViewSpreadsheetTitle',
												}),
												content: intl.formatMessage({
													id: 'pages.Artwork.components.Esign.ViewSpreadsheetConfirmText',
												}),
												confirmCB: () => handleGoToSpreadsheet(),
											};
											openGlobalDialog(confirmDialog);
										}}
									>
										{intl.formatMessage({
											id: 'pages.Artwork.components.Esign.SpreadsheetBtnText',
										})}
									</Button>
								)}
								{userLevel >= ESIGN_PROOF_MIN_UL && (
									<Button
										variant="contained"
										endIcon={<ProofIcon fontSize="small" />}
										onClick={() => {
											setOpenProof(true);
										}}
									>
										{intl.formatMessage({
											id: 'pages.Artwork.components.Esign.ProofBtnText',
										})}
									</Button>
								)}
							</ButtonGroup>
						</div>
						<div className={classes.infoRow}>
							{/** filters */}
							{esignFilters.filters.length > 0 && (
								<Button
									// size="small"
									// disabled={esignFilters.filters.length === 0}
									color="inherit"
									startIcon={<FilterIcon />}
									className={classes.button}
									onClick={(e) => setFilterAnchor(filterAnchor ? null : e.target)}
								>
									{intl.formatMessage(
										{ id: 'pages.Artwork.components.Esign.FilterBtnText' },
										{ filterCount: esignFilters.filters.length }
									)}
								</Button>
							)}
							<Filters
								anchorElem={filterAnchor}
								filters={esignFilters.filters}
								onClose={() => setFilterAnchor(null)}
								onFilterChange={(id, val) => {
									setEsignFilters((old) => {
										return {
											...old,
											filters: old.filters.map((filter) => {
												if (filter.id === id) {
													return { ...filter, value: val };
												} else {
													return filter;
												}
											}),
										};
									});
									// reset page index
									gotoPage(0);
								}}
							/>
							<FilterChips
								filters={esignFilters.filters}
								resetFilter={(id) => {
									setEsignFilters((old) => {
										return {
											...old,
											filters: old.filters.map((filter) => {
												if (filter.id === id) {
													return { ...filter, value: '' };
												} else {
													return filter;
												}
											}),
										};
									});
									// reset page index
									gotoPage(0);
								}}
							/>
							<div
								className={classes.adjustWrapper}
								title={intl.formatMessage({
									id: 'pages.Artwork.components.Esign.AdjustOutputTipText',
								})}
							>
								<HelpIcon fontSize="small" color="action" />
								<Typography component="span" noWrap variant="body2" style={{ flex: '1 0 auto' }}>
									{intl.formatMessage({
										id: 'pages.Artwork.components.Esign.AdjustOutputText',
									})}
								</Typography>
								<StyledOutlinedTextFieldSelection
									className={classes.selectOnRight}
									select
									size="small"
									disabled={selectedTemplateIds.length > 1}
									value={`${adjustOutput}`}
									onChange={(e) => {
										setAdjustOutput(Number(e.target.value));
									}}
									InputProps={{
										classes: {
											inputSizeSmall: classes.selectOnRightInputMarginDense,
										},
									}}
									options={adjustOutputOptions}
								/>
							</div>
						</div>
					</div>
					<div className={classes.tableWrapper}>
						<EnhancedRTable
							isStickyHeader={true}
							isLoading={isFetchingSSContent}
							rowHighlightPicker={rowHighlightPicker}
							// pagination props
							handleChangePage={handleChangePage}
							handleChangeRowsPerPage={handleChangeRowsPerPage}
							rowsPerPage={showSelections ? rTableData.length : pageSize}
							disableRowsPerPage={showSelections}
							pageIndex={pageIndex}
							totalNumRows={showSelections ? rTableData.length : (ssContent || {}).totalFound || -1}
							// table props
							getTableProps={tableInst.getTableProps}
							headerGroups={tableInst.headerGroups}
							prepareRow={tableInst.prepareRow}
							page={tableInst.page}
						/>
					</div>
					{/** loader of exporting/saving artwork */}
					<ArtLoading
						open={Boolean(exportArtworkStatus.status)}
						progressLoaderFormat="gif"
						message={exportArtworkStatus.message || ''}
						failureHandler={
							exportArtworkStatus.status === 'FAILED' ? () => setExportArtworkStatus({}) : null
						}
					/>
					{/** Proof dialog */}
					{openProof && (
						<ProofDialog
							open={openProof}
							title={`${activeArtTemplate.name}`}
							ssId={spreadsheetId}
							artTemplate={activeArtTemplate}
							artworkExtra={artworkExtra}
							filters={esignFilters.filters}
							searchKeywords={searchKeywords}
							notifyGeneral={notifyGeneral}
							handleClose={() => setOpenProof(false)}
						/>
					)}
					{/** Print dialog */}
					<PrintDialog
						open={Boolean(printData.mode)}
						adjustOutput={adjustOutput}
						printData={printData}
						artworkExtra={artworkExtra}
						setExportArtworkStatus={setExportArtworkStatus}
						handleClose={() => setPrintData({})}
						notifyGeneral={notifyGeneral}
						activeArtTemplate={activeArtTemplate}
						ssId={spreadsheetId}
						ssContent={ssContent}
						userId={uid}
						userEmail={userEmail}
						filters={esignFilters.filters}
						searchKeywords={searchKeywords}
						domainName={domainName}
					/>

					{openBatchDialog && (
						<BatchDialog
							open={true}
							handleClose={() => setOpenBatchDialog(false)}
							handleBatchingTask={handleBatchingTask}
							templatesProductPickerColumns={selectedTemplateProductPickerColumns}
							ssHeaderColumns={ssContent?.columnHeaders ?? []}
						/>
					)}
				</div>
			) : (
				<ArtLoading
					open={true}
					failureButtonText="Go Back"
					message={initStatus.message || ''}
					failureHandler={initStatus.status === 'FAILED' ? handleGoback : null}
				/>
			)}
		</div>
	);
}

Esign.propTypes = {
	notifyGeneral: PropTypes.func.isRequired,
	openGlobalDialog: PropTypes.func.isRequired,
	// fetchAWSCredential: PropTypes.func.isRequired,
	// resetAWSCredential: PropTypes.func.isRequired,

	// userData: PropTypes.object.isRequired,
	domainName: PropTypes.string.isRequired,
	userLevel: PropTypes.number.isRequired,
	uid: PropTypes.string.isRequired,
	userEmail: PropTypes.string,
};

Esign.defaultProps = {};

const mapStateToProps = (state) => {
	return {
		userLevel: state.authentication.userLevel,
		uid: state.authentication.userId,
		userEmail: state.usermanager?.userData?.email ?? '',
		// userData: { uid: state.authentication.userId, userLevel: state.authentication.userLevel }, // (logged in) user data
		domainName: state.authentication.domainName,
	};
};

/**
 * query parameters:
		- t: template ID
		// - f: product picker field id
		// - ss: spreadsheet id
		// - col: product picker column index
 */
export default connect(mapStateToProps, {
	notifyGeneral,
	// fetchAWSCredential,
	// resetAWSCredential,
	openGlobalDialog,
})(Esign);
