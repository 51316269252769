import React from 'react';

// Constants
import { ART_VARIABLES } from '../../Constants';

// API
import {
	artworkFetchTemplateById,
	fetchArtworkFonts,
	fetchArtworkSpreadsheets,
	fetchArtworkLists,
	fetchArtworkAutoImport,
	fetchArtworkOutputTemplates,
	fetchArtworkCategories,
	fileMgrFetchSSArtTemplateList,
	artworkFullServerGenerator,
	awsCreateS3Client,
	generateArtworkPdfs,
	// fileMgrFetchSSById,
} from 'restful';

import { _, isNumber } from 'utils/libHelper';
import { isNullish } from 'utils/generalHelper';

import {
	geFontListInTemplateFields,
	getColorListInTemplateFields,
	getFieldOutputData,
	loadFontfaceToDocument,
	outputTemplatePlan,
} from 'utils/artwork/artUtilsCommon';
import {
	generateMultipleArtworkToPdfPagesWithServer,
	// multipleArtworkPagesPdfGenerationInBrowserServer,
} from 'utils/artwork/artServerGenerator';
import { convertRemoteFieldsToLocal } from 'utils/artwork/artTemplateConverter';

/**
 * function to progressively load required data
 */
export const initEsignData = async (templateId, setInitStatus) => {
	// try {
	// 1. fetch artwork font list
	setInitStatus({ status: 'OK', message: 'Loading fonts...' });
	const artFonts = await fetchArtworkFonts({}).catch(() => {
		throw new Error('Can not load fonts. Please try again later...');
	});

	// 2. fetch spreadsheet data
	setInitStatus({ status: 'OK', message: 'Loading spreadsheet data...' });
	const artSpreadsheets = await fetchArtworkSpreadsheets({}).catch(() => {
		throw new Error('Can not load spreadsheet data. Please try again later...');
	});

	// 3. fetch list data
	setInitStatus({ status: 'OK', message: 'Loading list data...' });
	const artLists = await fetchArtworkLists({}).catch(() => {
		throw new Error('Can not load list data. Please try again later...');
	});

	// 4. fetch user data
	setInitStatus({ status: 'OK', message: 'Loading user data...' });
	const artAutoImport = await fetchArtworkAutoImport({}).catch(() => {
		throw new Error('Can not load auto-import data. Please try again later...');
	});

	// 5. fetch output template data
	setInitStatus({ status: 'OK', message: 'Loading output template...' });
	const artOutputTemplates = await fetchArtworkOutputTemplates({}).catch(() => {
		throw new Error('Can not load output template. Please try again later...');
	});

	// 6. fetch artwork fields data. (Do we need categories data?)
	setInitStatus({ status: 'OK', message: 'Loading categories...' });
	const artCategories = await fetchArtworkCategories({}).catch(() => {
		throw new Error('Can not load output template. Please try again later...');
	});

	// 7. build artwork extra data
	let originalAutoImportData = artAutoImport.data;
	let autoImportData = {};
	autoImportData['User Standard Data'] = originalAutoImportData.userStandardData.map((item) => ({
		value: `${item.id}:`,
		label: item.label,
	}));
	autoImportData['User Data'] = originalAutoImportData.userData.map((item) => ({
		value: `user:${item.id}`,
		label: item.label,
	}));
	autoImportData['Image Data'] = originalAutoImportData.imageData.map((item) => ({
		value: `image:${item.id}`,
		label: item.label,
	}));
	const artExtra = {
		fonts: artFonts.data.results,
		spreadsheets: artSpreadsheets.data.results,
		lists: artLists.data.results,
		autoImport: autoImportData,
		outputTemplates: artOutputTemplates.data.results,
		categories: artCategories.data.categories,
	};

	// 8. prepare artwork template data
	setInitStatus({ status: 'OK', message: 'Preparing artwork template ...' });
	const artTemplate = await getArtworkTemplate(templateId, artExtra);

	// 9. fetch artwork template list that use the spreadsheet
	setInitStatus({ status: 'OK', message: 'Fetch artwork template list ...' });
	const fetchArtTemplateListRes = await fileMgrFetchSSArtTemplateList({
		ssId: artTemplate.metadata.ssId,
	}).catch(() => {
		throw new Error('Can not fetch artwork template list. Please try again later...');
	});
	const artTemplateList = fetchArtTemplateListRes.data;

	// // 8. fetch artwork fields data
	// setInitStatus({ status: 'OK', message: 'Loading template data...' });
	// const rawTemplate = await artworkFetchTemplateById({ templateId }).catch(() => {
	// 	throw new Error('Can not load artwork template. Please try again later...');
	// });

	// // 9. build artwork template data
	// const artTemplate = convertRemoteFieldsToLocal(rawTemplate.data, artExtra);
	// if (artTemplate.fields.length === 0) {
	// 	throw new Error('Template is empty. Please contact to your administrator...');
	// }

	// // 10. prepare for getting esign spreadsheet data
	// // 	- find spreadsheetId from product picker field and column indices from relavant fields
	// // 	- sort template fields by group & field index.
	// let ordering = {},
	// 	ssId = null,
	// 	columnIndices = [],
	// 	editableColumnIndices = [],
	// 	searchColumnIndices = [],
	// 	filterColumnIndices = [],
	// 	primaryColIndex = -1,
	// 	outputTemplate = {};
	// artTemplate.fields = _.orderBy(
	// 	artTemplate.fields
	// 		.map((f) => {
	// 			if (typeof ordering[f.groupName] !== 'number')
	// 				ordering[f.groupName] =
	// 					0 +
	// 					artTemplate.groups.findIndex((g) => g.name === f.groupName) * artTemplate.fields.length;
	// 			else ordering[f.groupName] += 1;

	// 			// check for ss id
	// 			if (
	// 				f.type === 'text' &&
	// 				!ssId &&
	// 				f.predefinedValue.type === 'spreadsheet' &&
	// 				f.predefinedValue.from
	// 			) {
	// 				ssId = f.predefinedValue.from;
	// 				if (f.predefinedValue.fromColumn) {
	// 					// columnIndices.push(f.predefinedValue.fromColumn);
	// 					primaryColIndex = f.predefinedValue.fromColumn;
	// 				}
	// 			}

	// 			// get the output template settings . Only do it on first occurs
	// 			if (
	// 				!outputTemplate.id &&
	// 				f.predefinedValue &&
	// 				Number(f.predefinedValue.outputTemplate || 0) > 0
	// 			) {
	// 				for (let j = 0; j < artExtra.outputTemplates.length; j++) {
	// 					if (artExtra.outputTemplates[j].id === Number(f.predefinedValue.outputTemplate || 0)) {
	// 						outputTemplate = artExtra.outputTemplates[j];
	// 						break;
	// 					}
	// 				}
	// 			}

	// 			// check for column indices
	// 			if (
	// 				['text', 'image', 'barcode'].includes(f.type) &&
	// 				isNumber(f.autoImportMeta.spreadsheetCell)
	// 			) {
	// 				columnIndices.push(f.autoImportMeta.spreadsheetCell);
	// 				if (f.autoImportMeta.esignEditable)
	// 					editableColumnIndices.push(f.autoImportMeta.spreadsheetCell);
	// 				if (f.autoImportMeta.esignSearchable)
	// 					searchColumnIndices.push(f.autoImportMeta.spreadsheetCell);
	// 				if (f.autoImportMeta.esignFilter)
	// 					filterColumnIndices.push(f.autoImportMeta.spreadsheetCell);
	// 			}

	// 			return { ...f, displayOrder: ordering[f.groupName] };
	// 		})
	// 		.filter((f) => f),
	// 	['displayOrder'],
	// 	['asc']
	// );
	// columnIndices = Array.from(new Set(columnIndices)); // remove duplicated column
	// if (primaryColIndex === -1) {
	// 	throw new Error(`Can not find product picker column. Please contact to your administrator...`);
	// }

	// if (!outputTemplate.id) {
	// 	throw new Error(`Can not retrieve output template. Please contact to your administrator...`);
	// }

	// let maxSpecifyQty = 10,
	// 	plannedQty = 1;
	// if (artTemplate.dimension && Object.keys(outputTemplate).length > 0) {
	// 	let outputPlan = outputTemplatePlan(artTemplate.dimension, outputTemplate);
	// 	plannedQty = outputPlan.numOfItemsInPage;
	// }

	// artTemplate.metadata = {
	// 	outputTemplate,
	// 	ssId,
	// 	primaryColIndex,
	// 	columnIndices,
	// 	editableColumnIndices,
	// 	searchColumnIndices,
	// 	filterColumnIndices,
	// 	maxQtyPerRow: Math.max(maxSpecifyQty, plannedQty),
	// };

	// NB: we don't fetch spreadsheet in initial stage, but do it by table data fetching

	// DONE. we have prepared all necessary data, return them
	return {
		artTemplate,
		artExtra,
		artTemplateList,
		ssId: artTemplate.metadata.ssId,
	};
};

/**
 * initFontface: load font used by template to document so that svg preview can be rendered properly
 * @param {object} fields. all fields in template
 * @param {array} fontList. all available fonts
 * @returns {Promise} void
 */
const initFontface = (fields, fontList) => {
	// get all font faces used in template fields, format: [{ name: fontName, fontUrl: font.path }, ...]
	let fontlistInTemplate = geFontListInTemplateFields(fields, fontList, ART_VARIABLES);
	return Promise.all(
		fontlistInTemplate.map((fontOption) => loadFontfaceToDocument(fontOption))
	).then((results) => {
		let failedFonts = results
			.map((result) => (result.error ? result.error.font.name : null))
			.filter((item) => item)
			.join(', ');
		if (failedFonts) {
			throw new Error(`Failed to load fonts ${failedFonts}`);
		}
	});
};

export const getArtworkTemplate = async (templateId, artExtra) => {
	// fetch artwork fields data
	const rawTemplate = await artworkFetchTemplateById({ templateId }).catch(() => {
		throw new Error('Can not load artwork template. Please try again later...');
	});

	// build artwork template data
	const artTemplate = await convertRemoteFieldsToLocal(rawTemplate.data, artExtra);
	if (artTemplate.fields.length === 0) {
		throw new Error('Template is empty. Please contact to your administrator...');
	} else if (
		!artTemplate.esignPrintFiles.cacheId ||
		!artTemplate.esignPrintFiles.s3Bucket ||
		!artTemplate.esignPrintFiles.s3PathPrefix
	) {
		throw new Error(
			'Print settings are missing from template. Please contact to your administrator...'
		);
	}
	// convert template id to string
	artTemplate.mediaId = artTemplate.mediaId.toString();

	// prepare for getting esign spreadsheet data
	// 	- find spreadsheetId from product picker field and column indices from relavant fields
	// 	- sort template fields by group & field index.
	let ordering = {},
		ssId = null,
		columnIndices = [],
		editableColumnIndices = [],
		searchColumnIndices = [],
		filterColumnIndices = [],
		primaryColIndex = -1,
		outputTemplate = {};
	artTemplate.fields = _.orderBy(
		artTemplate.fields
			.map((f) => {
				if (typeof ordering[f.groupName] !== 'number')
					ordering[f.groupName] =
						0 +
						artTemplate.groups.findIndex((g) => g.name === f.groupName) * artTemplate.fields.length;
				else ordering[f.groupName] += 1;

				// check for ss id
				if (
					f.type === 'text' &&
					!ssId &&
					f.predefinedValue.type === 'spreadsheet' &&
					f.predefinedValue.from
				) {
					ssId = f.predefinedValue.from.toString();
					if (f.predefinedValue.fromColumn) {
						// columnIndices.push(f.predefinedValue.fromColumn);
						primaryColIndex = f.predefinedValue.fromColumn;
						searchColumnIndices.push(primaryColIndex);
					}
				}

				// check for column indices
				if (['text', 'barcode'].includes(f.type) && isNumber(f.autoImportMeta.spreadsheetCell)) {
					columnIndices.push(f.autoImportMeta.spreadsheetCell.toString());
					if (f.autoImportMeta.esignEditable)
						editableColumnIndices.push(f.autoImportMeta.spreadsheetCell);
					if (f.autoImportMeta.esignSearchable)
						searchColumnIndices.push(f.autoImportMeta.spreadsheetCell);
					if (f.autoImportMeta.esignFilter)
						filterColumnIndices.push(f.autoImportMeta.spreadsheetCell);
				}
				if (f.type === 'image' && isNumber(f.autoImportMeta.spreadsheetImageColumn)) {
					// in case of image field, it uses a "ref" column, and is only valid in columnIndices with "$ref:" prefix
					columnIndices.push('$ref:' + f.autoImportMeta.spreadsheetImageColumn);
				}

				return { ...f, displayOrder: ordering[f.groupName] };
			})
			.filter((f) => f),
		['displayOrder'],
		['asc']
	);

	// get the output template settings
	if (
		!outputTemplate.id &&
		artTemplate.outputTemplate &&
		Number(artTemplate.outputTemplate || 0) > 0
	) {
		for (let j = 0; j < artExtra.outputTemplates.length; j++) {
			if (artExtra.outputTemplates[j].id === Number(artTemplate.outputTemplate)) {
				outputTemplate = artExtra.outputTemplates[j];
				break;
			}
		}
	}

	columnIndices = Array.from(new Set(columnIndices)); // remove duplicated column

	if (!ssId) {
		throw new Error(
			`Can not retrieve spreadsheet from artwork template. Please contact to your administrator...`
		);
	}

	if (primaryColIndex === -1) {
		throw new Error(`Can not find product picker column. Please contact to your administrator...`);
	}

	// load tempalte fonts
	await initFontface(artTemplate.fields, artExtra.fonts);

	// if (!outputTemplate.id) {
	// 	throw new Error(`No output template. Please contact to your administrator...`);
	// }

	let maxSpecifyQty = 10,
		plannedQty = 1;
	if (artTemplate.dimension && !isNullish(outputTemplate.id)) {
		let outputPlan = outputTemplatePlan(artTemplate.dimension, outputTemplate);
		plannedQty = outputPlan.numOfItemsInPage;
	}

	artTemplate.metadata = {
		outputTemplate,
		ssId,
		primaryColIndex,
		columnIndices,
		editableColumnIndices,
		searchColumnIndices,
		filterColumnIndices,
		maxQtyPerRow: Math.max(maxSpecifyQty, plannedQty),
		numOfItemsInPage: plannedQty,
	};

	// DONE. we have prepared the artTemplate, return it
	return artTemplate;
};

/**
 * Calculate number of items in output page with considering adjustOutput
 * @param {object} param0
 * @returns number
 */
export const getNumOfItemsInPage = ({ artTemplate, adjustOutput }) => {
	let artworkOutputTemplate = { ...artTemplate.metadata.outputTemplate };
	if (!isNullish(artworkOutputTemplate.id)) {
		artworkOutputTemplate.margin_top =
			(artworkOutputTemplate.margin_top || 0) + (adjustOutput || 0);
	}

	let plannedQty = 1;
	if (artTemplate.dimension && !isNullish(artworkOutputTemplate.id)) {
		let outputPlan = outputTemplatePlan(artTemplate.dimension, artworkOutputTemplate);
		plannedQty = outputPlan.numOfItemsInPage;
	}
	return plannedQty;
};

/**
 * create final print (output) data from selected spreadsheet rows with selected quantity
 * NB: the spreadsheet data can only apply to text, image & barcode fields,
 * 			default value (if applicable) will be used in other field types (pdf, video, grid)
 * NB: the input data returned by this func is the final output dataset, by merging default value in field and spreadsheet data
 * NB: not all the rows in selectedRows has a valid qty in rowQty. e.g. rowQty[ROW_ID] could be undefined or 0
 * @param {array} selectedRows. selected row data in react-table. format:
 	  [
		 {
			 [rowId]: 'xxx',
			 [text_columnIndex]: 'xxx',
			 [image_columnIndex]: {previewUrl: 'xxx', optimisedUrl: 'xxx', highResUrl: 'xxx', mediafileId: 'xxx',},
			 ...
			},
			...
		]
 * @param {object} rowQty. selected quantity per row. format: {ROW_ID: num, ...}
 * @param {object} artTemplate. artwork template object
 *
 * @returns {array} multiplePrintData for generating pdf. exactly same as multiplePrintData in Create.jsx
 * 	Format: [{data: fieldOutputData, num: 17}, ...], fieldOutputData is combination of input data & field default data
 */
export const buildPrintData = (selectedRows, rowQty, artTemplate) => {
	return selectedRows
		.map((rowData) => {
			if ((rowQty[rowData.rowId] ?? 0) <= 0) return null; // the row is not selected
			let rowName = '';
			let inputDataByRow = artTemplate.fields.reduce((accu, field) => {
				if (field.hideInput) return accu; // the field is hidden from input, means no input data in this field
				let inputDataByField = {};
				if (
					field.type === 'text' &&
					field.predefinedValue.type === 'spreadsheet' &&
					field.predefinedValue.from &&
					field.predefinedValue.fromColumn
				) {
					// handle product picker (primary column)
					rowName = rowData[field.predefinedValue.fromColumn];
					inputDataByField[field.id] = { value: rowData[field.predefinedValue.fromColumn] }; // the value could be undefined if the value of the column is not in the rowData
				} else if (
					['text', 'barcode'].includes(field.type) &&
					isNumber(field.autoImportMeta.spreadsheetCell)
				) {
					inputDataByField[field.id] = { value: rowData[field.autoImportMeta.spreadsheetCell] }; // the value could be undefined if the value of the column is not in the rowData
				} else if (
					field.type === 'image' &&
					isNumber(field.autoImportMeta.spreadsheetImageColumn)
				) {
					let colData = rowData['$ref:' + field.autoImportMeta.spreadsheetImageColumn] || {};
					inputDataByField[field.id] = {
						previewUrl: colData.previewUrl,
						optimisedUrl: colData.optimisedUrl,
						highResUrl: colData.highResUrl,
						mediafileId: colData.mediafileId,
					};
				}

				return { ...accu, ...inputDataByField };
			}, {});

			let outputDataByRow = getFieldOutputData(artTemplate.fields, inputDataByRow);
			return { data: outputDataByRow, num: rowQty[rowData.rowId], rowId: rowData.rowId, rowName };
		})
		.filter((item) => item);
};

/**
 * create field input data from selected spreadsheet rows
 * NB: the spreadsheet data can only apply to text, image & barcode fields,
 * 			default value (if applicable) will be used in other field types (pdf, video, grid)
 * NB: the input data returned by this func is the field input dataset, (not final output print data)
 * NB: not all the rows in selectedRows has a valid qty in rowQty. e.g. rowQty[ROW_ID] could be undefined or 0
 * @param {array} selectedRows. selected row data in react-table. format:
 	  [
		 {
			 [rowId]: 'xxx',
			 [text_columnIndex]: 'xxx',
			 [image_columnIndex]: {previewUrl: 'xxx', optimisedUrl: 'xxx', highResUrl: 'xxx', mediafileId: 'xxx',},
			 ...
			},
			...
		]
 * @param {object} rowQty. selected quantity per row. Used to indicate if row is selcted or not. format: {ROW_ID: num, ...}
 * @param {object} artTemplate. artwork template object
 *
 * @returns {array} multiplePrintData for generating pdf. exactly same as multiplePrintData in Create.jsx
 * 	Format: [{fieldInputData: fieldInputputData, num: 17}, ...], fieldInputputData is the input data from spreadsheet
 */
export const buildFieldsInputData = (
	selectedRows,
	// rowQty,
	artTemplate,
	filenameColumnId,
	userOwnerColumnId,
	fallbackUserId
) => {
	return selectedRows
		.map((rowData) => {
			let inputDataByRow = artTemplate.fields.reduce((accu, field) => {
				if (field.hideInput) return accu; // the field is hidden from input, means no input data in this field
				let inputDataByField = {};
				if (
					field.type === 'text' &&
					field.predefinedValue.type === 'spreadsheet' &&
					field.predefinedValue.from &&
					field.predefinedValue.fromColumn
				) {
					// handle product picker (primary column)
					inputDataByField[field.id] = { value: rowData[field.predefinedValue.fromColumn] }; // the value could be undefined if the value of the column is not in the rowData
				} else if (
					['text', 'barcode'].includes(field.type) &&
					isNumber(field.autoImportMeta.spreadsheetCell)
				) {
					inputDataByField[field.id] = { value: rowData[field.autoImportMeta.spreadsheetCell] }; // the value could be undefined if the value of the column is not in the rowData
				} else if (
					field.type === 'image' &&
					isNumber(field.autoImportMeta.spreadsheetImageColumn)
				) {
					let colData = rowData['$ref:' + field.autoImportMeta.spreadsheetImageColumn] || {};
					inputDataByField[field.id] = {
						previewUrl: colData.previewUrl,
						optimisedUrl: colData.optimisedUrl,
						highResUrl: colData.highResUrl,
						mediafileId: colData.mediafileId,
					};
				}

				return { ...accu, ...inputDataByField };
			}, {});

			return {
				fieldInputData: inputDataByRow,
				fileName: rowData[filenameColumnId],
				userId: rowData[userOwnerColumnId] ?? fallbackUserId,
			};
		})
		.filter((item) => item);
};

/**
 * generate artwork pdf
 * NB: template (not output template) background is ignored from Esign output (see comment in ticket VID-3398: https://visualid.atlassian.net/browse/VID-3398)
 *
 * @param {array} multiplePrintData. Format: [{data: fieldOutputData, num: 17}, ...]
 * @returns {Promise} pdfUrl. Cloudfront https url of the generated pdf
 */
export const printArtwork = async ({
	multiplePrintData,
	artTemplate,
	artworkExtra,
	setExportArtworkStatus,
	adjustOutput,
	domainName,
	outputFileS3Url,
}) => {
	const templateFields = artTemplate.fields;
	const artworkOutputTemplate = { ...artTemplate.metadata.outputTemplate };
	if (!isNullish(artworkOutputTemplate.id)) {
		artworkOutputTemplate.margin_top =
			(artworkOutputTemplate.margin_top || 0) + (adjustOutput || 0);
	}
	// all font faces used in template fields, format: [{ name: fontName, fontUrl: font.path }, ...]
	let fontlistInTemplate = geFontListInTemplateFields(
		templateFields,
		artworkExtra.fonts,
		ART_VARIABLES
	);
	let colorList = getColorListInTemplateFields(templateFields);

	setExportArtworkStatus({
		status: 'OK',
		message: (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<div>({artTemplate.name})</div>
				Preparing artwork data...
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						color: 'yellow',
						fontSize: '2em',
						fontWeight: 'bold',
					}}
				>
					Please do not move away from this page
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						color: 'yellow',
						fontSize: '2em',
						fontWeight: 'bold',
					}}
				>
					until the process has finished
				</div>
			</div>
		),
	});
	const s3Client = await awsCreateS3Client();
	let pdfUrl = await generateMultipleArtworkToPdfPagesWithServer(
		templateFields,
		multiplePrintData,
		{
			outputTemplate: artworkOutputTemplate,
			templateSize: artTemplate.dimension, // in pixel
			// templateBGUrl: artworkDesignerTemplate.mediaUrl,
			// NB, template background is ignored from Esign output
			// templateMediaUrl: '', // artTemplate.templateMediaUrl,
			// templatePdfUrl: '', // artTemplate.templatePdfUrl,
			// templateSvgUrl: '', // artTemplate.templateSvgUrl,
			// downloadName: 'multiplePages.pdf',
			animations: ART_VARIABLES.animations,
			CONSTANTS: {
				placeholderSameAsText: ART_VARIABLES.placeholderSameAsText,
				DEFAULT_ANIMATION_DURATION: ART_VARIABLES.DEFAULT_ANIMATION_DURATION,
				DEFAULT_ANIMATION_DELAY: ART_VARIABLES.DEFAULT_ANIMATION_DELAY,
			},
			fontList: fontlistInTemplate,
			colorList: colorList,
			domain: domainName,
			s3Client,
			outputFileS3Url: outputFileS3Url,
			reportProgress: (report) => {
				setExportArtworkStatus({
					status: report.status,
					message: (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<div>({artTemplate.name})</div>
							{report.message.map((msg, idx) => {
								if (!msg) return null;
								return <div key={`multiplePdfReport-${idx}`}>{msg}</div>;
							})}
						</div>
					),
				});
			},
		}
	).then(async (svgPages) => {
		const res = await generateArtworkPdfs({ bodyParams: svgPages });
		return res.data.pdfUrl;
	});
	return pdfUrl;
};

/**
 * generate artwork pdf fully on server side
 * NB: template (not output template) background is ignored from Esign output (see comment in ticket VID-3398: https://visualid.atlassian.net/browse/VID-3398)
 *
 * @param {array} multipleTemplatesPrintData  {multiplePrintData: [{data: fieldOutputData, num: 17}, ...],artTemplate,artworkExtra,adjustOutput,domainName,outputFileS3Url,meta,}
 * @returns {Promise<Array>}
		[
			{
				artTemplateId: templatePrintData.artTemplate.mediaId,
				name: templatePrintData.artTemplate.name,
				pdfWebUrl: pdfUrl,
				errMsg: 'string', // it is available only when pdfWebUrl is NOT created
			},
			...
		]
 */
export const printArtworkFullServerSide = async (multipleTemplatesPrintData) => {
	if (!multipleTemplatesPrintData || multipleTemplatesPrintData.length === 0) return [];

	const reqBody = multipleTemplatesPrintData.map((templatePrintData) => {
		const {
			multiplePrintData,
			artTemplate,
			artworkExtra,
			adjustOutput,
			domainName,
			outputFileS3Url,
			meta,
		} = templatePrintData;

		const templateFields = artTemplate.fields;
		const artworkOutputTemplate = { ...artTemplate.metadata.outputTemplate };
		if (!isNullish(artworkOutputTemplate.id)) {
			artworkOutputTemplate.margin_top =
				(artworkOutputTemplate.margin_top || 0) + (adjustOutput || 0);
		}
		// all font faces used in template fields, format: [{ name: fontName, fontUrl: font.path }, ...]
		let fontlistInTemplate = geFontListInTemplateFields(
			templateFields,
			artworkExtra.fonts,
			ART_VARIABLES
		);
		let colorList = getColorListInTemplateFields(templateFields);

		return {
			fields: templateFields,
			fieldOutputDataArray: multiplePrintData,
			opts: {
				outputTemplate: artworkOutputTemplate,
				templateSize: artTemplate.dimension, // in pixel
				// templateBGUrl: artworkDesignerTemplate.mediaUrl,
				// NB, template background is ignored from Esign output
				animations: ART_VARIABLES.animations,
				CONSTANTS: {
					placeholderSameAsText: ART_VARIABLES.placeholderSameAsText,
					DEFAULT_ANIMATION_DURATION: ART_VARIABLES.DEFAULT_ANIMATION_DURATION,
					DEFAULT_ANIMATION_DELAY: ART_VARIABLES.DEFAULT_ANIMATION_DELAY,
				},
				fontList: fontlistInTemplate,
				colorList: colorList,
				domain: domainName,
				outputFileS3Url: outputFileS3Url,
				fullServerProcess: true,
			},
			meta,
		};
	});

	// // below comment out code is for esign pdf generation local test in toolkit
	// const pdfs = [];
	// const s3Client = await awsCreateS3Client();
	// for (const req of reqBody) {
	// 	try {
	// 		req.opts.s3Client = s3Client;
	// 		req.opts.fullServerProcess = undefined;
	// 		const { pdfWebUrl } = await multipleArtworkPagesPdfGenerationInBrowserServer(
	// 			req.fields,
	// 			req.fieldOutputDataArray,
	// 			req.opts
	// 		);

	// 		pdfs.push({
	// 			...(req.meta ?? {}),
	// 			pdfWebUrl: pdfWebUrl,
	// 		});
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// }
	// if (reqBody[0].meta) return pdfs;

	// call api to generate artwork pdfs
	const res = await artworkFullServerGenerator({
		bodyParams: reqBody,
	}).catch((error) => {
		console.debug(error);
		throw new Error(`We were unable to generate the PDF at this time. Please try again later.`);
	});

	// format of response data
	/**
	 	[
			{
				artTemplateId: templatePrintData.artTemplate.mediaId,
				name: templatePrintData.artTemplate.name,
				pdfWebUrl: pdfUrl,
				errMsg: err.response?.data.message || err.message,
			},
			...
		]
	 */
	return res.data.map((artPdf) => {
		return {
			...(artPdf.meta ?? {}),
			...(artPdf.pdfWebUrl ? { pdfWebUrl: artPdf.pdfWebUrl } : {}),
			...(artPdf.errMsg ? { errMsg: artPdf.errMsg } : {}),
		};
	});
};

/**
 * create react-table data from spreadsheet content
 * NB: ssContent may have all columns regardless the artwork template design
 * @param {object} ssContent. original spreadsheet content from api
 * @param {object} editedRTableData. the react-table rows that were modified
 * @returns {array}
 */
export const convertSSContentToRTableData = (ssContent, editedRTableData) => {
	if (!ssContent) return [];
	let columnType = ssContent.columnHeaders.reduce((accu, colHeader) => {
		return { ...accu, [colHeader.columnId]: colHeader.type };
	}, {});
	let data = ssContent.rows.map((row) => {
		let rowId = row.rowId.toString();
		let colObject =
			editedRTableData[rowId] ||
			row.rowData.reduce(
				(accu, colData) => {
					return {
						...accu,
						[colData.columnId]:
							columnType[colData.columnId] === 'text'
								? colData.value?.trim() ?? ''
								: {
										previewUrl: colData.previewUrl,
										optimisedUrl: colData.optimisedUrl,
										highResUrl: colData.highResUrl,
										mediafileId: colData.mediafileId,
								  },
					};
				},
				{ isMod: false } // add "isMod" field to each row to indicate if the row is edited
			);
		return {
			...colObject,
			rowId: rowId,
		};
	});
	return data;
};

// /**
//  * handle printAll
//  * @param {object} artTemplate
//  * @returns {promise}
//  */
// export const printAll = async ({
// 	artTemplate,
// 	artworkExtra,
// 	setExportArtworkStatus,
// 	adjustOutput,
// }) => {
// 	let fetchingCols = artTemplate.metadata.columnIndices;
// 	let primaryCol = artTemplate.metadata.primaryColIndex.toString();
// 	let selectedRows = [];
// 	setExportArtworkStatus({
// 		status: 'OK',
// 		// message: 'Fetching spreadsheet content...',
// 		message: `(${artTemplate.name}) Prepareing print content...`,
// 	});
// 	// console.log(new Date().toISOString() + 'fetch spreadsheet');
// 	const response = await fileMgrFetchSSById({
// 		ssId: artTemplate.metadata.ssId,
// 		queryParams: {
// 			// offset: 0,
// 			// limit: 200,
// 			columnIndices: fetchingCols.includes(primaryCol)
// 				? fetchingCols.join(',') || null
// 				: [...fetchingCols, primaryCol].join(','),
// 		},
// 	});
// 	selectedRows = convertSSContentToRTableData(response.data, {});

// 	// console.log(`begin to print all...`);
// 	let rowQty = selectedRows.reduce((accu, row) => {
// 		return { ...accu, [row.rowId]: 1 };
// 	}, {});
// 	let multiplePrintData = buildPrintData(selectedRows, rowQty, artTemplate);
// 	// console.log(new Date().toISOString() + 'printArtwork');
// 	return printArtwork({
// 		multiplePrintData,
// 		artworkExtra,
// 		setExportArtworkStatus,
// 		artTemplate,
// 		adjustOutput,
// 	});
// };

export const getPrintFileName = ({
	cacheId,
	ssId,
	artTemplateId,
	filters,
	searchKeywords,
	adjustOutput,
}) => {
	const regexToCleanFileName = /[^a-z0-9\s]/gi;
	// get filters that have value, then sort filters by its id so that final name is consistent
	let selectedFiltersStr = _.sortBy(
		filters.filter((filter) => Boolean(filter.value)),
		(filter) => filter.id.toLowerCase()
	)
		.map((filter) => `${filter.id}-${filter.value.trim()}`.replace(regexToCleanFileName, '-'))
		.join('-');
	let cleanKeywords = searchKeywords.replace(regexToCleanFileName, '-');
	return `${ssId}_${artTemplateId}_${cacheId}_${selectedFiltersStr || 'NoFilter'}_${
		cleanKeywords || 'NoKeywords'
	}_${adjustOutput}`;
};
