import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Card, CardActions, IconButton, CardContent, Grid } from '@mui/material';
// intl lang
// import { useIntl } from 'react-intl';
import { lightGreen, red, grey } from '@mui/material/colors';

import { _ } from 'utils/libHelper';

const useStyles = makeStyles((theme) => ({
	containerBox: {
		display: 'flex',
		position: 'relative',
		width: '100%',
		height: '100%',
		minHeight: `max(120px, calc(100% - 100px))`,
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		alignContent: 'flex-start',
		[theme.breakpoints.down(theme.screenBreakpointDown)]: {
			justifyContent: 'space-evenly',
		},
	},
	itemBox: {
		display: 'flex',
		overflow: 'hidden',
		position: 'relative',
		flex: '0 1 auto',
		alignSelf: 'auto',
		justifyContent: 'center',
		alignItems: 'center',
		border: `1px solid rgba(0,0,0,0.1)`,
		borderRadius: 4,
		height: 'auto',
		margin: theme.spacing(1),
		padding: theme.spacing(1),
		width: '100%',
		'&:hover': {
			borderColor: `rgba(0,0,0,0.3)`,
			boxShadow: theme.customBoxShadow.boxShadow,
		},
		'& img': {
			maxHeight: '100%',
			maxWidth: '100%',
		},
	},
	cardRoot: {
		width: '100%',
		boxShadow: 'unset',
	},
	cardAction: {
		padding: 'unset',
		paddingTop: theme.spacing(1),
		flexWrap: 'wrap',
		gap: theme.spacing(0.4),
	},
	iconButtons: {
		marginLeft: theme.spacing(1 / 2),
		marginRight: theme.spacing(1 / 2),
	},
	iconButtonRootSmaller: {
		fontSize: theme.typography.pxToRem(12),
		padding: theme.spacing(1 / 2),
	},
	iconButtonRootWithBGColor: {
		backgroundColor: lightGreen[300],
		'&:hover': {
			backgroundColor: lightGreen[500],
		},
		'&:disabled': {
			backgroundColor: grey[500],
		},
	},
	iconButtonRootWithRedBGColor: {
		backgroundColor: red[300],
		'&:hover': {
			backgroundColor: red[500],
		},
		'&:disabled': {
			backgroundColor: grey[500],
		},
	},
}));

function PlayerGridView({ screens, columns, playerActions, noDataMsg, ...rest }) {
	const classes = useStyles();
	const [screenEditData, setScreenEditData] = React.useState({});

	return (
		<div className={classes.containerBox}>
			{screens.length > 0 ? (
				screens.map((screen, idx) => {
					return (
						<div key={screen.id + '-' + idx} className={cx(classes.itemBox)}>
							<Card raised className={classes.cardRoot}>
								<CardContent>
									{columns.map((colDef, idx) => {
										const headerVal =
											typeof colDef.header === 'function' ? colDef.header() : colDef.header;

										let value = '';
										if (typeof colDef.cell === 'function') {
											value = colDef.cell({
												getValue: () => colDef.accessorFn(screen),
												row: { original: screen },
											});
										} else if (colDef.cell) {
											value = colDef.cell;
										} else {
											// when column defination has no "cell", we use accessorFn
											value = colDef.accessorFn(screen);
										}

										return (
											<Grid key={idx} direction="row" container sx={{ mx: 0, my: 0.5 }}>
												<Grid item xs={4} container wrap={'nowrap'} component={'span'}>
													{headerVal}
													{':'}
												</Grid>

												<Grid item xs={8}>
													{value}
												</Grid>
											</Grid>
										);
									})}
								</CardContent>
								{Array.isArray(playerActions) && playerActions.length > 0 && (
									<CardActions className={classes.cardAction} disableSpacing={true}>
										{playerActions.map((action, idx) => {
											return action.shouldDisplay({ rowOriginal: screen }) ? (
												<IconButton
													key={screen.id + '' + idx}
													classes={{
														root: cx(
															classes.iconButtonRootSmaller,
															classes.iconButtonRootWithBGColor,
															classes.iconButtons
														),
													}}
													size="small"
													title={action.tooltip}
													disabled={
														typeof action.isDisabled === 'function'
															? action.isDisabled({ rowOriginal: screen })
															: false
													}
													onClick={(e) => {
														let screenDataTemp = { ...screenEditData };
														setScreenEditData({});
														return typeof action.clickHandler === 'function'
															? action.clickHandler({ rowOriginal: screen }, e, screenDataTemp)
															: null;
													}}
												>
													{action.icon}
												</IconButton>
											) : null;
										})}
									</CardActions>
								)}
							</Card>
						</div>
					);
				})
			) : (
				<Typography
					color="error"
					style={{ textAlign: 'center', width: '100%', paddingTop: 50, minHeight: 120 }}
				>
					{noDataMsg}
				</Typography>
			)}
		</div>
	);
}

PlayerGridView.propTypes = {
	screens: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	/**
			* Extra click actions to each screen's data
			 [
						 {
							 shouldDisplay: (cardData)=>true/false, // hook function to verify should the action be displayed for the cardData
							 icon: <AddIcon fontSize="small" />,
							 tooltip: 'add',
							 clickHandler: (cardData, event) => {},
						 },
						 {
							 shouldDisplay: (cardData)=>true/false, // hook function to verify should the action be displayed for the cardData
							 icon: <SaveIcon fontSize="small" />,
							 tooltip: 'Save',
							 clickHandler: (cardData, event) => {},
						 },
					 ],
			*/
	playerActions: PropTypes.arrayOf(PropTypes.object),
	noDataMsg: PropTypes.string,
};

PlayerGridView.defaultProps = {
	noDataMsg: 'No data found!!',
	playerActions: [],
};

export default PlayerGridView;
