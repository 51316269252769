/**
 * As we use React/Redux more and more, we have better understanding of the concept of Redux store:
 * Redux store supposes to be application level data state which can be used cross pages/components
 * Component local-state (by useState()) is the data state used within the component. The performance is better than Redux store in case of changing data
 * With Context and local-state, we can achieve same flexibility like Redux
 */
import config from 'config';
import reduxStore from 'redux/store';
import { axiosRequest } from 'utils/libHelper';

const screensAPIBaseUrl = config.screenAPI.baseUrl;
const authHeaderKey = config.screenAPI.authHeaderKey;

/**
	 * PATCH to update mediafile asset
	 *
	 * @param {object} opts. Request options. Format:
		{
			mediafileId: 'xxx', // mandatary
			bodyParams: {}, // mandatary
		}.
	 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	 */
export const screensPatchMediafileAsset = (opts = {}) => {
	const { mediafileId = null, bodyParams = {} } = opts;
	if (!mediafileId || Object.keys(bodyParams).length === 0)
		return Promise.reject(new Error('Missing required data'));
	let reqUrl = `${screensAPIBaseUrl}/vid/medias/${mediafileId}`;

	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'patch',
		headers: reqHeader,
		data: bodyParams,
		timeout: 10000,
	};

	return axiosRequest(axiosConfig);
};

/**
	 * Duplicate playlist
	 *
	 * @param {object} opts. Request options. Format:
		{
			playlistId: 'xxx', // mandatary
			// queryParams: {},
		}.
	 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	 */
export const screensDuplicatePlaylist = (opts = {}) => {
	const { playlistId } = opts;
	if (!playlistId) return Promise.reject(new Error('Missing playlist ID'));
	let reqUrl = `${screensAPIBaseUrl}/playlists/${playlistId}/duplicate`;

	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'post',
		headers: reqHeader,
		timeout: 10000,
	};

	return axiosRequest(axiosConfig);
};

/**
	 * Restart the player in the Screenboard.
	 *
	 * @param {object} opts. Request options. Format:
		{
			playerId: 'xxx', // mandatary
			bodyParams: {}, // mandatary, data will be a static object.
		}.
	 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	 */
export const setCommandsToPlayer = (opts = {}) => {
	const { playerId, bodyParams = {} } = opts;
	if (!playerId) return Promise.reject(new Error('Missing Player ID'));
	let reqUrl = `${screensAPIBaseUrl}/control/${playerId}/exec`;
	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'post',
		headers: reqHeader,
		data: bodyParams,
		timeout: 10000,
	};

	return axiosRequest(axiosConfig);
};

/**
	* Gets the list of playlist
	*
	* @param {object} opts. Request options. Format:
	 {
		 mediafileId: 'xxx', // mandatary
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const fetchSelectedPlaylist = (opts = {}) => {
	const { mediafileId, queryParams = {} } = opts;
	let reqUrl = `${screensAPIBaseUrl}/vid/medias/${mediafileId}`;

	const authenticationState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authenticationState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
	 * Add to Screen Playlist.
	 *
	 * @param {object} opts. Request options. Format:
		{
			bodyParams: {}, // mandatary, data will be a static object.
		}.
	 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	 */
export const addScreenPlaylist = (opts = {}) => {
	const { bodyParams = {} } = opts;
	let reqUrl = `${screensAPIBaseUrl}/vid/slide`;
	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'post',
		headers: reqHeader,
		data: bodyParams,
		timeout: 10000,
	};

	return axiosRequest(axiosConfig);
};
export const saveSyncPlaylists = (opts = {}) => {
	const { bodyParams = {} } = opts;
	let reqUrl = `${screensAPIBaseUrl}/syncplaylists/save`;
	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'post',
		headers: reqHeader,
		data: bodyParams,
		timeout: 10000,
	};

	return axiosRequest(axiosConfig);
};

export const searchplaylists = (opts = {}) => {
	const { queryParams = {}, bodyParams = {} } = opts;
	let reqUrl = `${screensAPIBaseUrl}/searchplaylists`;

	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'POST',
		headers: reqHeader,
		params: { ...queryParams },
		data: bodyParams,
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};
export const fetchPlaylistSlide = (opts = {}) => {
	const { playlistId, queryParams = {} } = opts;
	let reqUrl = `${screensAPIBaseUrl}/playlists/${playlistId}`;

	const authenticationState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authenticationState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

// /**
// 	* Gets the list of players for reporting
// 	*
// 	* @param {object} opts. Request options. Format:
// 	 {
// 		 queryParams: {crossdomain: true/false}, // mandatary
// 	 }.
// 	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
// 	*/
// export const fetchReportPlayers = (opts = {}) => {
// 	const { queryParams = {} } = opts;
// 	let reqUrl = `${screensAPIBaseUrl}/report/players`;

// 	const authenticationState = reduxStore.getState().authentication;
// 	let reqHeader = {
// 		'Content-Type': 'application/json',
// 		[authHeaderKey]: authenticationState.token,
// 	};
// 	let axiosConfig = {
// 		url: reqUrl,
// 		method: 'get',
// 		headers: reqHeader,
// 		params: { ...queryParams },
// 		timeout: 30000,
// 	};

// 	return axiosRequest(axiosConfig);
// };

/**
	* Search for the list of players for reporting
	*
	* @param {object} opts. Request options. Format:
	 {
		 queryParams: {offset, limit, sortBy, sortByDirection},
		 bodyParams: {filters}
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const searchReportPlayers = (opts = {}) => {
	const { queryParams = {}, bodyParams = {} } = opts;
	let reqUrl = `${screensAPIBaseUrl}/report/searchplayers`;

	const authenticationState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authenticationState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'post',
		headers: reqHeader,
		params: { ...queryParams },
		data: bodyParams,
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
	* Update a Player's detail by Super Admin
	*
	* @param {object} opts. Request options. Format:
	 {
		 playerId, // mandatary
		 bodyParams: {}, // mandatary,
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const patchReportPlayersById = (opts = {}) => {
	const { playerId, bodyParams = {} } = opts;
	let reqUrl = `${screensAPIBaseUrl}/report/players/${playerId}`;

	const authenticationState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authenticationState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'patch',
		headers: reqHeader,
		data: bodyParams,
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
	* Update a list of Players detail by Super Admin
	*
	* @param {object} opts. Request options. Format:
	 {
		 playerId, // mandatary
		 bodyParams: {}, // mandatary,
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const patchBatchReportPlayers = (opts = {}) => {
	const { bodyParams = {} } = opts;
	let reqUrl = `${screensAPIBaseUrl}/report/players`;

	const authenticationState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authenticationState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'patch',
		headers: reqHeader,
		data: bodyParams,
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * Get a list of released version of linuxbox service app
 * @returns
 */
export const fetchBoxServiceReleases = (opts = {}) => {
	const { queryParams = {} } = opts;
	let reqUrl = `${screensAPIBaseUrl}/boxservice/release`;

	const authenticationState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authenticationState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * Set linux box app version
 * @returns
 */
export const patchSetBoxAppVersion = (opts = {}) => {
	const { bodyParams = {} } = opts;
	let reqUrl = `${screensAPIBaseUrl}/players/appversion`;

	const authenticationState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authenticationState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'patch',
		headers: reqHeader,
		data: bodyParams,
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};
/**
	* Gets the list of all/specified domain
	*
	* @param {object} opts. Request options. Format:
	 {
		 domain: 'xxx,yyy,zzz',
		 crossdomain: true/false 
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const fetchMapsData = (opts = {}) => {
	const { queryParams = {} } = opts;
	let reqUrl = `${screensAPIBaseUrl}/report/map`;

	const authenticationState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authenticationState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: {
			...queryParams,
		},
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

export const patchSyncGroupById = (opts = {}) => {
	const { syncGroupId, bodyParams = {} } = opts;
	let reqUrl = `${screensAPIBaseUrl}/syncplaylists/${syncGroupId}`;

	const authenticationState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authenticationState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'patch',
		headers: reqHeader,
		data: bodyParams,
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

export const deleteSyncGroupById = (opts = {}) => {
	const { syncGroupId, queryParams } = opts;
	let reqUrl = `${screensAPIBaseUrl}/syncplaylists/${syncGroupId}`;

	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'DELETE',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		timeout: 10000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * Following are a list of exported functions
 */
// const defaultExport = {
// 	screensPatchMediafileAsset, // PATCH to update mediafile asset
// 	screensDuplicatePlaylist, // Duplicate playlist
// 	fetchReportPlayers, // Gets the list of players for reporting
//  patchReportPlayersById, // Update a Player's detail by Super Admin
//  fetchBoxServiceReleases, // Get a list of released version of linuxbox service app
//  patchSetBoxAppVersion, // Set linux box app version
// };

// export default defaultExport;
