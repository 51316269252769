import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

// Intl Lang
import { FormattedMessage } from 'react-intl';

const ErrorCompStyle = (theme) => ({
	typography: {
		margin: theme.spacing(2),
		position: 'relative',
		top: 70,
	},
	container: {
		width: '100%',
		minHeight: 500,
	},
});

class ErrorComp extends Component {
	render() {
		let { classes } = this.props;
		return (
			<div className={classes.container}>
				<Typography
					align="center"
					color="error"
					variant="h5"
					gutterBottom
					component="div"
					className={classes.typography}
				>
					<FormattedMessage id="components.ErrorComp.OopsMsg" />
				</Typography>
			</div>
		);
	}
}

ErrorComp.propTypes = {
	classes: PropTypes.object.isRequired,
};

ErrorComp.defaultProps = {};

export default withStyles(ErrorCompStyle)(ErrorComp);
