/**
 * As we use React/Redux more and more, we have better understanding of the concept of Redux store:
 * Redux store supposes to be application level data state which can be used cross pages/components
 * Component local-state (by useState()) is the data state used within the component. The performance is better than Redux store in case of changing data
 * With Context and local-state, we can achieve same flexibility like Redux
 * As all the artwork data is only used within the artwork page, we should use local state instead of Redux
 */
import config from 'config';
import reduxStore from 'redux/store';
import { axiosRequest } from 'utils/libHelper';

const artworkAPIBaseUrl = config.filemanagerAPI.baseUrl;
const authHeaderKey = config.filemanagerAPI.authHeaderKey;

/**
 * Get template list (for importing)
 *
 * @param {object} opts. Request options. Format:
  {
		templateId: 'xxx', // mandatary
		queryParams: {
			domain: 'xxxx' // optional. Default will use the domain from Redux store (by user logged in). If specified, it overrides the default
		},
	}.
 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
 */
export const artworkFetchSimilarTemplates = (opts = {}) => {
	const { templateId, queryParams = {} } = opts;
	let reqUrl = `${artworkAPIBaseUrl}/artwork/similartemplates/${templateId}`;

	const authenticationState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authenticationState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: authenticationState.domainName, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * Get template detail data by template id
 * @param {*} opts. Request options. Format: 
 	{
		templateId: 'xxx' // mandatary
	}
 *
 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
 */
export const artworkFetchTemplateById = (opts = {}) => {
	let reqUrl = `${artworkAPIBaseUrl}/artwork/${opts.templateId}`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: reduxStore.getState().authentication.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: {
			domain: reduxStore.getState().authentication.domainName,
			uid: reduxStore.getState().authentication.userId,
		},
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * Get artwork mediafile content
 * @param {*} opts. Request options. Format: 
 	{
		mediafileId: 'xxx' // mandatary
		queryParams: {
			domain: 'xxxx' // optional. Default will use the domain from Redux store (by user logged in). If specified, it overrides the default
		},
	}
 *
 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
 */
export const artworkFetchArtContent = (opts = {}) => {
	const { mediafileId, queryParams = {} } = opts;
	const authState = reduxStore.getState().authentication;
	let reqUrl = `${artworkAPIBaseUrl}/artwork/mediafile/${mediafileId}`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * PUT artwork mediafile content & files
 * @param {*} opts. Request options. Format: 
 	{
		mediafileId: 'xxx' // mandatary
		queryParams: {
			domain: 'xxxx' // optional. Default will use the domain from Redux store (by user logged in). If specified, it overrides the default
		},
		bodyParams:{}, // mandatary. See detail at PUT /artwork/mediafile/{mediafile_id} endpoint in filemanager api
	}
 *
 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
 */
export const artworkPutArtContent = (opts = {}) => {
	const { mediafileId, queryParams = {}, bodyParams } = opts;
	const authState = reduxStore.getState().authentication;
	let reqUrl = `${artworkAPIBaseUrl}/artwork/mediafile/${mediafileId}`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'put',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		data: bodyParams,
		timeout: 180000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * POST save artwork
 * @param {*} opts. Request options. Format: 
 	{
		templateId: 'xxx' // mandatary
		queryParams: {
			domain: 'xxxx' // optional. Default will use the domain from Redux store (by user logged in). If specified, it overrides the default
		},
		bodyParams:{}, // mandatary. See detail at POST /artwork/save/{artwork_id} endpoint in filemanager api
	}
 *
 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
 */
export const artworkSaveArtwork = (opts = {}) => {
	const { templateId, queryParams = {}, bodyParams } = opts;
	const authState = reduxStore.getState().authentication;
	let reqUrl = `${artworkAPIBaseUrl}/artwork/save/${templateId}`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'POST',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		data: bodyParams,
		timeout: 180000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * POST Batch save artworks
 * @param {*} opts. Request options. Format:
 	{
		queryParams: {
			domain: 'xxxx' // optional. Default will use the domain from Redux store (by user logged in). If specified, it overrides the default
		},
		bodyParams:{}, // mandatary. See detail at POST /artwork/save/{artwork_id} endpoint in filemanager api
	}
 *
 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
 */
export const batchSaveArtwork = (opts = {}) => {
	const { queryParams = {}, bodyParams } = opts;
	const authState = reduxStore.getState().authentication;
	let reqUrl = `${artworkAPIBaseUrl}/artwork/batchsave`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'POST',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		data: bodyParams,
		timeout: 600000,
	};

	return axiosRequest(axiosConfig);
};

//////////////// artwork extra data //////////////

/**
 * Get all available fonts in a domain for artwork
 * @param {*} opts. Request options. Format:
 	{
		queryParams: {
			domain: 'xxxx' // optional. Default will use the domain from Redux store (by user logged in). If specified, it overrides the default
		},
	}
 *
 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
 */
export const fetchArtworkFonts = (opts = {}) => {
	const { queryParams = {} } = opts;
	const authState = reduxStore.getState().authentication;
	let reqUrl = `${artworkAPIBaseUrl}/fonts`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * fetch spreadsheet list for artwork
 * @param {*} opts. Request options. Format:
 	{
		queryParams: {
			domain: 'xxxx' // optional. Default will use the domain from Redux store (by user logged in). If specified, it overrides the default
		},
	}
 *
 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
 */
export const fetchArtworkSpreadsheets = (opts = {}) => {
	const { queryParams = {} } = opts;
	const authState = reduxStore.getState().authentication;
	let reqUrl = `${artworkAPIBaseUrl}/spreadsheets`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * fetch lists for artwork
 * @param {*} opts. Request options. Format:
 	{
		queryParams: {
			domain: 'xxxx' // optional. Default will use the domain from Redux store (by user logged in). If specified, it overrides the default
		},
	}
 *
 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
 */
export const fetchArtworkLists = (opts = {}) => {
	const { queryParams = {} } = opts;
	const authState = reduxStore.getState().authentication;
	let reqUrl = `${artworkAPIBaseUrl}/lists`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * fetch auto-import for artwork
 * @param {*} opts. Request options. Format:
 	{
		queryParams: {
			domain: 'xxxx' // optional. Default will use the domain from Redux store (by user logged in). If specified, it overrides the default
		},
	}
 *
 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
 */
export const fetchArtworkAutoImport = (opts = {}) => {
	const { queryParams = {} } = opts;
	const authState = reduxStore.getState().authentication;
	let reqUrl = `${artworkAPIBaseUrl}/artwork/autoimport`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * fetch output templates for artwork
 * @param {*} opts. Request options. Format:
 	{
		queryParams: {
			domain: 'xxxx' // optional. Default will use the domain from Redux store (by user logged in). If specified, it overrides the default
		},
	}
 *
 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
 */
export const fetchArtworkOutputTemplates = (opts = {}) => {
	const { queryParams = {} } = opts;
	const authState = reduxStore.getState().authentication;
	let reqUrl = `${artworkAPIBaseUrl}/artwork/outputtemplates`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * fetch categories for artwork
 * @param {*} opts. Request options. Format:
 	{
		queryParams: {
			domain: 'xxxx' // optional. Default will use the domain from Redux store (by user logged in). If specified, it overrides the default
		},
	}
 *
 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
 */
export const fetchArtworkCategories = (opts = {}) => {
	const { queryParams = {} } = opts;
	const authState = reduxStore.getState().authentication;
	let reqUrl = `${artworkAPIBaseUrl}/categories`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
 * compress artwork pdfs
 * @param {*} opts. Request options. Format:
 	{
		queryParams: {
			domain: 'xxxx' // optional. Default will use the domain from Redux store (by user logged in). If specified, it overrides the default
		},
		bodyParams: {}
	}
 *
 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
 */
export const compressArtworkPdfs = (opts = {}) => {
	const { queryParams = {}, bodyParams } = opts;
	const authState = reduxStore.getState().authentication;
	let reqUrl = `${artworkAPIBaseUrl}/artwork/compress`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'post',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		data: bodyParams,
		timeout: 300000, // compression may take longer time, hence set timetout to 5 minutes
	};

	return axiosRequest(axiosConfig);
};

/**
 * merge artwork pdfs
 * @param {*} opts. Request options. Format:
 	{
		queryParams: {
			domain: 'xxxx' // optional. Default will use the domain from Redux store (by user logged in). If specified, it overrides the default
		},
		bodyParams: {}
	}
 *
 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
 */
export const mergeArtworkPdfs = (opts = {}) => {
	const { queryParams = {}, bodyParams } = opts;
	const authState = reduxStore.getState().authentication;
	let reqUrl = `${artworkAPIBaseUrl}/artwork/merge`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'post',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		data: bodyParams,
		timeout: 600000, // merging may take longer time, hence set timetout to 10 minutes
	};

	return axiosRequest(axiosConfig);
};

/**
 * generate artwork pdfs
 * @param {*} opts. Request options. Format:
 	{
		queryParams: {
			domain: 'xxxx' // optional. Default will use the domain from Redux store (by user logged in). If specified, it overrides the default
		},
		bodyParams: {}
	}
 *
 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
 */
export const generateArtworkPdfs = (opts = {}) => {
	const { queryParams = {}, bodyParams } = opts;
	const authState = reduxStore.getState().authentication;
	let reqUrl = `${artworkAPIBaseUrl}/artwork/generate`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'post',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		data: bodyParams,
		timeout: 1200000, // there could be many pdf pages, hence set timetout to 20 minutes
	};

	return axiosRequest(axiosConfig);
};

/**
 * generate artwork pdfs
 * @param {*} opts. Request options. Format:
 	{
		queryParams: {
			domain: 'xxxx' // optional. Default will use the domain from Redux store (by user logged in). If specified, it overrides the default
		},
		bodyParams: {}
	}
 *
 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
 */
export const artworkFullServerGenerator = (opts = {}) => {
	const { queryParams = {}, bodyParams } = opts;
	const authState = reduxStore.getState().authentication;
	let reqUrl = `${artworkAPIBaseUrl}/artwork/servergen`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'post',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		data: bodyParams,
		timeout: 1800000, // there could be many pdf pages, hence set timetout to 30 minutes
	};

	return axiosRequest(axiosConfig);
};

/**
 * log Esign generation
 * @param {*} opts. Request options. Format:
 	{
		queryParams: {
			domain: 'xxxx' // optional. Default will use the domain from Redux store (by user logged in). If specified, it overrides the default
		},
		bodyParams: {}
	}
 *
 * @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
 */
export const logEsignGeneration = (opts = {}) => {
	const { queryParams = {}, bodyParams } = opts;
	const authState = reduxStore.getState().authentication;
	let reqUrl = `${artworkAPIBaseUrl}/artwork/logesign`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'post',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		data: bodyParams,
		timeout: 10000, // this should be done quick, 10s is enough
	};

	return axiosRequest(axiosConfig);
};
/**
 * Following are a list of exported functions
 */
// const defaultExport = {
// 	artworkFetchSimilarTemplates, // Get template list (for importing)
// 	artworkFetchTemplateById, // Get template detail data by template id
// 	artworkFetchArtContent, // Get artwork mediafile content
// 	artworkPutArtContent, // PUT artwork mediafile content & files
// 	artworkSaveArtwork, // POST save artwork
//	batchSaveArtwork, // POST Batch save artwork
// 	fetchArtworkFonts, // Get all available fonts for artwork
// 	fetchArtworkSpreadsheets, // fetch spreadsheet list for artwork
// 	fetchArtworkLists, // fetch lists for artwork
// 	fetchArtworkAutoImport, // fetch auto-import for artwork
// 	fetchArtworkOutputTemplates, // fetch output templates for artwork
// 	fetchArtworkCategories, // fetch categories for artwork
// compressArtworkPdfs, // compress artwork pdfs
// mergeArtworkPdfs, // merge artwork pdfs
// generateArtworkPdfs, // generate artwork pdfs
// };
