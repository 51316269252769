import React from 'react';

import PropTypes from 'prop-types';
import { Typography, TextField, Tooltip, Box, Fab, Button } from '@mui/material';

// icons
import { Save as SaveIcon, Add as AddIcon, Cancel as CancelIcon } from '@mui/icons-material';
import { useIntl } from 'react-intl';

import { NameAliasAvatar } from 'components/index.js';
import { toLocaleDateTime } from 'utils/generalHelper.js';

const CommentRowComp = ({ userAlias, timestamp, message }) => {
	userAlias = userAlias || 'XX';
	return (
		<Box sx={{ display: 'flex', borderBottom: '1px solid #eeeeee', padding: 1 }}>
			<NameAliasAvatar nameAlias={userAlias} sx={{ display: 'inline-flex', marginRight: 2 }} />
			<Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
				<Typography variant="body1">{message}</Typography>
				<Typography
					noWrap
					variant="caption"
					sx={{
						fontStyle: 'italic',
					}}
				>
					{toLocaleDateTime(new Date(timestamp), { second: '2-digit' })}
				</Typography>
			</Box>
		</Box>
	);
};

export const AddCommentComp = ({ handleCancelAddComment, handleNewComment }) => {
	const intl = useIntl();
	const commentRef = React.useRef();

	React.useEffect(() => {
		if (commentRef.current) {
			commentRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, []);

	return (
		<Box
			sx={{
				display: 'flex',
				borderBottom: '1px solid #eeeeee',
				padding: 1,
				alignItems: 'center',
				flexDirection: 'column',
			}}
		>
			<TextField
				required
				inputRef={commentRef}
				id="addComment"
				sx={{ minWidth: 250, width: '90%' }}
				label={intl.formatMessage({
					id: 'pages.ScreenManager.components.PlayerUniverse.AddCommentDialogLabel',
				})}
				fullWidth
				variant="outlined"
				multiline
				rows={4}
				size="small"
				inputProps={{ style: { resize: 'vertical' } }}
				InputProps={{
					sx: { fontSize: 'inherit' },
				}}
			/>
			<Box
				sx={{
					paddingTop: 2,
					// paddingBottom: 1,
					display: 'flex',
					width: '100%',
					alignItems: 'center',
					justifyContent: 'center',
					gap: 2,
				}}
			>
				{/* OK button */}
				<Button
					variant="contained"
					color="primary"
					title={intl.formatMessage({
						id: 'GENERAL.Add',
					})}
					onClick={() => {
						handleNewComment(commentRef.current?.value);
					}}
				>
					{intl.formatMessage({ id: 'GENERAL.Save' })}
					<SaveIcon sx={{ marginLeft: 1 }} />
				</Button>

				<Button
					variant="contained"
					color="secondary"
					title={intl.formatMessage({
						id: 'GENERAL.Cancel',
					})}
					onClick={() => {
						handleCancelAddComment();
					}}
				>
					{intl.formatMessage({ id: 'GENERAL.Cancel' })}
					<CancelIcon sx={{ marginLeft: 1 }} />
				</Button>
			</Box>
		</Box>
	);
};

function PlayerCommentComp({ player, handleAddComment }) {
	const intl = useIntl();

	const comments = [...(player.comments || [])];
	const [openAddComment, setOpenAddComment] = React.useState(false);

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', minHeight: 200 }}>
			{/* add new comment */}
			{openAddComment && (
				<AddCommentComp
					handleCancelAddComment={() => setOpenAddComment(false)}
					handleNewComment={async (commentString) => {
						try {
							await handleAddComment(commentString);
							setOpenAddComment(false);
						} catch (error) {
							// the error should be handled by parent, ignore it
						}
					}}
				/>
			)}
			{/* Existing comments */}
			{comments.length > 0 ? (
				comments.map((comment, idx) => {
					const { timestamp, message, userAlias } = comment;
					return (
						<CommentRowComp
							key={idx}
							timestamp={timestamp}
							message={message}
							userAlias={userAlias}
						/>
					);
				})
			) : openAddComment ? null : (
				<Typography sx={{ margin: 'auto', padding: 1, color: 'rose.main' }} variant="h4">
					{intl.formatMessage({
						id: 'pages.Admin.components.PlayerUniverse.NoCommentMsg',
					})}
				</Typography>
			)}

			<Fab
				aria-label="add"
				sx={{ position: 'absolute', bottom: 80, right: 30 }}
				color="primary"
				disabled={openAddComment}
				onClick={() => setOpenAddComment(true)}
			>
				<Tooltip
					title={intl.formatMessage({
						id: 'pages.Admin.components.PlayerUniverse.AddCommentTooltip',
					})}
				>
					<AddIcon fontSize="large" />
				</Tooltip>
			</Fab>
		</Box>
	);
}

PlayerCommentComp.propTypes = {
	/**
	 * handleAddComment function (async): async (comment)=>{}. comment is string
	 */
	handleAddComment: PropTypes.func.isRequired, // must be async function (or return Promise)
	player: PropTypes.object.isRequired,
};

PlayerCommentComp.defaultProps = {};

export default PlayerCommentComp;
