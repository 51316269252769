import config from 'config';

// artwork
export const ROUTES_PATH_ARTWORK_DESIGNER = `${config.basePath}/artdesign`;
export const ROUTES_PATH_ARTWORK_CREATOR = `${config.basePath}/artcreate`;
export const ROUTES_PATH_ARTWORK_ESIGN = `${config.basePath}/artesign`;

// fileuploader
export const ROUTES_PATH_FILEUPLOAD = `${config.basePath}/fileupload`;

// home page
export const ROUTES_PATH_HOME = `${config.basePath}/home`;

// media file explore
export const ROUTES_PATH_MEDIAFILE_LIST = `${config.basePath}/explore`;
export const ROUTES_PATH_MEDIAFILE_DETAILS = `${config.basePath}/mediafile`;

// screen pages
export const ROUTES_PATH_SCREENMANAGER = `${config.basePath}/screen_manager`;
export const ROUTES_PATH_SCREENMANAGER_SCREENS = `${config.basePath}/screen_manager/screens`;
export const ROUTES_PATH_SCREENMANAGER_SCREEN_SCHEDULE = `${config.basePath}/screen_manager/screens/schedule`;
export const ROUTES_PATH_SCREENMANAGER_PLAYLISTS = `${config.basePath}/screen_manager/playlists`;
export const ROUTES_PATH_SCREENMANAGER_DEPARTMENTS = `${config.basePath}/screen_manager/departments`;
export const ROUTES_PATH_SCREENMANAGER_SCHEDULES = `${config.basePath}/screen_manager/schedules`;
export const ROUTES_PATH_SCREENMANAGER_SCREENBOARD = `${config.basePath}/screen_manager/screenboard`;
export const ROUTES_PATH_SCREENMANAGER_PLAYER_UNIVERSE = `${config.basePath}/screen_manager/playerUniverse`;
export const ROUTES_PATH_SCREENMANAGER_SLIDES = `${config.basePath}/screen_manager/playlists/slides`;
export const ROUTES_PATH_SCREENMANAGER_PLAYLISTS_Synchronised = `${config.basePath}/screen_manager/playlists/synchronised`;

// my files
export const ROUTES_PATH_MYFILES = `${config.basePath}/myfiles`;
export const ROUTES_PATH_MYFILES_MEDIAFILE_LIST = `${config.basePath}/myfiles/explore`;

// user messages
export const ROUTES_PATH_USERMESSAGES_LIST = `${config.basePath}/messages`;

// Admin
export const ROUTES_PATH_ADMIN = `${config.basePath}/admin`;
export const ROUTES_PATH_ADMIN_PLAYER_UNIVERSE = `${config.basePath}/admin/playeruniverse`;
// domain settings
export const ROUTES_PATH_EDIT_DOMAIN_SETTINGS = `${config.basePath}/admin/settings`;

// External Login
export const ROUTES_PATH_SSO_LOGIN = `${config.basePath}/sso`;

// Login
export const ROUTES_PATH_LOGIN = `${config.basePath}/login`;
