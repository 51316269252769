import React from 'react';

import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import { stringToColor } from 'utils/generalHelper.js';

function NameAliasAvatar({ nameAlias, sx = {}, avatarProps = {} }) {
	return (
		<Avatar
			sx={{
				bgcolor: stringToColor(nameAlias),
				width: 24,
				height: 24,
				fontSize: 12,
				...sx,
			}}
			component="span"
			variant="string"
			{...avatarProps}
		>
			{nameAlias}
		</Avatar>
	);
}

NameAliasAvatar.propTypes = {
	nameAlias: PropTypes.string.isRequired,
	sx: PropTypes.object,
	avatarProps: PropTypes.object,
};
export default NameAliasAvatar;
