import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { APIProvider, Map, useMap, InfoWindow } from '@vis.gl/react-google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { getRouteScreenPlayerUniverse } from 'pages/Admin/Constants';
import { renderToString } from 'react-dom/server';
import makeStyles from '@mui/styles/makeStyles';
import config from 'config';
import { Button, Link } from '@mui/material';
// icons
import { SvgIcon } from '@mui/material';
import FmdGoodSharpIcon from '@mui/icons-material/FmdGoodSharp';
import WrongLocationSharpIcon from '@mui/icons-material/WrongLocationSharp';
import { Report as InactiveIcon, Check as ActiveIcon } from '@mui/icons-material';
const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100%',
		position: 'relative',
	},
	infoWindowContianer: {
		// width: 'auto',
		height: 'auto',
		minWidth: '250px',
	},
	infoText: {
		marginTop: '6px',
		marginBottom: '4px',
		textTransform: 'capitalize',
	},
	// TODO - remove if irrelevant
	screenCount: {
		marginTop: '6px',
		marginBottom: '4px',
		marginRight: '34px',
	},
	hoverCursor: {
		'&:hover': {
			cursor: 'pointer',
		},
	},
	inactiveIcon: {
		color: '#CB030A',
		height: 30,
		width: 30,
	},
	activeIcon: {
		color: 'green',
		height: 30,
		width: 30,
	},
	loaderContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '66vh',
	},
	mapContainer: {
		position: 'relative',
		width: '100%',
		height: '66vh',
		// overflow: 'hidden',
	},
	loaderOverlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent background
		zIndex: 1,
	},
	screensDetailsNew: {
		padding: '10px',
		backgroundColor: '#f0f0f0',
		border: '1px solid #ccc',
		borderRadius: '4px',
		marginTop: '10px',
	},
	screensDetails: {
		display: 'flex',
		// justifyContent: 'space-between',
		// fontWeight: 'bold',
		flexDirection: 'column',
		maxHeight: '134px',
		// minHeight: '100px',
		overflow: 'auto',
		marginBottom: '10px',
		// padding: '10px',
		// backgroundColor: '#f0f0f0',
		border: '1px solid #ccc',
		borderRadius: '0px 0px 4px 4px',
		// marginTop: '10px',
	},
	screensList: {
		display: 'grid',
		gridTemplateColumns: '1fr ',
		gap: '4px',
		// backgroundColor: 'red',
	},
	screenDetail: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '4px',
		// backgroundColor: '#fff',
		borderBottom: '1px solid #ddd',
		// borderRadius: '4px',
	},
	screenName: {
		fontWeight: 'bold',
		// maxWidth: '150px',  // Adjust the maximum width as needed
		//   whiteSpace: 'nowrap',
		//   overflow: 'hidden',
		//   textOverflow: 'ellipsis',
	},
	screenStatus: {
		color: '#555',
	},
	success: {
		color: theme.palette.success.main,
	},
	/**
	 * ----------------------------------------
	 * animation shake (ring)
	 * ----------------------------------------
	 */
	'@keyframes shake': theme.animations['@keyframes ring'],
	shake: {
		animation: '$shake .3s infinite',
	},
	screensHeader: {
		display: 'flex',
		fontWeight: 'bold',
		justifyContent: 'space-between',
		backgroundColor: '#ddd',
		padding: '8px',
		// borderBottom: '1px solid #ccc',
		borderRadius: '4px 4px 0px 0px',
		marginTop: '10px',
	},
	screenNameHeader: {
		fontWeight: 'bold',
	},
	screenStatusHeader: {
		color: '#555',
	},
}));

const googleAPIKey = config.googleAPI.apiKey;
const googleMapId = config.googleAPI.apiKey;
const defaultCenter = {
	lat: 53.35014, // Dublin
	lng: -6.266155, // Dublin
};
const createSvgIconUrl = (IconComponent, color) => {
	const svgString = renderToString(
		<SvgIcon component={IconComponent} viewBox="0 0 24 24" width="34" height="34" fill={color} />
	);
	const cleanedSvgString = svgString
		.replace(/class="[^"]*"/g, '')
		.replace(/style="[^"]*"/g, '')
		.replace('<svg', '<svg xmlns="http://www.w3.org/2000/svg"')
		.replace('<path', `<path fill="${color}"`);
	return `data:image/svg+xml;utf8,${encodeURIComponent(cleanedSvgString)}`;
};
const MapWithMarker = ({ mapsData }) => {
	const classes = useStyles();
	const [selectedLocation, setSelectedLocation] = useState(null);
	const markerClusterRef = useRef(null);
	const map = useMap();

	useEffect(() => {
		if (map) {
			const svgActiveIcon = createSvgIconUrl(FmdGoodSharpIcon, 'green');
			const markers = mapsData
				.filter((location) => location.locationLatLong)
				.map((location) => {
					// function to calculate the difference in hours between two dates
					const getHoursDifference = (date1, date2) => {
						return Math.abs(new Date(date1) - new Date(date2)) / 36e5;
					};
					const currentDate = new Date();
					let markerColor;
					let allNotActivated = true;
					let allOfflineMoreThan24Hours = true;
					let allOfflineLessThan24Hours = true;

					location.screens.forEach((screen) => {
						const lastConnectedAt = screen.lastConnectedAt || null;
						const hoursInactive = lastConnectedAt
							? getHoursDifference(currentDate, lastConnectedAt)
							: null;

						if (lastConnectedAt !== null) {
							allNotActivated = false;
						}

						if (screen.status === 'INACTIVE' && hoursInactive !== null) {
							if (hoursInactive > 24) {
								allOfflineLessThan24Hours = false;
							} else {
								allOfflineMoreThan24Hours = false;
							}
						} else {
							allOfflineMoreThan24Hours = allOfflineLessThan24Hours = false;
						}
					});

					// Determine the marker color based on conditions
					if (!location.hasInactiveScreen) {
						markerColor = 'green'; // If any screen is active
					} else if (allNotActivated) {
						markerColor = 'blue'; // All screens are "not yet activated"
					} else if (allOfflineMoreThan24Hours) {
						markerColor = 'black'; // All screens are offline for more than 24 hours
					} else if (allOfflineLessThan24Hours) {
						markerColor = 'orange'; // All offline screens are offline for less than 24 hours
					} else {
						markerColor = '#CB030A'; // At least one screen is offline for more than 24 hours
					}

					// Update the screens with inActiveIconColor
					location.screens = location.screens.map((screen) => ({
						...screen,
						inActiveIconColor: markerColor,
					}));
					let svgInActiveIcon = createSvgIconUrl(WrongLocationSharpIcon, markerColor);

					const marker = new window.google.maps.Marker({
						position: location.locationLatLong,
						title: location.fullLocation,
						icon: location.hasInactiveScreen ? svgInActiveIcon : svgActiveIcon,
					});

					marker.addListener('click', () => {
						setSelectedLocation({
							domain: location.domain,
							screenStatus: location.hasInactiveScreen ? 'Inactive' : 'Active',
							screens: location.screens.sort((a) => (a.status === 'INACTIVE' ? -1 : 1)),
							...location,
						});
					});

					return marker;
				});
			if (markerClusterRef.current) {
				markerClusterRef.current.clearMarkers();
			}

			markerClusterRef.current = new MarkerClusterer({ markers, map });
		}
	}, [map, mapsData]);
	return (
		<>
			{selectedLocation && (
				<InfoWindow
					position={selectedLocation.locationLatLong}
					headerDisabled={true}
					onCloseClick={() => {
						setSelectedLocation(null);
					}}
				>
					<div className={classes.infoWindowContianer}>
						<h2 className={classes.infoText}>{selectedLocation.domain}</h2>
						<span>
							<Link
								variant="body1"
								underline="always"
								target="_blank"
								rel="noopener"
								href={getRouteScreenPlayerUniverse(
									selectedLocation.domain,
									selectedLocation.userInfo.email,
									selectedLocation.uid
								)}
								title={selectedLocation.fullLocation}
							>
								{selectedLocation.userInfo.company}
							</Link>
						</span>
						<div className={classes.screensHeader}>
							<div className={classes.screenNameHeader}>Name</div>
							<div className={classes.screenStatusHeader}>Status</div>
						</div>
						<div className={classes.screensDetails}>
							<div className={classes.screensList}>
								{selectedLocation.screens.map((screen) => (
									<div key={screen.id} className={classes.screenDetail}>
										<div className={classes.screenName}>{screen.name}</div>
										<div className={classes.screenStatus}>
											{screen.status === 'ACTIVE' ? (
												<ActiveIcon className={classes.success} style={{ fontSize: 30 }} />
											) : (
												<InactiveIcon
													className={classes.shake}
													style={{
														fontSize: 30,
														color: screen.inActiveIconColor,
													}}
												/>
											)}
										</div>
									</div>
								))}
							</div>
						</div>
						<Button
							onClick={() => {
								setSelectedLocation(null);
							}}
							size="small"
							variant="contained"
						>
							{'Close'}
						</Button>
					</div>
				</InfoWindow>
			)}
		</>
	);
};

function Maps({ mapsData }) {
	const classes = useStyles();

	return (
		<>
			<div className={classes.mapContainer}>
				<APIProvider apiKey={googleAPIKey}>
					<Map
						mapId={googleMapId}
						defaultCenter={defaultCenter}
						mapContainerStyle={classes.mapContainer}
						defaultZoom={6}
					>
						<MapWithMarker mapsData={mapsData} />
					</Map>
				</APIProvider>
			</div>
		</>
	);
}

Maps.propTypes = {
	mapsData: PropTypes.array.isRequired,
};

export default Maps;
