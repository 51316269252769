/**
 * As we use React/Redux more and more, we have better understanding of the concept of Redux store:
 * Redux store supposes to be application level data state which can be used cross pages/components
 * Component local-state (by useState()) is the data state used within the component. The performance is better than Redux store in case of changing data
 * With Context and local-state, we can achieve same flexibility like Redux
 */
import config from 'config';
import reduxStore from 'redux/store';
import { axiosRequest } from 'utils/libHelper';

const userManagerAPIBaseUrl = config.usermanagerAPI.baseUrl;
const authHeaderKey = config.usermanagerAPI.authHeaderKey;

const externalSSOBaseUrl = config.externalSSOAPI.baseUrl;

/**
	* Following are a list of exported functions for userManager use cases:
		{
		 userManagerFetchUserGroups, // Get user group list
		}
	*/

/**
	* Get user group list
	*
	* @param {object} opts. Request options. Format:
	 {
		 queryParams: {
			 domain: 'xxxx', // optional. Default will use the domain in Redux store (by user logged in). If specified, it overrides the default
			 groupName: 'xxxx', // optional. search string to look up user groups by name
		 },
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const userManagerFetchUserGroups = (opts = {}) => {
	const { queryParams = {} } = opts;
	let reqUrl = `${userManagerAPIBaseUrl}/userGroups`;

	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: authState.domainName, userId: authState.userId, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
	* Get list of user messages
	*
	* @param {object} opts. Request options. Format:
	 {
		 queryParams: {
			 domain: 'xxxx', // optional. Default will use the domain in Redux store (by user logged in). If specified, it overrides the default
			 userId: 'xxxx', // optional. Default will use the user id in Redux store (by user logged in). If specified, it overrides the default
			 offset: 0, // optional. Default 0
			 limit: 20, // optional. Default 20
			 sortBy: "xxx", // optional
			 sortDirection: "asc | desc", // optional
		 },
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const userManagerFetchMessages = (opts = {}) => {
	const { queryParams = {} } = opts;
	const authState = reduxStore.getState().authentication;

	let reqUrl = `${userManagerAPIBaseUrl}/users/${authState.userId}/messages`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: authState.domainName, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
	* Get details of message
	*
	* @param {object} opts. Request options. Format:
	 {
		 queryParams: {
			 domain: 'xxxx', // optional. Default will use the domain in Redux store (by user logged in). If specified, it overrides the default
			 userId: 'xxxx', // optional. Default will use the user id in Redux store (by user logged in). If specified, it overrides the default
			 msgId: 'xxxx',	 // mandatary
		 },
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const userManagerPreviewMessages = (opts = {}) => {
	const { msgId, queryParams = {} } = opts;
	const authState = reduxStore.getState().authentication;

	let reqUrl = `${userManagerAPIBaseUrl}/messages/${msgId}`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: { domain: authState.domainName, userId: authState.userId, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
	* Updates the message's status
	*
	* @param {object} opts. Request options. Format:
	 {
		 queryParams: {
			 domain: 'xxxx', // optional. Default will use the domain in Redux store (by user logged in). If specified, it overrides the default
			 userId: 'xxxx', // optional. Default will use the user id in Redux store (by user logged in). If specified, it overrides the default
			 msgId: 'xxxx',	 // mandatary
			 isRead: 'true/false',	 // mandatary
		 },
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const userMessageSetReadStatus = (opts = {}) => {
	const { msgId, queryParams = {} } = opts;
	const authState = reduxStore.getState().authentication;

	let reqUrl = `${userManagerAPIBaseUrl}/messages/${msgId}`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'patch',
		headers: reqHeader,
		params: {
			domain: authState.domainName,
			userId: authState.userId,
			...queryParams,
		},
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
	* Delete's the message
	*
	* @param {object} opts. Request options. Format:
	 {
		 queryParams: {
			 domain: 'xxxx', // optional. Default will use the domain in Redux store (by user logged in). If specified, it overrides the default
			 userId: 'xxxx', // optional. Default will use the user id in Redux store (by user logged in). If specified, it overrides the default
			 msgId: 'xxxx',	 // mandatary
		 },
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const userManagerDeleteMessage = (opts = {}) => {
	const { msgId, queryParams = {} } = opts;
	const authState = reduxStore.getState().authentication;

	let reqUrl = `${userManagerAPIBaseUrl}/messages/${msgId}`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'delete',
		headers: reqHeader,
		params: { domain: authState.domainName, userId: authState.userId, ...queryParams },
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
	* Bulk Read/Unread messages
	*
	* @param {object} opts. Request options. Format:
	 {
		 queryParams: {
			 userId: 'xxxx', // optional. Default will use the user id in Redux store (by user logged in). If specified, it overrides the default
		 },
		 bodyParams: {}, // mandatary
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const userMessageBulkReadStatus = (opts = {}) => {
	const { queryParams = {}, bodyParams = {} } = opts;
	const authState = reduxStore.getState().authentication;

	let reqUrl = `${userManagerAPIBaseUrl}/users/${authState.userId}/messages`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'patch',
		headers: reqHeader,
		params: {
			domain: authState.domainName,
			// userId: authState.userId,
			...queryParams,
		},
		data: bodyParams,
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
	* Bulk Delete messages
	*
	* @param {object} opts. Request options. Format:
	 {
		 queryParams: {
			 userId: 'xxxx', // optional. Default will use the user id in Redux store (by user logged in). If specified, it overrides the default
		 },
		 bodyParams: {}, // mandatary
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const userMessageBulkDeleteMessages = (opts = {}) => {
	const { queryParams = {}, bodyParams = {} } = opts;
	const authState = reduxStore.getState().authentication;

	let reqUrl = `${userManagerAPIBaseUrl}/users/${authState.userId}/messages`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'delete',
		headers: reqHeader,
		params: {
			domain: authState.domainName,
			...queryParams,
		},
		data: bodyParams,
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

export const fetchUserDataByIdGroup = (opts = {}) => {
	const { queryParams = {} } = opts;
	const authState = reduxStore.getState().authentication;

	let reqUrl = `${userManagerAPIBaseUrl}/users`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: {
			domain: queryParams.domain ? queryParams.domain : authState.domainName,
			userAdminId: authState.userId,
			...queryParams,
		},
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
	* Bulk Read/Unread messages
	*
	* @param {object} opts. Request options. Format:
	 {
		 token: xxxxxx, // token from external system
		 bodyParams: {}, // mandatary
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const externalSSOLogin = (opts = {}) => {
	const { token, bodyParams = {} } = opts;

	let reqUrl = `${externalSSOBaseUrl}/external/sso`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: `Bearer ${token}`,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'post',
		headers: reqHeader,
		data: bodyParams,
		timeout: 15000,
	};

	return axiosRequest(axiosConfig);
};

/**
	* Fetch users cross domain
	*
	* @param {object} opts. Request options. Format:
	 {
		 queryParams: {
		 	domains: ['xxx', 'yyy'], // mandatary
			...Other_Query_Params_Defined_In_Api
		 },
		 bodyParams: {},
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const fetchUsersCrossDomain = (opts = {}) => {
	const { queryParams = {}, bodyParams = {} } = opts;
	const authState = reduxStore.getState().authentication;

	let reqUrl = `${userManagerAPIBaseUrl}/users/crossdomain`;

	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'post',
		headers: reqHeader,
		params: {
			...queryParams,
		},
		data: bodyParams,
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};

/**
	* Get user details
	*
	* @param {object} opts. Request options. Format:
	 {
		 queryParams: {
			userId: 'xxxx',	 // mandatary
			domain: 'xxxx', // optional. Default will use the domain in Redux store (by user logged in). If specified, it overrides the default
		 },
	 }.
	* @returns {Promise} axios promise response. See detail at https://github.com/axios/axios#response-schema
	*/
export const fetchUserData = (opts = {}) => {
	const { queryParams = {} } = opts;
	let reqUrl = `${userManagerAPIBaseUrl}/users/${queryParams.userId}`;
	const authState = reduxStore.getState().authentication;
	let reqHeader = {
		'Content-Type': 'application/json',
		[authHeaderKey]: authState.token,
	};
	let axiosConfig = {
		url: reqUrl,
		method: 'get',
		headers: reqHeader,
		params: {
			domain: queryParams.domain ? queryParams.domain : authState.domainName,
			...queryParams,
		},
		timeout: 30000,
	};

	return axiosRequest(axiosConfig);
};
