// ##############################
// // // Pages
// #############################
import { Home, HomeRoutes } from './Home';
import {
	ScreenManager,
	Screens,
	Playlists,
	Departments,
	Schedules,
	Screenboard,
	PlayerUniverse,
	Slides,
	ScreenManagerRoutes,
} from './ScreenManager';
import { ArtworkDesign, ArtworkCreate, ArtworkEsign, ArtworkRoutes } from './Artwork';
import { NotFound } from './NotFound';
import { FileUpload, FileUploadRoutes } from './FileUpload';
import { MediafileList, MediafilesRoutes } from './MediaFiles';
import { UserMessagesList, UserMessagesRoutes } from './UserMessages';
import { MyFilesList, MyFilesRoutes } from './MyFiles';
// import { EditDomainSettingsList, EditDomainSettingsRoutes } from './EditDomainSettings';
import { AdminRoutes } from './Admin';
import { SSOLoginRoutes } from './SSOLogin';
import { LoginRoutes, Login } from './Login';

const pageRoutes = [
	...HomeRoutes,
	...ScreenManagerRoutes,
	...ArtworkRoutes,
	...FileUploadRoutes,
	...MediafilesRoutes,
	...UserMessagesRoutes,
	...MyFilesRoutes,
	// ...EditDomainSettingsRoutes,
	...AdminRoutes,
	...SSOLoginRoutes,
	...LoginRoutes,
];

export {
	Home,
	HomeRoutes,
	ScreenManager,
	Screens,
	Playlists,
	Departments,
	Schedules,
	Screenboard,
	PlayerUniverse,
	Slides,
	ScreenManagerRoutes,
	ArtworkDesign,
	ArtworkCreate,
	ArtworkEsign,
	ArtworkRoutes,
	Login,
	NotFound,
	FileUpload,
	FileUploadRoutes,
	MediafileList,
	MediafilesRoutes,
	pageRoutes,
	UserMessagesList,
	UserMessagesRoutes,
	MyFilesList,
	MyFilesRoutes,
	// EditDomainSettingsList,
	// EditDomainSettingsRoutes,
};
