import React from 'react';

// import cx from 'classnames';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Link, IconButton, Box, Tooltip } from '@mui/material';

import {
	Visibility as PreviewIcon,
	More as MoreIcon,
	AddComment as AddCommentIcon,
	Report as InactiveIcon,
	Check as ActiveIcon,
	StayCurrentPortraitOutlined as PortraitIcon,
	StayCurrentLandscapeOutlined as LandscapeIcon,
	Construction as CommandIcon,
	Radio as StartleIcon,
} from '@mui/icons-material';

// custom components
import { Image, NameAliasAvatar } from 'components';

// intl lang
import { useIntl } from 'react-intl';

import { getRouteScreenPlayerUniverse } from '../../Constants.js';
import { moment } from 'utils/libHelper.js';
import { getCloudFrontUrlOfS3File } from 'utils/artwork/artUtilsCommon.js';
import { toLocaleDateTime, toLocaleDate } from 'utils/generalHelper.js';

function ScreenshotComp({ s3Url, timestamp, maxSize = {}, enablePopover = false }) {
	const intl = useIntl();
	return (
		<Box
			sx={{
				flexDirection: 'column',
				...maxSize,
			}}
		>
			<Image
				styles={{ rootStyle: { height: 'auto' } }}
				srcHttpUrl={getCloudFrontUrlOfS3File(s3Url)}
				popoverLargePreview={enablePopover}
			/>
			<Typography
				noWrap
				sx={{ fontSize: 12, fontStyle: 'italic', /* paddingLeft: 0.5, */ lineHeight: 'normal' }}
			>
				{intl.formatMessage(
					{
						id: 'pages.Admin.components.PlayerUniverse.ScreenshotTimestampText',
					},
					{
						timestamp: toLocaleDateTime(new Date(timestamp), { second: '2-digit' }),
					}
				)}
			</Typography>
		</Box>
	);
}

// CSS
const useStyles = makeStyles((theme) => ({
	// success: {
	// 	color: theme.palette.success.main,
	// },

	/**
	 * ----------------------------------------
	 * animation shake (ring)
	 * ----------------------------------------
	 */
	'@keyframes shake': theme.animations['@keyframes ring'],
	shake: {
		animation: '$shake .3s infinite',
	},
}));

// parameters passed must be memorized
function useColumnsConfig({
	setPlayerToSetComment,
	setPlayerToSetCommand,
	isMobileView,
	checkHasRadioStatus,
}) {
	const classes = useStyles();
	const intl = useIntl();
	const columnsConfig = React.useMemo(() => {
		let baseColumns = [
			{
				header: () =>
					intl.formatMessage({
						id: 'pages.ScreenManager.components.Screens.SiteCol',
					}),
				accessorFn: (row) => row.createdBy.company || '',
				enableSorting: false,
				id: `company`,
				cell: function renderCell({ getValue, row }) {
					return (
						<Link
							variant="body1"
							// className={classes.hoverCursor}
							underline="always"
							target="_blank"
							rel="noopener"
							href={getRouteScreenPlayerUniverse(
								row.original.domain,
								row.original.createdBy.email,
								row.original.createdBy.uid
							)}
						>
							<Typography title={row.original.createdBy.email}>{getValue()}</Typography>
						</Link>
					);
				},
			},
			{
				header: () =>
					intl.formatMessage({
						id: 'pages.ScreenManager.components.Screens.NameCol',
					}),
				accessorFn: (row) => row.name || '',
				enableSorting: true,
				id: 'name',
			},
			{
				header: () =>
					intl.formatMessage({
						id: 'pages.ScreenManager.components.PlayerUniverse.DomainCol',
					}),
				accessorFn: (row) => row.domain || '',
				enableSorting: true,
				id: 'domain',
			},
			{
				header: () =>
					intl.formatMessage({
						id: 'pages.Admin.components.PlayerUniverse.ScreenshotCol',
					}),
				accessorFn: (row) => row.screenshot || '',
				enableSorting: false,
				id: 'screenshot',
				cell: function renderCell({ getValue }) {
					const value = getValue();
					if (!value?.s3Url) return null;
					return <ScreenshotComp {...value} enablePopover={true} />;
				},
			},
			{
				header: () =>
					intl.formatMessage({
						id: 'pages.ScreenManager.components.PlayerUniverse.CommentCol',
					}),
				accessorFn: (row) => row.comments || [],
				enableSorting: true,
				id: 'comments',
				cell: function renderCell({ getValue, row }) {
					const comments = getValue();

					const { message: commentValue, timestamp: commentTimeStamp, userAlias } =
						comments?.[0] ?? {};

					return (
						<Box>
							{commentValue && (
								<>
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<Typography variant="body2">
											<NameAliasAvatar
												nameAlias={userAlias || 'XX'}
												sx={{ marginRight: 1, display: 'inline-flex' }}
											/>
											{commentValue}
										</Typography>
									</Box>
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<Typography
											noWrap
											variant="caption"
											sx={{
												fontStyle: 'italic',
											}}
										>
											{toLocaleDate(new Date(commentTimeStamp)) +
												' ' +
												moment(commentTimeStamp).format('HH:mm:ss')}
										</Typography>

										<Tooltip title={intl.formatMessage({ id: 'GENERAL.More' })}>
											<IconButton
												aria-label="comment"
												color={'primary'}
												fontSize="small"
												sx={{ marginLeft: 0.5 }}
												onClick={() => {
													setPlayerToSetComment(row.original);
												}}
											>
												<MoreIcon />
											</IconButton>
										</Tooltip>
									</Box>
								</>
							)}
							{!commentValue && (
								<Tooltip
									title={intl.formatMessage({
										id: 'pages.ScreenManager.components.PlayerUniverse.AddCommentDialogLabel',
									})}
								>
									<IconButton
										aria-label="comment"
										color={'primary'}
										onClick={() => {
											setPlayerToSetComment(row.original);
										}}
									>
										<AddCommentIcon color="primary" />
									</IconButton>
								</Tooltip>
							)}
						</Box>
					);
				},
			},

			{
				accessorFn: (row) => row.sysExecCommands || [],
				id: 'sysExecCommands',
				enableSorting: false,
				header: () =>
					intl.formatMessage({
						id: 'pages.Admin.components.PlayerUniverse.SysCommandsCol',
					}),
				cell: function renderCell({ getValue, row }) {
					const value = getValue();
					return (
						<Tooltip
							title={intl.formatMessage({
								id:
									value.length > 0
										? 'GENERAL.View'
										: 'pages.Admin.components.PlayerUniverse.AddCommandTooltip',
							})}
						>
							<IconButton
								aria-label="command"
								color={value.length > 0 ? 'success' : 'primary'}
								size="small"
								onClick={() => setPlayerToSetCommand(row.original)}
							>
								{value.length > 0 ? <PreviewIcon /> : <CommandIcon />}
							</IconButton>
						</Tooltip>
					);
				},
			},
			{
				accessorFn: (row) => row.orientation || '',
				id: 'orientation',
				enableSorting: false,
				header: () => (
					<span
						title={intl.formatMessage({
							id: 'pages.ScreenManager.components.Screens.OrientationCol',
						})}
					>
						<PortraitIcon />
						<LandscapeIcon />
					</span>
				),
				cell: function renderCel({ getValue }) {
					return getValue() === 'portrait' ? <PortraitIcon /> : <LandscapeIcon />;
				},
			},
			{
				accessorFn: (row) => row.playerCode,
				id: 'playerCode',
				enableSorting: true,
				header: () =>
					intl.formatMessage({
						id: 'pages.ScreenManager.components.PlayerUniverse.PlayCodeCol',
					}),
				cell: ({ getValue }) => getValue() || '',
			},
			{
				accessorFn: (row) => row.status || '',
				id: 'status',
				enableSorting: true,
				header: () =>
					intl.formatMessage({
						id: 'pages.ScreenManager.components.Screens.CurrentStatusCol',
					}),
				cell: function renderCell({ getValue }) {
					return getValue() === 'ACTIVE' ? (
						<ActiveIcon color="success" style={{ fontSize: 30 }} />
					) : (
						<InactiveIcon color="error" className={classes.shake} style={{ fontSize: 30 }} />
					);
				},
			},
			{
				accessorFn: (row) => row.lastConnectedAt || '',
				id: 'lastConnectedAt',
				enableSorting: true,
				header: () =>
					intl.formatMessage({
						id: 'pages.ScreenManager.components.Screens.lastConnectedAtCol',
					}),
				cell: ({ row }) => {
					return row.original.lastConnectedAt
						? moment(row.original.lastConnectedAt).fromNow()
						: 'Never';
				},
			},
			{
				id: 'radioBtn',
				enableSorting: false,
				header: () =>
					intl.formatMessage({
						id: 'pages.Admin.components.PlayerUniverse.HasRadioCol',
					}),
				cell: function renderCell({ row }) {
					return (
						<Tooltip
							title={intl.formatMessage({
								id: 'pages.Admin.components.PlayerUniverse.RadioIcon',
							})}
						>
							<IconButton
								aria-label="command"
								color={'primary'}
								size="small"
								onClick={() => checkHasRadioStatus(row.original)}
							>
								{<StartleIcon />}
							</IconButton>
						</Tooltip>
					);
				},
			},
		];
		// Filter out the radioBtn column if isMobileView is false
		return !isMobileView ? baseColumns.filter((column) => column.id !== 'radioBtn') : baseColumns;
	}, [
		checkHasRadioStatus,
		classes.shake,
		intl,
		isMobileView,
		setPlayerToSetCommand,
		setPlayerToSetComment,
	]);
	return columnsConfig;
}

useColumnsConfig.propTypes = {
	setPlayerToSetCommand: PropTypes.func.isRequired,
	setPlayerToSetComment: PropTypes.func.isRequired,
	checkHasRadioStatus: PropTypes.func.isRequired,
	isMobileView: PropTypes.bool.isRequired,
};

// parameters passed must be memorized
export default useColumnsConfig;
