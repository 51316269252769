import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import makeStyles from '@mui/styles/makeStyles';

import { blueGrey } from '@mui/material/colors';
// MUI components
import { InputLabel, IconButton } from '@mui/material';
// Icons
import { Delete as DeleteIcon } from '@mui/icons-material';

// intl lang
import { FormattedMessage } from 'react-intl';

// const textFieldLineColor = grey[300];
const useStyles = makeStyles((theme) => ({
	textFieldLabelRoot: {
		color: `inherit`,
		alignSelf: 'flex-start',
		fontSize: 'inherit',
		// width: 'fit-content',
		// backgroundColor: 'rgba(255, 255, 255, 1)',
		// fontSize: 12,
	},
	outlinedLabel: {
		backgroundColor: 'rgba(255, 255, 255, 1)',
	},
	creatableSelectionWrapper: {
		// margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
		width: '100%', // `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
		display: 'flex',
		flexDirection: 'column',
		fontSize: 'inherit',
	},
	outlined: {
		border: '1px solid',
		borderRadius: 4,
		borderColor: 'rgba(0, 0, 0, 0.23)',
		'&:hover': {
			borderColor: 'rgba(0, 0, 0, 1)',
		},
	},
	rsOptionWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	rsOptionActions: {
		margin: `0 ${theme.spacing(1)}`,
	},
	rsOptionActionIcon: {
		fontSize: 14,
	},
}));

const reactSelectStyles = {
	container: (provided, state) => ({
		...provided,
		// transform: 'translate(0px, -10px)',
		marginTop: state.selectProps.outlined ? -14 : null,
	}),
	// eslint-disable-next-line no-unused-vars
	control: (provided, state) => {
		return {
			...provided,
			border: 'unset',
			background: 'inherit',
			color: 'inherit',
			boxShadow: 'unset',
			borderRadius: 'unset',
			borderStyle: 'unset',
			borderWidth: 'unset',
			minHeight: 30,
			borderBottom: state.selectProps.outlined ? 'unset' : '1px solid currentColor',
			'&:hover': {
				borderColor: 'currentColor',
			},
		};
	},
	// eslint-disable-next-line no-unused-vars
	placeholder: (provided, state) => ({
		...provided,
		color: 'inherit',
		background: 'inherit',
	}),
	// eslint-disable-next-line no-unused-vars
	indicatorsContainer: (provided, state) => {
		return {
			...provided,
			color: 'inherit',
			// '&:hover,&:active': {
			// 	color: state.isActive? 'inherit':'unset',
			// }
		};
	},
	// eslint-disable-next-line no-unused-vars
	dropdownIndicator: (provided, state) => ({
		...provided,
		color: 'inherit',
		padding: '0px 4px',
		// '&:hover,&:focus': {
		// 	// color: 'inherit',
		// }
	}),
	// eslint-disable-next-line no-unused-vars
	input: (provided, state) => ({
		...provided,
		color: 'inherit',
	}),
	// eslint-disable-next-line no-unused-vars
	menu: (provided, state) => ({
		...provided,
		color: 'inherit',
		backgroundColor: '#fff', //blueGrey[400],
		zIndex: 9999,
	}),
	// eslint-disable-next-line no-unused-vars
	noOptionsMessage: (provided, state) => ({
		...provided,
		color: 'inherit',
	}),
	// eslint-disable-next-line no-unused-vars
	option: (provided, state) => {
		return {
			...provided,
			color: 'inherit',
			backgroundColor: state.isSelected ? blueGrey[100] : 'inherit',
			'&:hover': {
				backgroundColor: blueGrey[200],
			},
			'&:active': {
				backgroundColor: blueGrey[300],
			},
		};
	},
	// eslint-disable-next-line no-unused-vars
	singleValue: (provided, state) => {
		return {
			...provided,
			color: 'inherit',
			padding: 5,
			borderRadius: 5,
			// background: blueGrey[700],
			// color: 'white',
			display: 'flex',
		};
	},
	// eslint-disable-next-line no-unused-vars
	clearIndicator: (provided, state) => ({
		...provided,
		color: 'inherit',
		padding: '0px 4px',
	}),
};

/**
 *
 * fieldProps = field
 * handleGroupChange = handleOnChange
 * handelDelGroup = handleDelOption
 * setFieldProps = handleSelection
 * templateGroups= options
 */
function CreatableSelection({
	// field,
	label,
	value,
	valueLabel,
	// handleOnChange,
	handleDelOption,
	handleCreation,
	handleSelection,
	options,
	isClearable,
	RSStyles,
	required,
	outlined,
	className,
}) {
	const classes = useStyles();
	const handleOnChange = (data, meta) => {
		if (meta.action === 'create-option') {
			handleCreation(data.value);
		} else {
			handleSelection(data ? data.value : null);
		}
	};
	const CustomRSOption = (props) => {
		return (
			<div className={classes.rsOptionWrapper}>
				<components.Option {...props} />
				{typeof handleDelOption === 'function' && (
					<IconButton
						className={classes.rsOptionActions}
						color="inherit"
						onClick={() => {
							// if (field.groupName && props.value === field.groupName) {
							// 	handleSelection({ ...field, groupName: '' });
							// }
							handleDelOption(props.value);
						}}
					>
						<DeleteIcon className={classes.rsOptionActionIcon} />
					</IconButton>
				)}
			</div>
		);
	};
	return (
		<div
			className={cx(classes.creatableSelectionWrapper, className, { [classes.outlined]: outlined })}
		>
			<InputLabel
				shrink
				className={cx({ [classes.outlinedLabel]: outlined })}
				required={required}
				variant={outlined ? 'outlined' : 'standard'}
				classes={{
					root: classes.textFieldLabelRoot,
					// focused: classes.textFieldFocus,
				}}
			>
				{label}
			</InputLabel>
			<CreatableSelect
				// id='groupName-select'
				value={value ? { value: value, label: valueLabel || value } : null}
				components={{ Option: CustomRSOption }}
				placeholder={
					<FormattedMessage id="components.CreatableSelection.placeholderText" />
				} /* "Type to search/create" */
				styles={{ ...reactSelectStyles, ...RSStyles }}
				isClearable={isClearable}
				noOptionsMessage={() => (
					<FormattedMessage id="components.CreatableSelection.noOptionsMsg" />
				)}
				onChange={handleOnChange}
				options={options}
				maxMenuHeight={200}
				outlined={outlined}
			/>
		</div>
	);
}

CreatableSelection.propTypes = {
	label: PropTypes.string.isRequired, // label of the text field
	value: PropTypes.string.isRequired, // value of selection
	valueLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // label of the selection. Allow PropTypes.object is in case it is intl message object
	options: PropTypes.array.isRequired, // array of object. [{ value: 'option', label: 'option' }, { value: 'option', label: 'option' }]
	handleDelOption: PropTypes.func,
	handleCreation: PropTypes.func.isRequired,
	handleSelection: PropTypes.func.isRequired,
	isClearable: PropTypes.bool, // Is the select value clearable
	RSStyles: PropTypes.object, // Customized react-select css styles
	required: PropTypes.bool,
	outlined: PropTypes.bool,
	className: PropTypes.string,
};

CreatableSelection.defaultProps = {
	isClearable: true,
	RSStyles: {},
	handleDelOption: null,
	required: false,
	outlined: false,
	className: '',
	valueLabel: null,
};

export default CreatableSelection;
