import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import matchSorter from 'match-sorter';
import withStyles from '@mui/styles/withStyles';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Link from '@mui/material/Link';
import {
	OutlinedInput,
	InputAdornment,
	Checkbox,
	Tooltip,
	IconButton,
	Button,
} from '@mui/material';

import { _, qs } from 'utils/libHelper';
import { decryptStr } from 'utils/appHelper';
import { injectIntl, FormattedMessage } from 'react-intl';
import { isValid } from 'date-fns';

import {
	Search,
	// RTTrComp,
	RTTrCompDraggable,
	Image,
	RnDDialog,
	MediafileMultiChoosers,
} from 'components';

import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import { DragDropContext, Droppable /*Draggable*/ } from 'react-beautiful-dnd';

import SlideSchedule from './SlideSchedule';
import SlidePreview from './SlidePreview';

// // icons
// import {
// } from '@mui/icons-material';
import { DateRange as DateRangeIcon, Close as CloseIcon } from '@mui/icons-material';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import config from 'config';

// CSS
import SlidesStyle from './SlidesStyle.jsx';
// restful
import { getMediafileDetailsById, downloadFileLogging } from 'restful';
// redux
import { connect } from 'react-redux';
import {
	notifyError,
	notifyGeneral,
	getScreenManagerPlaylistSlide,
	editScreenManagerPlaylistSlide,
	addScreenManagerPlaylistSlide,
	deleteScreenManagerPlaylistSlide,
	resetScreenManagerPlaylistSlideAllStatus,
	reorderScreenManagerPlaylistSlides,
	openGlobalDialog,
	fetchUserData,
	// addScreenManagerSlideFromMedia,
} from 'redux/actions'; // actions

// Constants
import { FILTER_GROUP_MIN_UL } from 'pages/ScreenManager/Constants';
import { ROUTES_PATH_SCREENMANAGER_PLAYLISTS, getRouteMediafileDetail } from 'routes';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const DropTbodyComponentInner = React.forwardRef((props, ref) => {
	const { children = null, style, className, ...rest } = props;

	return (
		<Droppable droppableId="droppable">
			{
				// eslint-disable-next-line no-unused-vars
				(droppableProvided, droppableSnapshot) => (
					<div ref={droppableProvided.innerRef} style={{ overflow: 'auto' }}>
						<div ref={ref} className={cx('rt-tbody', className)} style={style} {...rest}>
							{children}
						</div>
					</div>
				)
			}
		</Droppable>
	);
});
DropTbodyComponentInner.displayName = 'DropTbodyComponentInner';

class DropTbodyComponent extends React.Component {
	render() {
		const { innerRef, ...rest } = this.props;
		return <DropTbodyComponentInner ref={innerRef} {...rest} />;
	}
}

const InputWithSelect = withStyles((theme) => ({
	input: {
		color: 'inherit',
		fontSize: 'inherit',
		backgroundColor: '#ffffff',
		paddingRight: '6px',
		'& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button': {
			WebkitAppearance: 'none',
			MozAppearance: 'none',
			margin: 0,
		},
		'& input[type=number]': {
			'-webkit-appearance': 'textfield',
			'-moz-appearance': 'textfield',
			height: 'inherit', // used to fix conflicts with bootstrap in lasso app
			// padding: '10.5px 6px', // used to fix conflicts with bootstrap in lasso app
		},
	},
	label: {
		fontSize: 'inherit',
	},
	selectMenuPaperOverride: {
		maxHeight: 300,
		// minWidth: 100,
	},
	selectMenuItem: {
		fontSize: 'inherit',
	},
}))(
	({
		classes,
		initValue, // value of the field
		onCommitted, // onCommitted(val) handler (onBlur in input or onChange in Select). NB: param is the e.target.value, not event
		options, // options array of dropdown list
		inputProps, // props passed to native input (used by OutlineInput)
		...rest
	}) => {
		const [inputValue, setInputValue] = React.useState(initValue);
		React.useEffect(() => {
			setInputValue(initValue);
		}, [initValue]);
		return (
			<OutlinedInput
				className={cx(classes.input)}
				margin="dense"
				value={inputValue}
				onChange={(e) => setInputValue(e.target.value || '')}
				onBlur={(e) => {
					if (!e.target.value) {
						setInputValue(initValue);
						onCommitted(initValue);
					} else onCommitted(e.target.value);
				}}
				endAdornment={
					<InputAdornment position="end">
						<Select
							value={inputValue}
							variant="standard"
							onChange={(e) => {
								setInputValue(e.target.value);
								onCommitted(e.target.value);
							}}
							displayEmpty
							disableUnderline
							MenuProps={{
								anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
								transformOrigin: { vertical: 'top', horizontal: 'right' },
								// getContentAnchorEl: null,
								PopoverClasses: { root: classes.selectMenuPaperOverride },
							}}
							renderValue={() => ''}
						>
							{options.map((option, idx) => {
								return (
									<MenuItem
										key={`${option.label}-${idx}`}
										value={option.value}
										className={classes.selectMenuItem}
									>
										{option.label}
									</MenuItem>
								);
							})}
						</Select>
					</InputAdornment>
				}
				inputProps={inputProps}
				{...rest}
			/>
		);
	}
);

class Slides extends React.Component {
	constructor(props) {
		super(props);
		this.rtTBodyRef = React.createRef();
		this.state = {
			filtered: [],
			editing: {}, // the editing data of a screen
			adding: {}, // the adding data of a screen
			bulkScheduleSlides: [], // it contains Ids of selected row (checkbox)
			bulkScheduleDialogOpen: false, // used to open/close bulk schedule dialog
			draggableDialog: false,
			videoOriginalDuration: {},
		};

		this.columns = [
			{
				accessor: (d) => d.id,
				id: 'checkbox',
				Header: '', //'Checkbox',
				minWidth: 150,
				maxWidth: 250,
				resizable: true,
				sortable: false,
				filterable: false,
				Cell: (cellInfo) => {
					return (
						<div className={this.props.classes.checkboxInput}>
							<Checkbox
								className={this.props.classes.alignUserDefinedFormControlCheckbox}
								color="primary"
								size="small"
								disableRipple
								checked={this.state.bulkScheduleSlides.includes(cellInfo.value)}
								disabled={cellInfo.original.thisPropAddedAfterwards_readOnly}
								onChange={(e) => {
									let bulkScheduleSlides = [...this.state.bulkScheduleSlides];
									if (e.target.checked) {
										bulkScheduleSlides.push(cellInfo.value);
									} else {
										bulkScheduleSlides = bulkScheduleSlides.filter((x) => x !== cellInfo.value);
									}
									this.setState({ bulkScheduleSlides });
								}}
							/>
						</div>
					);
				},
			},
			{
				accessor: (d) => d.mediaPreviewUrl,
				id: 'mediaPreviewUrl',
				Header: props.intl.formatMessage({
					id: 'GENERAL.Preview',
				}), //'Preview',
				minWidth: 200,
				maxWidth: 300,
				resizable: true,
				sortable: false,
				filterable: false,
				Cell: (cellInfo) => {
					return <Image srcHttpUrl={cellInfo.original.mediaPreviewUrl} popoverLargePreview />;
				},
			},
			{
				accessor: (d) => d.name || '',
				id: 'name',
				Header: props.intl.formatMessage({ id: 'pages.ScreenManager.components.Slides.TitleCol' }), //'Title',
				resizable: true,
				sortable: false,
				// filterable: true,
				// Filter: this.inputFilter,
				Cell: (cellInfo) => {
					let currentEditing = this.state.editing;
					const editing = currentEditing.original === cellInfo.original;
					return !editing ? (
						cellInfo.value
					) : (
						<Input
							className={this.props.classes.editingInput}
							inputProps={{
								'aria-label': 'slideName',
								style: {
									border: 'unset',
									boxShadow: 'unset',
									height: 34,
									backgroundColor: '#ffffff',
								},
							}}
							onChange={(event) => {
								this.setState({ editing: { ...currentEditing, name: event.target.value } });
							}}
							value={
								typeof currentEditing.name === 'undefined'
									? currentEditing.original.name
									: currentEditing.name
							}
						/>
					);
				},
			},
			{
				accessor: (d) => d.type || 'UNKNOWN',
				id: 'type',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Slides.FileTypeCol',
				}), //'File Type',
				maxWidth: 100,
				resizable: true,
				sortable: false,
				filterable: false,
			},
			{
				accessor: (d) => d.duration || '',
				id: 'duration',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Slides.DurationCol',
				}), //'Duration',
				maxWidth: 100,
				resizable: true,
				sortable: false,
				filterable: false,
				Cell: (cellInfo) => {
					let currentEditing = this.state.editing;
					const editing = currentEditing.original === cellInfo.original;
					return !editing ? (
						cellInfo.value
					) : (
						<InputWithSelect
							initValue={currentEditing.original.duration}
							onCommitted={(val) => this.handleDurationChange(val, currentEditing)}
							inputProps={{ type: 'number' }}
							options={_.range(5, 101, 5).map((val) => ({ label: val, value: val }))}
						/>
					);
				},
			},
			{
				accessor: (d) => d.schedule || {},
				id: 'schedule',
				Header: props.intl.formatMessage({
					id: 'pages.ScreenManager.components.Slides.ScheduleCol',
				}), //'Schedule',
				minWidth: 200,
				// maxWidth: 550,
				resizable: true,
				sortable: false,
				filterable: false,
				Cell: (cellInfo) => {
					let currentEditing = this.state.editing;
					const editing = currentEditing.original === cellInfo.original;
					return (
						<SlideSchedule
							schedule={
								editing
									? currentEditing.schedule || cellInfo.original.schedule
									: cellInfo.original.schedule
							}
							isEditing={editing}
							intl={props.intl}
							onScheduleChange={(schedule) => {
								this.setState({ editing: { ...currentEditing, schedule } });
							}}
						/>
					);
				},
			},
			{
				accessor: (d) => d.mediaId || '',
				id: 'mediaId',
				Header: props.intl.formatMessage({ id: 'pages.ScreenManager.components.Slides.MediaCol' }), //'Media',
				resizable: true,
				sortable: false,
				// filterable: true
				Cell: (cellInfo) => {
					let href = cellInfo.original.thisPropAddedAfterwards_readOnly
						? {}
						: { href: getRouteMediafileDetail(cellInfo.value) };
					return (
						<Link underline="always" {...href}>
							{props.intl.formatMessage({
								id: 'pages.ScreenManager.components.Slides.ViewMediaText',
							})}
						</Link>
					);
				},
			},
			{
				// NOTE - this is a "filter all" DUMMY column
				// you can't HIDE it because then it wont FILTER
				// but it has a size of ZERO with no RESIZE and the
				// FILTER component is NULL (it adds a little to the front)
				// You may possibly move it to the end of columns
				Header: 'All',
				id: 'all',
				width: 0,
				resizable: false,
				sortable: false,
				Filter: () => {},
				// eslint-disable-next-line no-unused-vars
				getProps: (state, rowInfo, column) => {
					return {
						style: { display: 'none' },
					};
				},
				getHeaderProps: () => {
					return {
						style: { display: 'none' },
					};
				},
				filterMethod: (filter, rows) => {
					// using match-sorter
					// it will take the content entered into the "filter"
					// and search for it in keys
					const result = matchSorter(rows, filter.value, {
						keys: ['name', 'type', 'duration'],
						threshold: matchSorter.rankings.CONTAINS,
					});
					// console.log('row[0]:', result[0]);
					return result;
				},
				filterAll: true,
			},
		];
	}

	filterAll = (e) => {
		const { value } = e.target;
		const filtered = [{ id: 'all', value: value }];
		// NOTE: this completely clears any COLUMN filters
		this.setState({ filtered });
	};

	handleDurationChange = async (val, currentEditing) => {
		let durationVal;
		if (this.state.videoOriginalDuration[currentEditing.original.mediaId]) {
			durationVal = this.state.videoOriginalDuration[currentEditing.original.mediaId];
		} else {
			let res = await getMediafileDetailsById({ mediafileId: currentEditing.original.mediaId });
			let vidDurationObj = {
				[res.data.id]: res.data.duration,
			};
			this.setState({
				videoOriginalDuration: { ...this.state.videoOriginalDuration, ...vidDurationObj },
			});
			durationVal = res.data.duration;
		}
		if (currentEditing.original.type === 'video' && Number(val) < durationVal) {
			return this.props.notifyError(
				new Error(
					this.props.intl.formatMessage(
						{
							id: 'pages.ScreenManager.components.Slides.NotifyErrorFailedUpdateDuration',
						},
						{ newDuration: Number(val), duration: durationVal }
					)
				)
			);
		} else {
			this.setState({
				editing: {
					...currentEditing,
					duration: Number(val),
				},
			});
		}
	};

	previewSlide = (slide) => {
		let previewDialog = {
			size: 'md',
			title: slide.name + ' (Preview)',
			content: <SlidePreview {...slide} customStyle={{ height: '480px' }} />,
			dialogClasses: { content: this.props.classes.previewDialogContent },
			disableBackdropClick: false,
			disableEscapeKeyDown: false,
		};
		this.props.openGlobalDialog(previewDialog);
	};

	bulkSchedule = () => {
		this.setState({ bulkScheduleDialogOpen: true });
	};
	duplicateSlide = (slideData) => {
		let intl = this.props.intl;
		let confirmDialog = {
			size: 'sm',
			title: this.props.intl.formatMessage({
				id: 'pages.ScreenManager.components.Slides.DuplicateSlideDialogTitle',
			}),
			content: (
				<Typography
					variant="body1"
					gutterBottom
					style={{ minHeight: 50, marginTop: 25, overflow: 'hidden', textOverflow: 'ellipsis' }}
				>
					{
						<FormattedMessage
							id="pages.ScreenManager.components.Slides.DuplicateSlideDialogMessage"
							values={{
								slideName: (
									<Typography variant="body1" component="span" color="secondary">
										{slideData.name}
									</Typography>
								),
							}}
						/>
					}
				</Typography>
			),
			confirmCB: () => {
				let reqData = {
					name: slideData.name,
					type: slideData.type,
					duration: slideData.duration,
					schedule: slideData.schedule,
					mediaId: slideData.mediaId,
					mediaUrl: slideData.mediaUrl,
					mediaPreviewUrl: slideData.mediaPreviewUrl,
				};
				this.props.addScreenManagerPlaylistSlide(
					{ playlistId: this.props.screenManagerPlaylistSlide.playlist.id, reqData },
					({ error }) => {
						if (!error) {
							this.rtTBodyRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
							// document.getElementsByClassName('rt-tbody')[0].scrollIntoView({behavior: 'smooth', block: 'end'});
							this.props.notifyGeneral(
								intl.formatMessage({
									id: 'pages.ScreenManager.components.Slides.DuplicateSlideDoneMessage',
								}),
								'success'
							);
						}
					}
				);
			},
		};
		this.props.openGlobalDialog(confirmDialog);
	};

	editSlide = (slideData) => {
		this.setState({ editing: { original: slideData } });
	};

	saveEditing = (isReset) => {
		// if isReset is value is true then schedule is set to {}
		let newSlideData = { ...this.state.editing };
		delete newSlideData.original;
		if (Object.keys(newSlideData).length === 0) {
			return this.props.notifyError(
				new Error(
					this.props.intl.formatMessage({
						id: 'pages.ScreenManager.components.Slides.NotifyErrorNoDataChange',
					})
				)
			);
		}
		if (
			newSlideData.bulkSchedule !== undefined &&
			Object.keys(newSlideData.bulkSchedule).length > 0
		) {
			(newSlideData.bulkSchedule.id || []).forEach((item) => {
				return this.props.editScreenManagerPlaylistSlide(
					{
						playlistId: this.props.screenManagerPlaylistSlide.playlist.id,
						slideId: item,
						reqData: {
							schedule: isReset ? {} : newSlideData.schedule,
						},
					},
					({ error }) => {
						if (!error) {
							this.setState({ editing: {} });
							this.setState({ bulkScheduleSlides: [] });
						} else {
							this.props.notifyError(
								new Error(
									this.props.intl.formatMessage({
										id: 'pages.ScreenManager.components.Slides.NotifyErrorNoDataChange',
									})
								)
							);
						}
					}
				);
			});
		} else {
			if (
				Object.keys(newSlideData.schedule || {}).length === 1 &&
				newSlideData.schedule.dayOfWeek &&
				newSlideData.schedule.dayOfWeek.length === 7
			) {
				newSlideData.schedule = {};
			}
			return this.props.editScreenManagerPlaylistSlide(
				{
					playlistId: this.props.screenManagerPlaylistSlide.playlist.id,
					slideId: this.state.editing.original.id,
					reqData: newSlideData,
				},
				({ error }) => {
					if (!error) {
						this.setState({ editing: {} });
					} else {
						this.props.notifyError(
							new Error(
								this.props.intl.formatMessage({
									id: 'pages.ScreenManager.components.Slides.NotifyErrorNoDataChange',
								})
							)
						);
					}
				}
			);
		}
	};

	cancelEditing = () => {
		this.setState({ editing: {} });
	};

	// addSlide = () => {
	// 	let { name, type, duration, isMaster } = { ...this.state.adding };
	// 	if (!name || !departmentId || !layout || (isMaster===undefined || isMaster === null)) {
	// 		return this.props.notifyError(new Error('Missing Data. Please enter the required playlist details'));
	// 	}
	// 	let reqData = {
	// 		name, departmentId, layout, isMaster
	// 	};
	// 	return this.props.addScreenManagerPlaylist({ reqData }, ({error}) => {
	// 		if (!error) {
	// 			this.setState({ adding: {} });
	// 			this.props.resetGlobalDialog();
	// 		}
	// 	});

	// }

	deleteSlide = (slideData) => {
		let confirmDialog = {
			size: 'sm',
			title: this.props.intl.formatMessage({
				id: 'pages.ScreenManager.components.Slides.DelSlideDialogTitle',
			}),
			content: (
				<Typography
					variant="body1"
					gutterBottom
					style={{ minHeight: 50, marginTop: 25, overflow: 'hidden', textOverflow: 'ellipsis' }}
				>
					{
						<FormattedMessage
							id="pages.ScreenManager.components.Slides.DelSlideDialogMessage"
							values={{
								slideName: (
									<Typography variant="h6" component="span" color="secondary">
										{slideData.name}
									</Typography>
								),
							}}
						/>
					}
				</Typography>
			),
			confirmCB: () => {
				this.props.deleteScreenManagerPlaylistSlide({
					playlistId: this.props.screenManagerPlaylistSlide.playlist.id,
					slideId: slideData.id,
				});
			},
		};
		this.props.openGlobalDialog(confirmDialog);
	};

	resetSlideSchedule = () => {
		let confirmDialog = {
			size: 'sm',
			title: this.props.intl.formatMessage({
				id: 'pages.ScreenManager.components.Slides.ResetBulkScheduleTitle',
			}),
			content: (
				<Typography
					variant="body1"
					gutterBottom
					style={{ minHeight: 50, marginTop: 25, overflow: 'hidden', textOverflow: 'ellipsis' }}
				>
					{<FormattedMessage id="pages.ScreenManager.components.Slides.ResetBulkSchedule" />}
				</Typography>
			),
			confirmCB: () => {
				this.saveEditing(true);
				this.setState({ bulkScheduleDialogOpen: false });
			},
		};
		this.props.openGlobalDialog(confirmDialog);
	};

	getFileType = (type) => {
		let newType = '';
		if (['video', 'mpeg-4', 'mpg', 'mp4'].indexOf(type.toLowerCase()) > -1) {
			newType = 'video';
			return newType;
		} else if (type.toLowerCase() === 'svg') {
			newType = 'svg';
			return newType;
		} else if (type.toLowerCase() === 'html') {
			newType = 'html';
			return newType;
		} else if (type.toLowerCase() === 'weburl') {
			newType = 'weburl';
			return newType;
		} else {
			newType = 'image';
			return newType;
		}
	};

	handleDragEnd = (result) => {
		if (!result.destination || result.source.index === result.destination.index) {
			return;
		}
		let playlist = this.props.screenManagerPlaylistSlide.playlist;
		let slides = playlist.slides.slice(0);
		let [removed] = slides.splice(result.source.index, 1); // take out the slide you dragged
		slides.splice(result.destination.index, 0, removed); // place the dragged slide the its new position
		this.props.reorderScreenManagerPlaylistSlides({
			playlistId: playlist.id,
			reqData: { slides: slides.map((slide) => slide.id) },
		});
	};

	componentDidMount() {
		let parsedQueryParams = qs.parse(this.props.location.search);
		if (parsedQueryParams.playlistId) {
			this.props.getScreenManagerPlaylistSlide({
				playlistId: decryptStr(parsedQueryParams.playlistId),
			});
		} else {
			// invalid url, redirect to playlist page
			this.props.notifyError(new Error('Invalid Url'));
			this.props.history.replace(ROUTES_PATH_SCREENMANAGER_PLAYLISTS);
		}
		this.props.fetchUserData();
		return this.props.history.listen((location) => {
			if (this.props.history.action === 'POP') {
				location.state = { ...this.props.location.state };
			}
		});
	}
	componentWillUnmount() {
		this.props.resetScreenManagerPlaylistSlideAllStatus();
	}

	getTrComp = (props) => <RTTrCompDraggable {...props} />;

	render() {
		const {
			classes,
			screenManagerPlaylistSlide,
			intl,
			userData,
			// playlistId,
			// screenManagerDepartments
		} = this.props;
		const { isGettingPlaylist, playlist } = screenManagerPlaylistSlide;
		let currentPlaylist = playlist || {};
		let slidesAll = (currentPlaylist.slides || []).concat(
			(currentPlaylist.masterSlides || []).map((slide) => {
				slide.thisPropAddedAfterwards_readOnly = true;
				slide.thisPropAddedAfterwards_draggable = false;
				return slide;
			})
		);
		let userDataFilters = {};
		if (
			userData.accessibleFileCategories?.length > 0 &&
			this.props.authentication.userLevel < FILTER_GROUP_MIN_UL
		) {
			userDataFilters.accessibleFileCategories = userData.accessibleFileCategories.map(
				(cate) => cate.id
			);
		}
		if (
			userData.userGroups?.length > 0 &&
			this.props.authentication.userLevel < FILTER_GROUP_MIN_UL
		) {
			userDataFilters.accessibleUserGroups = userData.userGroups.map((group) => group.id);
		}
		return (
			<div className={classes.contentWrapper}>
				<Paper className={classes.header}>
					<Typography variant="h6" gutterBottom className={classes.title}>
						{intl.formatMessage(
							{ id: 'pages.ScreenManager.components.Slides.PageTitle' },
							{ PLName: currentPlaylist.name ? `"${currentPlaylist.name}"` : '' }
						)}
						{
							<Tooltip
								title={intl.formatMessage({
									id: 'pages.ScreenManager.components.Slides.BulkScheduleTitle',
								})}
							>
								<span>
									<IconButton
										className={classes.scheduleButton}
										color="primary"
										onClick={() => {
											this.bulkSchedule();
										}}
										disabled={this.state.bulkScheduleSlides.length < 2}
										aria-label="schedule"
									>
										<DateRangeIcon />
									</IconButton>
								</span>
							</Tooltip>
						}
						{
							<Tooltip
								title={intl.formatMessage({
									id: 'pages.ScreenManager.components.Slides.AddSlides',
								})}
							>
								<IconButton
									className={classes.scheduleButton}
									color="primary"
									onClick={() => {
										this.setState({ draggableDialog: true });
									}}
									aria-label="Add"
								>
									<AddToQueueIcon />
								</IconButton>
							</Tooltip>
						}
					</Typography>
					<Search
						placeholder={intl.formatMessage({ id: 'GENERAL.Search' })}
						className={classes.search}
						onChange={this.filterAll.bind(this)}
						onClearClick={() => {
							if (this.state.filtered.length === 0) return;
							this.setState({ filtered: [] });
						}}
						// OnEnterKeyPressed={this.filterAll.bind(this)}
					/>
				</Paper>
				<DragDropContext onDragEnd={this.handleDragEnd}>
					<ReactTable
						// ref="slidesTable"
						// International Lang
						previousText={intl.formatMessage({ id: 'ReactTable.PreviousText' })}
						nextText={intl.formatMessage({ id: 'ReactTable.NextText' })}
						loadingText={intl.formatMessage({ id: 'ReactTable.LoadingText' })}
						noDataText={intl.formatMessage({ id: 'ReactTable.NoDataText' })}
						pageText={intl.formatMessage({ id: 'ReactTable.PageText' })}
						ofText={intl.formatMessage({ id: 'ReactTable.OfText' })}
						rowsText={intl.formatMessage({ id: 'ReactTable.RowsText' })}
						defaultFiltered={[]}
						filtered={this.state.filtered}
						// onFilteredChange={filtered => {
						// 	this.setState({ filtered });
						// }}
						TrComponent={this.getTrComp}
						// TdComponent={TdComponent}
						TbodyComponent={DropTbodyComponent}
						// manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={slidesAll}
						// pages={pages} // Display the total number of pages
						defaultPageSize={10}
						pageSize={slidesAll ? slidesAll.length + 1 : undefined}
						showPagination={false}
						loading={isGettingPlaylist} // Display the loading overlay when we need it
						// onFetchData={this.fetchBrandsData.bind(this)} // Request new data when things change
						// data={this.props.getProductsDataReducerState.productsData}
						multiSort={false}
						columns={this.columns}
						style={{
							height: '600px', // This will force the table body to overflow and scroll, since there is not enough room
						}}
						className="-striped -highlight"
						// eslint-disable-next-line no-unused-vars
						defaultFilterMethod={(filter, row, column) => {
							const id = filter.pivotId || filter.id;
							return row[id] !== undefined
								? String(row[id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
								: true;
						}}
						// TfootComponent={
						// 	({ children, className, ...rest }) =>
						// 		<div className={className} {...rest} style={{ width: '100%' }}>
						// 			<Tooltip title="Add New Playlist">
						// 				<Fab color="secondary" aria-label="Add" className={classes.addFab} onClick={this.openAddingPlaylistDialog}>
						// 					<AddIcon />
						// 				</Fab>
						// 			</Tooltip>
						// 		</div>
						// }
						// eslint-disable-next-line no-unused-vars
						getTbodyProps={(state, rowInfo, column, instance) => {
							return {
								innerRef: this.rtTBodyRef,
							};
						}}
						// eslint-disable-next-line no-unused-vars
						getTheadProps={(state, rowInfo, column, instance) => {
							return {
								style: { maxHeight: 50 },
							};
						}}
						// eslint-disable-next-line no-unused-vars
						getTheadTrProps={(state, rowInfo, column, instance) => {
							return {
								className: classes.tableHeadCell + ' ' + state.className,
								style: { alignItems: 'center' },
							};
						}}
						// eslint-disable-next-line no-unused-vars
						getTableProps={(state, rowInfo, column, instance) => {
							return {
								className: classes.table + ' ' + state.className,
							};
						}}
						// eslint-disable-next-line no-unused-vars
						getTrProps={(state, rowInfo, column, instance) => {
							if (!rowInfo) return {};
							let isOnEditing = rowInfo.original === this.state.editing.original;
							let isDraggable = true;

							if (rowInfo.original.thisPropAddedAfterwards_readOnly) {
								isDraggable = false;
							}
							return {
								style: {
									alignItems: 'center',
								},
								previewAction: isOnEditing
									? undefined
									: this.previewSlide.bind(this, rowInfo.original),
								duplicateAction: isOnEditing
									? undefined
									: this.duplicateSlide.bind(this, rowInfo.original),
								editAction: isOnEditing ? undefined : this.editSlide.bind(this, rowInfo.original),
								deleteAction: isOnEditing
									? undefined
									: this.deleteSlide.bind(this, rowInfo.original),
								saveAction: isOnEditing ? this.saveEditing : undefined,
								cancelAction: isOnEditing ? this.cancelEditing : undefined,
								rowInfo: rowInfo,
								uniqueKey: 'id',
								isDraggable: isDraggable,
							};
						}}
						// eslint-disable-next-line no-unused-vars
						getTdProps={(state, rowInfo, column, instance) => {
							// get TD (column) props
							// let editAction, editingUI;
							// if (rowInfo) {
							// 	switch (column.id) {
							// 		case 'name':
							// 		case 'restricted_to':
							// 		case 'metadata':
							// 			editingUI = <EditBrandMeta brand_id={state.data[rowInfo.index]['brand_id']} handleEditingDialogClose={this.handleEditingDialogClose} />
							// 			break;
							// 		case 'brand_logo':
							// 			editingUI = <EditingLogo brand_id={state.data[rowInfo.index]['brand_id']} handleEditingDialogClose={this.handleEditingDialogClose} />
							// 			break;
							// 		default:
							// 			break;
							// 	}
							// 	if (editingUI) editAction = () => this.setState({ editingUI: editingUI });
							// }
							return {
								// style: { whiteSpace: 'unset' },
								// className: classes.tableCell + ' ' + state.className,
								// editAction: editAction,
							};
						}}
					/>
				</DragDropContext>
				{
					// <Dialog open={this.state.addingScreenOpen} size='md' title='Enter the details for the new Screen:' content={this.state.addingScreenUI} onClose={this.onCloseAddingScreenDialog} />
				}
				{
					<Dialog
						maxWidth="xs"
						fullWidth
						onClose={() => {
							this.setState({ bulkScheduleDialogOpen: false });
						}}
						aria-labelledby="Bulk Scheduling"
						open={this.state.bulkScheduleDialogOpen}
						BackdropProps={{ invisible: false }}
					>
						<DialogTitle id="Bulk Scheduling" className={this.props.classes.dialogTitle}>
							{this.props.intl.formatMessage({
								id: 'pages.ScreenManager.components.Slides.BulkScheduleTitle',
							})}
							<IconButton
								aria-label="Close"
								className={this.props.classes.closeButton}
								onClick={() => {
									this.setState({ bulkScheduleDialogOpen: false });
								}}
							>
								<CloseIcon />
							</IconButton>
						</DialogTitle>

						<DialogContent dividers>
							<div className={this.props.classes.bulkScheduleDialog}>
								<SlideSchedule
									schedule={
										this.state.editing.schedule === undefined ? {} : this.state.editing.schedule
									}
									isEditing={true}
									intl={this.props.intl}
									onScheduleChange={(schedule) => {
										this.setState(
											{
												editing: {
													...this.state.editing,
													bulkSchedule: { id: this.state.bulkScheduleSlides },
													schedule,
												},
											},
											this.bulkSchedule
										);
									}}
								/>
							</div>
						</DialogContent>
						<DialogActions>
							<Button
								size="small"
								variant="contained"
								onClick={() => {
									this.setState({ bulkScheduleDialogOpen: false });
								}}
							>
								{this.props.intl.formatMessage({ id: 'GENERAL.Cancel' })}
							</Button>
							<Button
								variant="contained"
								size="small"
								color="secondary"
								// startIcon={<ResetIcon />}
								onClick={() => {
									this.setState(
										{
											editing: {
												...this.state.editing,
												bulkSchedule: { id: this.state.bulkScheduleSlides },
											},
										},
										this.bulkSchedule
									);
									this.resetSlideSchedule();
								}}
							>
								{this.props.intl.formatMessage({ id: 'GENERAL.Reset' })}
							</Button>
							<Button
								size="small"
								variant="contained"
								color="primary"
								onClick={() => {
									this.saveEditing();
									this.setState({ bulkScheduleDialogOpen: false });
								}}
							>
								{this.props.intl.formatMessage({ id: 'GENERAL.Confirm' })}
							</Button>
						</DialogActions>
					</Dialog>
				}
				{this.state.draggableDialog && (
					<RnDDialog
						open={true}
						size="md"
						title={intl.formatMessage({
							id: 'pages.ScreenManager.components.Slides.SelectMediaFile',
						})}
						resizable={true}
						onClose={() => {
							// setFieldToChooseMediafile(null);
							this.setState({ draggableDialog: false });
						}}
					>
						<MediafileMultiChoosers
							enabledProviders={['TOOLKIT_FILELIBRARY', 'TOOLKIT_MYFILES']}
							filtersToolkitFileLibrary={{
								isScreenEnabled: true,
								isArtworkTemplate: false,
								orientation: [this.props.screenManagerPlaylistSlide.playlist.layout],
								fileStatuses: ['live'],
								...userDataFilters,
							}}
							filtersToolkitMyFiles={{
								isScreenEnabled: true,
								isArtworkTemplate: false,
								orientation: [this.props.screenManagerPlaylistSlide.playlist.layout],
								createdByUIDs: [this.props.authentication.userId],
							}}
							onMediafileSelect={(selectedMediafile) => {
								if (!config.isReactOnly) {
									let addScreenPlaylistLogObj = {
										logType: 'mediafiles',
										mediafileIds: [selectedMediafile.id],
										action: 'screen',
									};
									downloadFileLogging({ bodyParams: addScreenPlaylistLogObj });
								}
								let fileType = this.getFileType(selectedMediafile.fileType);
								let reqData = {
									playlists: [this.props.screenManagerPlaylistSlide.playlist.id],
									name: selectedMediafile.title,
									duration: fileType === 'video' ? selectedMediafile.duration : 10,
									schedule: {
										...(selectedMediafile.postingDate &&
											isValid(new Date(selectedMediafile.postingDate)) && {
												availableStart: selectedMediafile.postingDate,
											}),
										...(selectedMediafile.expiryDate &&
											isValid(new Date(selectedMediafile.expiryDate)) && {
												availableEnd: selectedMediafile.expiryDate,
											}),
									},
									type: fileType,
									mediaId: selectedMediafile.id,
									mediaUrl:
										fileType === 'svg' ? selectedMediafile.svgUrl : selectedMediafile.optimisedUrl,
									mediaPreviewUrl: selectedMediafile.previewUrl,
								};
								this.props.addScreenManagerPlaylistSlide(
									{ playlistId: this.props.screenManagerPlaylistSlide.playlist.id, reqData },
									({ error }) => {
										if (!error) {
											this.rtTBodyRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
											// document.getElementsByClassName('rt-tbody')[0].scrollIntoView({behavior: 'smooth', block: 'end'});
											this.props.notifyGeneral(
												intl.formatMessage({
													id: 'pages.ScreenManager.components.Slides.InsertSlideDoneMessage',
												}),
												'success'
											);
										} else {
											this.props.notifyError(
												new Error(
													this.props.intl.formatMessage({
														id: 'pages.ScreenManager.components.Slides.NotifyErrorFailedToAddSlide',
													})
												)
											);
										}
									}
								);
								this.setState({ draggableDialog: false });
							}}
						/>
					</RnDDialog>
				)}
			</div>
		);
	}
}

Slides.propTypes = {
	classes: PropTypes.object.isRequired,
	notifyError: PropTypes.func.isRequired,
	notifyGeneral: PropTypes.func.isRequired,
	getScreenManagerPlaylistSlide: PropTypes.func.isRequired,
	editScreenManagerPlaylistSlide: PropTypes.func.isRequired,
	addScreenManagerPlaylistSlide: PropTypes.func.isRequired,
	// addScreenManagerSlideFromMedia: PropTypes.func.isRequired,
	deleteScreenManagerPlaylistSlide: PropTypes.func.isRequired,
	reorderScreenManagerPlaylistSlides: PropTypes.func.isRequired,
	resetScreenManagerPlaylistSlideAllStatus: PropTypes.func.isRequired,
	openGlobalDialog: PropTypes.func.isRequired,
	screenManagerPlaylistSlide: PropTypes.object.isRequired,
	authentication: PropTypes.object.isRequired,
	fetchUserData: PropTypes.func.isRequired,
	userData: PropTypes.object.isRequired,
};

Slides.defaultProps = {};

const mapStateToProps = (state) => {
	return {
		screenManagerPlaylistSlide: state.screenManagerPlaylistSlide,
		authentication: state.authentication,
		userData: state.usermanager.userData,
	};
};

export default connect(mapStateToProps, {
	notifyError,
	notifyGeneral,
	getScreenManagerPlaylistSlide,
	editScreenManagerPlaylistSlide,
	addScreenManagerPlaylistSlide,
	deleteScreenManagerPlaylistSlide,
	reorderScreenManagerPlaylistSlides,
	resetScreenManagerPlaylistSlideAllStatus,
	openGlobalDialog,
	fetchUserData,
	// addScreenManagerSlideFromMedia,
})(injectIntl(withStyles(SlidesStyle)(Slides)));
