/* eslint-disable react/no-string-refs */
import React from 'react';
import PropTypes from 'prop-types';
// javascript plugin used to create scrollbars on windows
import { NavLink, Link } from 'react-router-dom';
import cx from 'classnames';

import withStyles from '@mui/styles/withStyles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import { Badge } from '@mui/material';
// import { AddBox, ExitToApp, SettingsApplications, Autorenew } from '@mui/icons-material';
import { connect } from 'react-redux';

// core components
// import HeaderLinks from 'components/Header/HeaderLinks';

// import avatar from 'assets/img/scatter.png';

import SidebarWrapper from './SidebarWrapper';
import SidebarStyle from './SidebarStyle';
import { encryptStr } from 'utils/appHelper';

// import { injectIntl } from 'react-intl';
// import messages from './messages';

class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		let activeCollapsePath = '';
		for (let i = 1; i < props.routes.length; i++) {
			let route = props.routes[i];
			if (!route.children || !Array.isArray(route.children || route.children.length === 0))
				continue;
			if (
				props.location.pathname === route.path ||
				(route.children || []).map((item) => item.path).includes(props.location.pathname)
			) {
				activeCollapsePath = route.path;
				break;
			}
		}
		this.state = {
			activeCollapsePath: activeCollapsePath, // possible value: '', location.pathname
			miniActive: true,
		};
		this.activeRoute.bind(this);
	}
	// verifies if routeName is the one active (in browser input)
	activeRoute(routePath) {
		return this.props.location.pathname === routePath;
	}
	openCollapse(activeCollapsePath) {
		// const st = {};
		// st[collapse] = !this.state[collapse];
		if (this.state.activeCollapsePath === activeCollapsePath) {
			activeCollapsePath = '';
		}
		this.setState({ activeCollapsePath });
	}

	isCollapseIn(route) {
		return this.state['activeCollapsePath'] === route.path;
		// if(!this.state['activeCollapsePath'] && (this.props.location.pathname === route.path || (route.children||[]).map(item=>item.path).includes(this.props.location.pathname))){
		// 	return true;
		// }
		// if(this.state['activeCollapsePath'] && this.state['activeCollapsePath'] !== route.path){
		// 	return false;
		// }
		// // if(this.state['activeCollapsePath'] === route.path){
		// // 	return true;
		// // }
		// if(this.state['activeCollapsePath'] && (this.state['activeCollapsePath'] === route.path || (route.children||[]).map(item=>item.path).includes(this.props.location.pathname))){
		// 	return true;
		// }
		// return false;
	}

	render() {
		const {
			classes,
			routes,
			image,
			color,
			bgColor,
			domainConfig,
			showRoute,
			hidden /* , logo,  logoText, routes, bgColor, rtlActive*/,
			mediaFileCount,
			userId,
		} = this.props;
		const links = (
			<List className={classes.list}>
				{routes.map((route) => {
					// if (route.hide) {
					//   return null;
					// }
					if (!showRoute(route)) {
						return null;
					}
					if (route.children && Array.isArray(route.children) && route.children.length > 0) {
						// Route has children. Use Collapse
						const listItemClasses = cx(classes.item, {
							[classes.collapseActive]: this.isCollapseIn(route),
						});
						const navLinkClasses = cx(classes.itemLink);
						const listItemTextClass = cx(classes.itemText, {
							[classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
							// [classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
							// [classes.itemTextRTL]: rtlActive,
						});
						const collapseItemTextClass = cx(classes.collapseItemText, {
							[classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
							// [classes.collapseItemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
							// [classes.collapseItemTextRTL]: rtlActive,
						});
						const itemIcon = cx(classes.itemIcon);
						const caretLink = cx(classes.caret);

						const navLinkClassesForMyFiles = cx(classes.itemLink, {
							[classes[color]]: this.activeRoute(route.path),
						});
						const itemTextLinkForMyFiles = cx(classes.itemText, {
							[classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
						});
						return (
							<ListItemButton key={`list-item-${route.path}`} className={listItemClasses}>
								<NavLink
									to={route.component ? route.path : '#'}
									className={route.showCounter ? navLinkClassesForMyFiles : navLinkClasses}
									onClick={() => {
										if (route.showCounter) {
											this.setState({ activeCollapsePath: '' });
											this.props.handleDrawerToggle();
										} else {
											this.openCollapse(route.path);
										}
									}}
								>
									<ListItemIcon className={itemIcon}>
										<route.sidebarIcon />
									</ListItemIcon>
									<ListItemText
										primary={route.sidebarName}
										secondary={
											!route.showCounter && (
												<b
													className={`${caretLink} ${
														this.isCollapseIn(route) ? classes.caretActive : ''
													}`}
												/>
											)
										}
										disableTypography
										className={route.showCounter ? itemTextLinkForMyFiles : listItemTextClass}
									/>
								</NavLink>
								{/* Counter is only visible for My Files menu */}
								{route.showCounter && (
									<NavLink
										className={classes.badgeMyFiles}
										to={
											route.children
												? route.children[0].path +
												  `?counterDate=${encryptStr(
														new Date().toJSON().slice(0, 10)
												  )}&uid=${encryptStr(userId)}`
												: '#'
										}
										// onClick={() => {
										// 	this.openCollapse(route.path);
										// 	this.setState({ activeCollapsePath: '' });
										// }}
									>
										<Badge badgeContent={mediaFileCount} color="primary"></Badge>
									</NavLink>
								)}

								<Collapse in={this.isCollapseIn(route)} unmountOnExit>
									<List
										className={cx(classes.list, classes.collapseList, {
											[classes.collapsesListNormal]: !(
												this.props.miniActive && this.state.miniActive
											),
										})}
									>
										{route.children.map((childRoute) => {
											if (!showRoute(childRoute)) {
												return null;
											}
											const navLinkCollapseClasses = cx(classes.collapseItemLink, {
												[classes[color]]:
													this.activeRoute(childRoute.path) ||
													(childRoute.children || [])
														.map((item) => item.path)
														.includes(this.props.location.pathname),
											});
											const collapseItemMini = cx(classes.collapseItemMini);
											return (
												<ListItemButton
													key={`list-item-collapse-${childRoute.path}`}
													className={classes.collapseItem}
												>
													<NavLink
														to={childRoute.path}
														className={navLinkCollapseClasses}
														onClick={this.props.handleDrawerToggle}
													>
														<ListItemIcon className={collapseItemMini}>
															<childRoute.sidebarIcon />
														</ListItemIcon>

														{
															// <span className={collapseItemMini}>{childRoute.sidebarIcon}</span>
														}
														<ListItemText
															primary={childRoute.sidebarName}
															disableTypography
															className={collapseItemTextClass}
														/>
													</NavLink>
												</ListItemButton>
											);
										})}
									</List>
								</Collapse>
							</ListItemButton>
						);
					}
					const navLinkClasses = cx(classes.itemLink, {
						[classes[color]]: this.activeRoute(route.path),
					});
					const itemTextLink = cx(classes.itemText, {
						[classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
					});
					const itemIcon = cx(classes.itemIcon);
					return (
						<ListItemButton key={`list-${route.path}`} className={classes.item}>
							<NavLink
								to={route.path}
								className={navLinkClasses}
								onClick={() => {
									this.setState({ activeCollapsePath: '' });
									this.props.handleDrawerToggle();
								}}
							>
								<ListItemIcon className={itemIcon}>
									<route.sidebarIcon />
								</ListItemIcon>
								<ListItemText
									primary={route.sidebarName}
									disableTypography
									className={itemTextLink}
								/>
							</NavLink>
						</ListItemButton>
					);
				})}
			</List>
		);

		// const logoNormal = cx(classes.logoNormal, {
		//   [classes.logoNormalSidebarMini]: this.props.miniActive && this.state.miniActive,
		// });
		// const logoMini = cx(classes.logoMini);
		const logoClasses = cx(classes.logo, {
			[classes.whiteAfter]: bgColor === 'white',
		});

		var domain = (
			<div className={logoClasses}>
				<Link to="/" className={classes.logoLink}>
					<img src={domainConfig.logo} alt="logo" className={classes.logoImg} />
				</Link>
				{
					// 	<Link to='/' className={logoNormal}>
					// 		{`${domainConfig.name} Toolkit`}
					// </Link>
				}
			</div>
		);

		const drawerPaper = cx(classes.drawerPaper, {
			[classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
			[classes.drawerPaperHidden]: hidden,
		});
		const sidebarWrapper = cx(classes.sidebarWrapper, {
			[classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
			[classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
			[classes.drawerPaperHidden]: hidden,
		});
		return (
			<div ref="sidebarPanel">
				{/* Mobile View */}
				<Drawer
					variant="temporary"
					anchor="left"
					sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}
					open={this.props.open}
					classes={{
						paper: `${drawerPaper} ${classes[`${bgColor}Background`]}`,
					}}
					onClose={this.props.handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					{domain}
					<SidebarWrapper className={sidebarWrapper} links={links} /*headerLinks={vote}*/ />
					{image !== undefined ? (
						<div className={classes.background} style={{ backgroundImage: `url(${image})` }} />
					) : (
						<div className={classes.background} />
					)}
				</Drawer>

				{/* Desktop View */}
				<Drawer
					onMouseOver={() => this.setState({ miniActive: false })}
					onMouseOut={() => this.setState({ miniActive: true })}
					sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
					anchor="left"
					variant="permanent"
					open
					classes={{
						paper: `${drawerPaper} ${classes[`${bgColor}Background`]}`,
					}}
				>
					{domain}
					<SidebarWrapper className={sidebarWrapper} links={links} />
					{image !== undefined ? (
						<div className={classes.background} style={{ backgroundImage: `url(${image})` }} />
					) : (
						<div className={classes.background} />
					)}
				</Drawer>
			</div>
		);
	}
}

Sidebar.defaultProps = {
	bgColor: 'domain',
	color: 'domain',
	hidden: false,
	mediaFileCount: 0,
};

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	bgColor: PropTypes.oneOf(['domain', 'white', 'black', 'blue']),
	// rtlActive: PropTypes.bool,
	color: PropTypes.oneOf(['domain', 'white', 'red', 'orange', 'green', 'blue', 'purple', 'rose']),
	// logo: PropTypes.string,
	// logoText: PropTypes.string,
	image: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object).isRequired,
	domainConfig: PropTypes.object.isRequired,
	showRoute: PropTypes.func.isRequired,
	hidden: PropTypes.bool,
	miniActive: PropTypes.bool.isRequired,
	handleDrawerToggle: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	mediaFileCount: PropTypes.number,
	userId: PropTypes.string.isRequired,
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => {
	return {
		// authentication: state.authentication,
		userId: state.authentication.userId,
	};
};

export default connect(mapStateToProps, {})(withStyles(SidebarStyle, { withTheme: true })(Sidebar));
