import React from 'react';

// import cx from 'classnames';

import PropTypes from 'prop-types';
import { FILTERS } from './filterSettings';
import {
	MenuItem,
	Paper,
	Select,
	Typography,
	TextField,
	Button,
	useMediaQuery,
	Box,
	FormControl,
	InputLabel,
	Chip,
	IconButton,
	Tooltip,
	Fab,
	Link,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';

import { useIntl } from 'react-intl';
import { Search, MuiDialog, MUITablePagination, Maps } from 'components';
import PlayerCommandComp, { AddCommandRowComp } from './PlayerCommandComp';
import PlayerCommentComp from './PlayerCommentComp';
import PlayerTableView from './PlayerTableView';
import PlayerGridView from './PlayerGridView';
import Filters from './Filters';

// icons
import {
	Save as SaveIcon,
	MoreHoriz as MoreInfoIcon,
	SettingsSuggest as SysVersionIcon,
	AddComment as AddCommentIcon,
	Construction as SetCommandIcon,
} from '@mui/icons-material';
import PlaceIcon from '@mui/icons-material/Place';
import { amber } from '@mui/material/colors';

import useColumnsConfig from './useColumnsConfig';

// redux
import { connect } from 'react-redux';
import { openGlobalDialog, resetGlobalDialog, notifyGeneral } from 'redux/actions'; // actions

import {
	setCommandsToPlayer,
	searchReportPlayers,
	patchReportPlayersById,
	patchBatchReportPlayers,
	fetchBoxServiceReleases,
	patchSetBoxAppVersion,
	fetchUserDataByIdGroup,
	fetchMapsData,
	fetchUsersCrossDomain,
	fetchUserData,
} from 'restful';
import { toLocaleDateTime } from 'utils/generalHelper';
import config from 'config';
const googleAPIKey = config.googleAPI.apiKey;

const BATCH_ACTIONS = {
	SET_COMMAND: 'SET_COMMAND',
	ADD_COMMENT: 'ADD_COMMENT',
};

function BatchAddCommentComp({ handleBatchAddComment }) {
	const intl = useIntl();
	const [comment, setComment] = React.useState('');
	return (
		<Box sx={{ minWidth: 250 }}>
			<TextField
				required
				label={intl.formatMessage({
					id: 'pages.ScreenManager.components.PlayerUniverse.AddCommentDialogLabel',
				})}
				value={comment}
				variant="outlined"
				multiline
				rows={4}
				fullWidth
				size="small"
				inputProps={{ style: { resize: 'vertical' } }}
				onChange={(e) => setComment(e.target.value)}
			/>
			<Box sx={{ display: 'flex', justifyContent: 'center', margin: 1 }}>
				<Button
					variant="contained"
					color="primary"
					startIcon={<SaveIcon />}
					onClick={() => {
						handleBatchAddComment(comment);
					}}
				>
					{intl.formatMessage({ id: 'GENERAL.Save' })}
				</Button>
			</Box>
		</Box>
	);
}

function MoreForPlayerComp({ player }) {
	const moreFields = [
		{
			title: 'System Status',
			value: player.sysStatus,
			shouldDisplay: true,
		},
		{
			title: 'System Last Connected',
			value: player.sysLastConnectedAt
				? toLocaleDateTime(new Date(player.sysLastConnectedAt))
				: 'N/A',
			shouldDisplay: true,
		},
		{
			title: 'Pending Commands',
			value:
				player.sysExecCommands.length > 0 ? JSON.stringify(player.sysExecCommands, null, 2) : 'N/A',
			shouldDisplay: true,
		},
		{
			title: 'System Report',
			value:
				Object.keys((player.isLinuxBox ? player.boxServicePingReport : player.sysPingReport) || {})
					.length > 0
					? JSON.stringify(
							player.isLinuxBox ? player.boxServicePingReport : player.sysPingReport,
							null,
							2
					  )
					: 'N/A',
			shouldDisplay: true,
		},
		{
			title: 'Updater Status',
			value: player.boxUpdaterStatus,
			shouldDisplay: player.isLinuxBox,
		},
		{
			title: 'Updater Report',
			value:
				Object.keys(player.boxUpdaterPingReport || {}).length > 0
					? JSON.stringify(player.boxUpdaterPingReport, null, 2)
					: 'N/A',
			shouldDisplay: player.isLinuxBox,
		},
		{
			title: 'Updater Last Connected',
			value: player.boxUpdaterLastConnectedAt
				? toLocaleDateTime(new Date(player.boxUpdaterLastConnectedAt))
				: 'N/A',
			shouldDisplay: player.isLinuxBox,
		},
	];
	return (
		<Box sx={{ flexDirection: 'column', borderTop: '1px solid #eeeeee' }}>
			{moreFields.map((playerField, idx) => {
				if (!playerField.shouldDisplay) return null;
				return (
					<Box
						key={idx}
						sx={{
							display: 'flex',
							flexDirection: 'row',
							border: '1px solid #eeeeee',
							borderTop: 'unset',
						}}
					>
						<Box
							style={{
								minWidth: '30%',
								display: 'flex',
								alignItems: 'center',
								paddingRight: 0.5,
								borderRight: '1px solid #eeeeee',
							}}
						>
							{playerField.title}
						</Box>
						<Box sx={{ display: 'flex', paddingLeft: 1, flex: '1 1 auto' }}>
							<pre style={{ whiteSpace: 'pre-wrap' }}>{playerField.value}</pre>
						</Box>
					</Box>
				);
			})}
		</Box>
	);
}

function SystemSettingComp({ notifyGeneral, player, boxServiceVersions, handleSysSettings }) {
	const intl = useIntl();
	const [boxServiceVersion, setBoxServiceVersion] = React.useState('');
	const [chromiumVersion, setChromiumVersion] = React.useState('');
	const [chromiumLinuxVersion, setChromiumLinuxVersion] = React.useState('');
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', '& > *': { m: 0.5 } }}>
			<FormControl sx={{ minWidth: 120, maxWidth: 500, m: 0.5 }}>
				<InputLabel id="boxServiceVersionSelect">
					{intl.formatMessage({
						id: 'pages.Admin.components.PlayerUniverse.LinuxBoxSysVersionText',
					})}
				</InputLabel>
				<Select
					label={intl.formatMessage({
						id: 'pages.Admin.components.PlayerUniverse.LinuxBoxSysVersionText',
					})}
					labelId="boxServiceVersionSelect"
					value={boxServiceVersion}
					onChange={(e) => {
						setBoxServiceVersion(e.target.value);
					}}
				>
					{boxServiceVersions.map((ver, idx) => {
						return (
							<MenuItem key={idx} sx={{ fontSize: '0.9rem' }} value={ver.boxServiceVersion}>
								{ver.boxServiceVersion}
								<Typography sx={{ ml: 0.5 }} variant="caption">{`(on ${toLocaleDateTime(
									new Date(ver.createdAt)
								)})`}</Typography>
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					'& > :not(:first-child)': { m: 0.5 },
				}}
			>
				<TextField
					label={intl.formatMessage({
						id: 'pages.Admin.components.PlayerUniverse.LinuxBoxChromiumVerLabel',
					})}
					value={chromiumVersion}
					onChange={(e) => setChromiumVersion(e.target.value)}
				/>
				<TextField
					sx={{ minWidth: 300 }}
					label={intl.formatMessage({
						id: 'pages.Admin.components.PlayerUniverse.LinuxBoxChromiumLinuxVerLabel',
					})}
					value={chromiumLinuxVersion}
					onChange={(e) => setChromiumLinuxVersion(e.target.value)}
				/>
			</Box>

			<Box sx={{ '& > :not(:first-child)': { m: 1 } }}>
				<Button
					disabled={!((chromiumVersion && chromiumLinuxVersion) || boxServiceVersion)}
					onClick={() => {
						// validate
						if (
							(chromiumVersion && !chromiumLinuxVersion) ||
							(!chromiumVersion && chromiumLinuxVersion)
						) {
							notifyGeneral(
								intl.formatMessage({
									id: 'pages.Admin.components.PlayerUniverse.LinuxBoxChromiumValidationErrorNotify',
								}),
								'error'
							);
							return;
						}

						const appversion = {};
						if (boxServiceVersion) {
							const selectedVersion = boxServiceVersions.find(
								(v) => v.boxServiceVersion === boxServiceVersion
							);
							appversion.boxServiceVersion = selectedVersion.boxServiceVersion;
							appversion.boxServiceZipUrl = selectedVersion.boxServiceZipUrl;
						}
						if (chromiumVersion) {
							appversion.boxChromiumVersion = chromiumVersion;
							appversion.boxChromiumFullVersionName = chromiumLinuxVersion;
						}

						if (Object.keys(appversion).length > 0) {
							handleSysSettings(player.id, appversion);
						}
					}}
				>
					{intl.formatMessage({ id: 'GENERAL.Save' })}
				</Button>
				<Button
					color="secondary"
					onClick={() => {
						setBoxServiceVersion('');
						setChromiumVersion('');
						setChromiumLinuxVersion('');
					}}
				>
					{intl.formatMessage({ id: 'GENERAL.Reset' })}
				</Button>
			</Box>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	// contentWrapper: {
	// 	...theme.contentWrapper,
	// },
	root: {
		padding: theme.spacing(1),
		// paddingBottom: theme.spacing(1),
		position: 'relative',
		width: '100%',
		height: '100%',
		overflow: 'auto',
		display: 'grid',
		gridTemplateRows: 'minmax(30px, max-content) auto minmax(30px, max-content)',
		gridGap: theme.spacing(1, 1),
		gridTemplateAreas: `
		"header"
		"content"
		"pagination"
		`,
	},
	header: {
		gridArea: 'header',
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(1),
	},
	filtersDisplaySection: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		gap: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	selectedFilterChipsContainer: {
		...theme.customBoxShadow,
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(0.5),
		padding: theme.spacing(0.5, 1),
		border: `1px solid rgba(0,0,0,0.2)`,
		borderRadius: 16,
		backgroundColor: amber[200],
	},
	search: {
		flex: '1 1 auto',
		// padding: theme.spacing(1),
		// paddingTop: 0,
		[theme.breakpoints.down(theme.mobileViewBreakpoint)]: {
			display: 'flex',
			justifyContent: 'flex-start',
			width: '100%',
			// marginBottom: 8,
		},
	},
	content: {
		gridArea: 'content',
		overflow: 'auto',
	},
	pagination: {
		gridArea: 'pagination',
		// marginTop0: theme.spacing(1),
	},

	rightIconInButton: {
		marginLeft: theme.spacing(1),
	},

	addingTextField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '90%',
	},

	addingButton: {
		marginRight: theme.spacing(1),
	},
	addingButtonContainer: {
		display: 'flex',
		justifyContent: 'center',
		margin: theme.spacing(1),
	},

	commentTimeStamp: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: '8px',
		marginRight: '8px',
	},
	resize: {
		fontSize: 'inherit',
	},

	actionsWrapper: {
		display: 'flex',
		gap: theme.spacing(0.5),
		flexWrap: 'wrap',
		maxWidth: 160,
		minWidth: 80,
		justifyContent: 'center',
	},
	actionIconButton: {
		'&:hover': {
			backgroundColor: `rgba(0,0,0,0.1)`,
		},
	},
	maps: {
		margin: theme.spacing(1),
		height: 36,
		width: 36,
	},
}));

function PlayerUniverse({ userData, openGlobalDialog, resetGlobalDialog, notifyGeneral }) {
	const classes = useStyles();
	const intl = useIntl();
	const theme = useTheme();
	const isMobileView = !useMediaQuery(theme.breakpoints.up(theme.mobileViewBreakpoint));

	// states
	const [isFetching, setIsFetching] = React.useState(false);
	const [screens, setScreens] = React.useState([]);
	// filter states
	/**
	 * filters contains all filters selected by user
	 * format is compatable with the "body" param in API request:
	 	{
			[searchableKey]: [], // if type is checkbox
			[searchableKey]: true/false, // if type is switch
			[searchableKey]: { // if type is datetime (Only "updatedDatetime" is datetime). NB: the searchableKey is missing "From" and "To" which are corresponding to startDate & endData in the value object
				startDate: Date(),
				endDate: Date(),
			}
		}
	 */
	const [filters, setFilters] = React.useState({});
	// search keyword state
	const [searchKeyword, setSearchKeyword] = React.useState('');
	// multiple selections
	const [selectedRowIds, setSelectedRowIds] = React.useState([]);
	// sortBy states
	const [sortBy, setSortBy] = React.useState([]);
	// pagination states
	const [pageIndex, setPageIndex] = React.useState(0);
	const [itemsPerPage, setItemsPerPage] = React.useState(25);
	const [totalNumItems, setTotalNumItems] = React.useState(-1);
	// other states
	const [batchAction, setBatchAction] = React.useState(null);
	const [playerToSetCommand, setPlayerToSetCommand] = React.useState(null);
	const [playerToSetComment, setPlayerToSetComment] = React.useState(null);
	const [playerForSysSetting, setPlayerForSysSetting] = React.useState(null);
	const [boxServiceVersions, setBoxServiceVersions] = React.useState(null);
	const [screensMaps, setScreensMaps] = React.useState([]);
	const [openMaps, setOpenMaps] = React.useState(false);

	const setPlayerToOpenSysSetting = React.useCallback(
		async (player) => {
			if (!boxServiceVersions) {
				try {
					const res = await fetchBoxServiceReleases();
					setBoxServiceVersions(res.data);
				} catch (err) {
					notifyGeneral(
						intl.formatMessage(
							{
								id: 'pages.Admin.components.PlayerUniverse.LinuxBoxServiceFetchReleaseErrorNotify',
							},
							{ errorMsg: err.response ? err.response.data.message : err.message }
						),
						'error'
					);
					return;
				}
			}
			setPlayerForSysSetting(player);
		},
		[boxServiceVersions, intl, notifyGeneral]
	);

	const addComment = React.useCallback(
		(id, val) => {
			if (!val) {
				return notifyGeneral(intl.formatMessage({ id: 'GENERAL.NotEmpty' }), 'error');
			}
			let reqCommentData = {
				commentObj: {
					message: val,
					commentedByUid: userData.userId,
					company: userData.company,
					...(userData.firstname && userData.lastname
						? {
								userAlias: userData.firstname[0].toUpperCase() + userData.lastname[0].toUpperCase(),
						  }
						: {}),
				},
			};
			return patchReportPlayersById({ playerId: id, bodyParams: reqCommentData })
				.then((res) => {
					const updatedScreen = res.data;
					setScreens(
						screens.map((screen) => {
							if (screen.id === updatedScreen.id) {
								updatedScreen.status =
									updatedScreen.lastConnectedAt === undefined ? 'NEVER' : updatedScreen.status;
								updatedScreen.sortByStatus =
									updatedScreen.status === 'ACTIVE'
										? 2
										: updatedScreen.status === 'INACTIVE'
										? 1
										: 3;
								return updatedScreen;
							}
							return screen;
						})
					);
					setPlayerToSetComment(updatedScreen); // the "updatedScreen" has been updated with sortByStatus
					resetGlobalDialog();
				})
				.catch((err) => {
					notifyGeneral(err.response ? err.response.data.message : err.message, 'error');
				});
		},
		[
			intl,
			notifyGeneral,
			resetGlobalDialog,
			screens,
			userData.company,
			userData.firstname,
			userData.lastname,
			userData.userId,
		]
	);

	const showMoreForPlayer = React.useCallback(
		(player) => {
			let moreInfoDialog = {
				size: 'md',
				title: `More Info - ${player.name}`,
				content: <MoreForPlayerComp player={player} />,
			};
			openGlobalDialog(moreInfoDialog);
		},
		[openGlobalDialog]
	);

	// Open Google maps
	const openGoogleMaps = () => {
		setOpenMaps(true);
	};

	const checkHasRadioStatus = React.useCallback(
		async (rowValue) => {
			const res = await fetchUserData({
				queryParams: {
					userId: rowValue.createdBy.uid,
					domain: rowValue.domain,
				},
			});
			if (res.data.startleLoginUrl) {
				const msg = (
					<div>
						<Typography variant="body2" component="div">
							{intl.formatMessage({
								id: 'pages.Admin.components.PlayerUniverse.HasRadioBtnText1',
							})}
						</Typography>
						<Typography variant="body2" component="div">
							<Link
								style={{ cursor: 'pointer' }}
								underline="always"
								color="inherit"
								variant="inherit"
								href={res.data.startleLoginUrl}
								rel="noreferrer"
								target="_blank"
							>
								{intl.formatMessage({
									id: 'pages.Admin.components.PlayerUniverse.clickHereBtnText',
								})}
							</Link>
							{intl.formatMessage({
								id: 'pages.Admin.components.PlayerUniverse.HasRadioBtnText2',
							})}
						</Typography>
					</div>
				);
				notifyGeneral(msg, 'success', { autoHideDuration: 3000 });
			} else {
				notifyGeneral(
					intl.formatMessage({
						id: 'pages.Admin.components.PlayerUniverse.HasRadioBtnError',
					}),
					'error',
					{ autoHideDuration: 3000 }
				);
			}
		},
		[intl, notifyGeneral]
	);

	const handleSetCommand = React.useCallback(
		async (command, player) => {
			const res = await setCommandsToPlayer({
				playerId: player.id,
				bodyParams: { commands: [command] },
			});
			setScreens(
				screens.map((screen) => {
					if (screen.id === player.id) {
						screen.sysExecCommands = res.data.commands;
					}
					return screen;
				})
			);
		},
		[screens]
	);

	const handleBatchSetCommand = React.useCallback(
		async (command, playerIds) => {
			try {
				const { data: updatedPlayers } = await patchBatchReportPlayers({
					bodyParams: { commands: [command], playerIds },
				});

				setScreens(
					screens.map((screen) => {
						const updatedPlayer = updatedPlayers.find((p) => p.id === screen.id);
						if (updatedPlayer) {
							screen.sysExecCommands = updatedPlayer.sysExecCommands;
						}
						return screen;
					})
				);
				setBatchAction(null);
				notifyGeneral('Command was successfully added.', 'success');
				return true;
			} catch (err) {
				notifyGeneral(err.response ? err.response.data.message : err.message, 'error');
				return false;
			}
		},
		[notifyGeneral, screens]
	);

	const handleBatchAddComment = React.useCallback(
		async (comment, playerIds) => {
			try {
				const { data: updatedPlayers } = await patchBatchReportPlayers({
					bodyParams: {
						playerIds,
						commentObj: {
							message: comment,
							commentedByUid: userData.userId,
							company: userData.company,
							...(userData.firstname && userData.lastname
								? {
										userAlias:
											userData.firstname[0].toUpperCase() + userData.lastname[0].toUpperCase(),
								  }
								: {}),
						},
					},
				});

				setScreens(
					screens.map((screen) => {
						const updatedPlayer = updatedPlayers.find((p) => p.id === screen.id);
						if (updatedPlayer) {
							screen.comments = updatedPlayer.comments;
						}
						return screen;
					})
				);
				setBatchAction(null);
				notifyGeneral('New comment was successfully added.', 'success');
				return true;
			} catch (err) {
				notifyGeneral(err.response ? err.response.data.message : err.message, 'error');
				return false;
			}
		},
		[
			notifyGeneral,
			screens,
			userData.company,
			userData.firstname,
			userData.lastname,
			userData.userId,
		]
	);

	// handle page changes (prev or next page)
	const handleChangePage = React.useCallback((event, newPage) => {
		setPageIndex(newPage);
	}, []);

	const handleChangeRowsPerPage = React.useCallback(
		(event) => {
			setPageIndex(Math.ceil((pageIndex * itemsPerPage) / Number(event.target.value)));
			setItemsPerPage(Number(event.target.value));
		},
		[itemsPerPage, pageIndex]
	);

	const handleUpdateFilters = React.useCallback((filters) => {
		setPageIndex(0);
		setFilters(filters);
	}, []);

	// ##############################
	// React-Table (treat it as part of local states)
	// #############################
	// function to select rows that require highlight
	const columnsConfigure = useColumnsConfig({
		setPlayerToSetComment,
		setPlayerToSetCommand,
		isMobileView,
		checkHasRadioStatus,
	});
	// const rowHighlightPicker = React.useCallback((row) => row.isSelected, []);
	const playerActions = React.useMemo(() => {
		return [
			{
				shouldDisplay: () => true, // hook func to verify that should the action be displayed
				isDisabled: () => false,
				icon: <MoreInfoIcon fontSize="small" color="info" />,
				tooltip: intl.formatMessage({
					id: 'GENERAL.More',
				}),
				CustomComp: null,
				clickHandler: ({ rowOriginal }) => showMoreForPlayer(rowOriginal), // arg is {rowOriginal}, event
			},
			{
				shouldDisplay: ({ rowOriginal }) => Boolean(rowOriginal.isLinuxBox), // arg is {rowOriginal}
				isDisabled: () => false,
				icon: <SysVersionIcon fontSize="small" color="info" />,
				tooltip: intl.formatMessage({ id: 'GENERAL.Setting' }),
				CustomComp: null,
				clickHandler: ({ rowOriginal }) => setPlayerToOpenSysSetting(rowOriginal), // arg is {rowOriginal}, event
			},
		];
	}, [intl, setPlayerToOpenSysSetting, showMoreForPlayer]);

	// ##############################
	// actions for multiple selections
	// #############################
	const multipleSelectionActions = React.useMemo(() => {
		return [
			{
				shouldDisplay: () => true, // hook func to verify that should the action be displayed
				isDisabled: () => false,
				icon: <AddCommentIcon fontSize="small" color="info" />,
				tooltip: intl.formatMessage({
					id: 'pages.ScreenManager.components.PlayerUniverse.AddCommentDialogLabel',
				}),
				CustomComp: null,
				clickHandler: () => setBatchAction(BATCH_ACTIONS.ADD_COMMENT),
			},
			{
				shouldDisplay: () => true, // hook func to verify that should the action be displayed
				isDisabled: () => false,
				icon: <SetCommandIcon fontSize="small" color="info" />,
				tooltip: intl.formatMessage({
					id: 'pages.Admin.components.PlayerUniverse.AddCommandTooltip',
				}),
				CustomComp: null,
				clickHandler: () => setBatchAction(BATCH_ACTIONS.SET_COMMAND),
			},
		];
	}, [intl]);

	// ##############################
	// fetch data
	// #############################
	React.useEffect(() => {
		setIsFetching(true);

		let formatedFilters = Object.keys(filters).reduce((accu, searchableKey) => {
			const filterSetting = FILTERS.find((f) => f.searchableKey === searchableKey);
			if (filterSetting?.type === 'datetime') {
				// NB: datetime field is hardcoded as datetime range is using different data structure
				return {
					...accu,
					[`${searchableKey}From`]: filters[searchableKey].startDate.toISOString(),
					[`${searchableKey}To`]: filters[searchableKey].endDate.toISOString(),
				};
			} else if (filterSetting?.type === 'date') {
				// NB: date field is hardcoded as datetime range is using different data structure
				//		 and value is also needed to be formatted to date string (not datetime string)
				return {
					...accu,
					[`${searchableKey}From`]: filters[searchableKey].startDate.toISOString().split('T')[0],
					[`${searchableKey}To`]: filters[searchableKey].endDate.toISOString().split('T')[0],
				};
			} else if (filterSetting?.type === 'select') {
				return {
					...accu,
					[searchableKey]: filterSetting?.multiple
						? filters[searchableKey]
						: filters[searchableKey][0],
				};
			} else {
				return { ...accu, [searchableKey]: filters[searchableKey] };
			}
		}, {});

		if (searchKeyword) {
			formatedFilters.keyword = searchKeyword;
		}

		searchReportPlayers({
			queryParams: {
				offset: pageIndex * itemsPerPage,
				limit: itemsPerPage,
				...(sortBy.length > 0
					? { sortBy: sortBy[0].id, sortByDirection: sortBy[0].desc ? 'desc' : 'asc' }
					: {}),
			},
			bodyParams: { filters: formatedFilters },
		})
			.then(async (res) => {
				if (res.data.totalFound === 0 /* || !formatedFilters.keyword */) {
					// if no screen found, just pass res to next step
					return res;
				}

				const userDomainStringSet = new Set();
				const domainSet = new Set();
				const userDomainMap = [];
				res.data.results.forEach((screen) => {
					const userDomainUniqueKey = `${screen.createdBy.uid}-${screen.domain}`;
					domainSet.add(screen.domain);
					if (!userDomainStringSet.has(userDomainUniqueKey)) {
						userDomainStringSet.add(userDomainUniqueKey);
						userDomainMap.push({
							uid: `${screen.createdBy.uid}`,
							domain: screen.domain,
						});
					}
				});
				const allUsersDataRes = await fetchUsersCrossDomain({
					queryParams: {
						domains: Array.from(domainSet).join(','),
					},
					bodyParams: {
						users: userDomainMap,
					},
				});

				if (allUsersDataRes.data.totalFound === 0) {
					// the user was not found, return original screen response
					return res;
				}

				// merge the userdata into screen
				res.data.results = res.data.results.map((screen) => {
					const uidInScreen = screen.createdBy.uid;
					// find the corresponding user data;
					const userData = allUsersDataRes.data.results.find((u) => u.id === uidInScreen);
					if (userData) {
						return { ...screen, createdBy: { ...screen.createdBy, company: userData.company } };
					}
					return screen;
				});

				return res;
			})
			.then((res) => {
				setScreens(
					res.data.results.map((screen) => {
						screen.status = screen.lastConnectedAt === undefined ? 'NEVER' : screen.status;
						screen.sortByStatus =
							screen.status === 'ACTIVE' ? 2 : screen.status === 'INACTIVE' ? 1 : 3;
						return screen;
					})
				);
				setTotalNumItems(res.data.totalFound);
			})
			.catch((err) => {
				notifyGeneral(err.response ? err.response.data.message : err.message, 'error');
			})
			.finally(() => {
				setIsFetching(false);
			});
	}, [filters, itemsPerPage, notifyGeneral, pageIndex, searchKeyword, sortBy]);

	// This function will convert Zipcode to lat-long
	const getLatLongFromURL = async (zipCode) => {
		let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${googleAPIKey}`;
		let urlData;
		try {
			urlData = fetch(url).then((res) => res.json());
		} catch (error) {
			console.error(error);
		}
		return urlData;
	};
	React.useEffect(() => {
		// API CALL
		fetchMapsData({
			queryParams: filters.domain
				? {
						domain: filters.domain.map((x) => x).join('|'),
				  }
				: {},
		})
			.then(async (res) => {
				await Promise.all(
					res.data.map(async (screen) => {
						if (screen.locations) {
							await fetchUserDataByIdGroup({
								queryParams: {
									userIds: screen.locations.map((x) => x.uid).join(','),
									domain: screen.domain,
								},
							})
								.then(async (res) => {
									await Promise.all(
										screen.locations.map(async (obj) => {
											const user = res.data.results.find(
												(user) => user['id'].toString() === obj['uid']
											);
											if (user) {
												if (user.zipCode && user.zipCode !== '') {
													const zipCode = await getLatLongFromURL(user.zipCode);
													if (zipCode.status === 'OK') {
														obj.locationLatLong = zipCode.results[0].geometry.location;
														obj.fullLocation = zipCode.results[0].formatted_address;
													}
												}
												obj.userZipCode = user.zipCode;
												obj.userInfo = { ...user };
											}
										})
									);
								})
								.catch((err) => {
									console.log('err --->', err);
								});
						}
					})
				);
				// Restructure the data
				const transformedData = res.data.reduce((acc, domainObj) => {
					domainObj.locations.forEach((location) => {
						if (location.userInfo && location.userInfo.zipCode) {
							acc.push({
								domain: domainObj.domain,
								uid: location.uid,
								locationLatLong: location.locationLatLong,
								userInfo: location.userInfo,
								fullLocation: location.fullLocation,
								hasInactiveScreen: location.hasInactiveScreen,
								screens: location.screens,
							});
						}
					});
					return acc;
				}, []);
				setScreensMaps(transformedData);
			})
			.catch((err) => {
				console.log('err --->', err);
			});
	}, [filters.domain]);

	// post-process after screens changes (e.g. pagination, search for new keyword, new filters)
	React.useEffect(() => {
		setSelectedRowIds([]);
	}, [screens]);

	return (
		<div className={classes.root}>
			<Paper className={classes.header}>
				<Typography variant="h4" gutterBottom>
					{intl.formatMessage({
						id: 'pages.ScreenManager.components.PlayerUniverse.TableTitle',
					})}
					<Tooltip title={'Maps'}>
						<Fab
							color="primary"
							aria-label="Maps"
							className={classes.maps}
							onClick={() => openGoogleMaps()}
						>
							<PlaceIcon />
						</Fab>
					</Tooltip>
				</Typography>
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					{/* <Filters /> */}
					<Filters selectedFilters={filters} setSelectedFilters={handleUpdateFilters} />
					{selectedRowIds.length > 0 && (
						<Box sx={{ paddingLeft: 1, display: 'flex', alignItems: 'center' }}>
							{' '}
							{multipleSelectionActions.map((action, idx) => {
								if (!action.shouldDisplay()) return null;
								return (
									<IconButton
										key={`multiple-selection-action-${idx}`}
										size="small"
										title={action.tooltip}
										disabled={action.isDisabled()}
										onClick={() =>
											typeof action.clickHandler === 'function'
												? action.clickHandler({ selectedRowIds })
												: null
										}
									>
										{action.icon}
									</IconButton>
								);
							})}
						</Box>
					)}
					<Search
						placeholder={intl.formatMessage({ id: 'GENERAL.Search' })}
						className={classes.search}
						OnEnterKeyPressed={(e) => setSearchKeyword(e.target.value)}
						onClearClick={() => setSearchKeyword('')}
						onSearchClick={(val) => setSearchKeyword(val)}
					/>
				</Box>
				<section className={classes.filtersDisplaySection}>
					{FILTERS.map((filter, idx) => {
						if (filter.type === 'select') {
							let selectedFiltersInSection = [...(filters[filter.searchableKey] || [])];

							return selectedFiltersInSection.length === 0 ? null : (
								<div
									key={`${filter.searchableKey}-filters-${idx}`}
									className={classes.selectedFilterChipsContainer}
								>
									<Typography variant="body2" component="span">{`${filter.title}: `}</Typography>
									{(filter.options || []).map((filterOption, filterOptIdx) => {
										return !selectedFiltersInSection.includes(filterOption.value) ? null : (
											<Chip
												key={`${filter.searchableKey}-filter-chip-${filterOptIdx}`}
												color="secondary"
												size="small"
												component="span"
												// sx={{ margin: [0, 0.5] }}
												label={filterOption.label}
												onDelete={() => {
													selectedFiltersInSection.splice(
														selectedFiltersInSection.indexOf(filterOption.value),
														1
													);

													if (selectedFiltersInSection.length > 0) {
														setFilters({
															...filters,
															[filter.searchableKey]: selectedFiltersInSection,
														});
													} else {
														let currFilters = { ...filters };
														delete currFilters[filter.searchableKey];
														setFilters(currFilters);
													}
												}}
											/>
										);
									})}
								</div>
							);
						} else if (filter.type === 'datetime' || filter.type === 'date') {
							return filters[filter.searchableKey] ? (
								<div
									key={`${filter.searchableKey}-filters-${idx}`}
									className={classes.selectedFilterChipsContainer}
								>
									<Typography variant="body2" component="span">{`${filter.title}: `}</Typography>
									<Chip
										color="secondary"
										size="small"
										component="span"
										label={`${filters[
											filter.searchableKey
										].startDate.toLocaleDateString()} ~ ${filters[
											filter.searchableKey
										].endDate.toLocaleDateString()}`}
										onDelete={() => {
											let currFilters = { ...filters };
											delete currFilters[filter.searchableKey];
											setFilters(currFilters);
										}}
									/>
								</div>
							) : null;
						} else {
							return null;
						}
					})}
				</section>
			</Paper>
			<div className={classes.content}>
				{isMobileView ? (
					<PlayerGridView
						screens={screens}
						playerActions={playerActions}
						columns={columnsConfigure}
						noDataMsg={
							isFetching
								? intl.formatMessage({
										id: 'ReactTable.LoadingText',
								  })
								: intl.formatMessage({
										id: 'ReactTable.NoDataText',
								  })
						}
					/>
				) : (
					<PlayerTableView
						columns={columnsConfigure}
						screens={screens}
						playerActions={playerActions}
						sortBy={sortBy}
						setSortBy={setSortBy}
						selectedRowIds={selectedRowIds}
						setSelectedRowIds={setSelectedRowIds}
						noDataMsg={
							isFetching
								? intl.formatMessage({
										id: 'ReactTable.LoadingText',
								  })
								: intl.formatMessage({
										id: 'ReactTable.NoDataText',
								  })
						}
					/>
				)}
			</div>
			{screens.length > 0 && (
				<div className={classes.pagination}>
					<MUITablePagination
						// pagination props
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						rowsPerPage={itemsPerPage}
						disableRowsPerPage={false}
						pageIndex={pageIndex}
						totalNumRows={totalNumItems}
						labelRowsPerPage={intl.formatMessage({
							id: 'pages.Admin.components.PlayerUniverse.ItemsPerPageLabel',
						})}
					/>
				</div>
			)}
			{/* Batch - set command */}
			{batchAction === BATCH_ACTIONS.SET_COMMAND && (
				<MuiDialog
					open={true}
					size={'md'}
					title={intl.formatMessage({
						id: 'pages.Admin.components.PlayerUniverse.BatchSetCommandDialogTitle',
					})}
					content={
						<AddCommandRowComp
							isLinuxBox={
								!selectedRowIds.some((playerId) => {
									const screen = screens.find((screen) => screen.id === playerId);
									return !screen.isLinuxBox;
								})
							}
							notifyGeneral={notifyGeneral}
							sequenceNo={0}
							handleSetCommand={async (command) =>
								await handleBatchSetCommand(command, selectedRowIds)
							}
						/>
					}
					onClose={() => setBatchAction(null)}
				/>
			)}
			{/* Batch - add comment */}
			{batchAction === BATCH_ACTIONS.ADD_COMMENT && (
				<MuiDialog
					open={true}
					size={'md'}
					title={intl.formatMessage({
						id: 'pages.Admin.components.PlayerUniverse.BatchAddCommentDialogTitle',
					})}
					content={
						<BatchAddCommentComp
							handleBatchAddComment={async (comment) =>
								await handleBatchAddComment(comment, selectedRowIds)
							}
						/>
					}
					onClose={() => setBatchAction(null)}
				/>
			)}
			{/* player command dialog */}
			{playerToSetCommand && (
				<MuiDialog
					open={true}
					size={'md'}
					title={intl.formatMessage(
						{ id: 'pages.Admin.components.PlayerUniverse.CommandDialogTitle' },
						{ playerName: playerToSetCommand.name }
					)}
					content={
						<PlayerCommandComp
							notifyGeneral={notifyGeneral}
							player={playerToSetCommand}
							handleSetCommand={async (command) => {
								await handleSetCommand(command, playerToSetCommand);
							}}
						/>
					}
					onClose={() => setPlayerToSetCommand(null)}
				/>
			)}

			{playerToSetComment && (
				<MuiDialog
					open={true}
					size={'md'}
					title={intl.formatMessage(
						{ id: 'pages.Admin.components.PlayerUniverse.CommentDialogTitle' },
						{ playerName: playerToSetComment.name }
					)}
					content={
						<PlayerCommentComp
							player={playerToSetComment}
							handleAddComment={async (comment) => {
								if (!comment) {
									notifyGeneral(intl.formatMessage({ id: 'GENERAL.NotEmpty' }), 'error');
									throw new Error(`Comment can't be empty`);
								}
								await addComment(playerToSetComment.id, comment);
							}}
						/>
					}
					onClose={() => setPlayerToSetComment(null)}
				/>
			)}
			{playerForSysSetting && (
				<MuiDialog
					open={Boolean(playerForSysSetting)}
					size={'md'}
					title={intl.formatMessage(
						{ id: 'pages.Admin.components.PlayerUniverse.LinuxBoxSysSettingDialogTitle' },
						{ playerName: playerForSysSetting.name }
					)}
					content={
						<SystemSettingComp
							notifyGeneral={notifyGeneral}
							player={playerForSysSetting}
							boxServiceVersions={boxServiceVersions}
							handleSysSettings={async (playerId, appVersion) => {
								try {
									await patchSetBoxAppVersion({
										bodyParams: {
											playerIds: [playerId],
											appversion: appVersion,
										},
									});
									notifyGeneral(
										intl.formatMessage({
											id: 'pages.Admin.components.PlayerUniverse.SuccessToSaveAppVersionNotify',
										}),
										'success'
									);
								} catch (err) {
									notifyGeneral(
										intl.formatMessage(
											{
												id: 'pages.Admin.components.PlayerUniverse.FailToSaveAppVersionErrorNotify',
											},
											{
												errorMsg: err.response ? err.response.data.message : err.message,
											}
										),
										'error'
									);
								}
							}}
						/>
					}
					onClose={() => setPlayerForSysSetting(null)}
				/>
			)}
			{openMaps && (
				<MuiDialog
					open={true}
					size={'lg'}
					title={intl.formatMessage({
						id: 'pages.Admin.components.PlayerUniverse.MapsTitle',
					})}
					content={<Maps mapsData={screensMaps} />}
					onClose={() => setOpenMaps(false)}
				/>
			)}
		</div>
	);
}

PlayerUniverse.propTypes = {
	userData: PropTypes.object.isRequired,
	openGlobalDialog: PropTypes.func.isRequired,
	resetGlobalDialog: PropTypes.func.isRequired,
	notifyGeneral: PropTypes.func.isRequired,
	fetchUserDataByIdGroup: PropTypes.func.isRequired,
};

PlayerUniverse.defaultProps = {};

const mapStateToProps = (state) => {
	return {
		userData: {
			userId: state.authentication.userId,
			firstname: state.usermanager?.userData?.firstname,
			lastname: state.usermanager?.userData?.lastname,
			company: state.usermanager?.userData?.company,
		},
	};
};
export default connect(mapStateToProps, {
	openGlobalDialog,
	resetGlobalDialog,
	notifyGeneral,
	fetchUserDataByIdGroup,
})(PlayerUniverse);
