import React from 'react';

// import cx from 'classnames';
// import PropTypes from 'prop-types';

// MUI components
import { makeStyles } from '@mui/styles';
import {
	Typography,
	IconButton,
	Switch,
	MenuItem,
	TextField,
	ClickAwayListener,
} from '@mui/material';

import { _ } from 'utils/libHelper';
import { Edit as EditIcon, Cancel as CancelIcon, Done as ApplyIcon } from '@mui/icons-material';

import { InputBox } from 'components';

// intl lang
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
	buttonWrapper: {
		// position: 'absolute',
		display: 'flex',
		// width: `calc(100% - ${theme.spacing(1) * 2}px)`,
		// height: '100%',
		// paddingRight: theme.spacing(2),
		// right: 0,
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	icon: {
		margin: 2,
		fontSize: '1rem',
	},
	radioWrapper: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
	},
	formRightCell: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		whiteSpace: 'nowrap',
	},
}));

const useStylesEditableCell = makeStyles((theme) => ({
	cellWrapper: {
		width: '100%',
		height: '100%',
		display: 'flex',
		position: 'relative',
		alignItems: 'center',
		justifyContent: 'space-between',
		// justifyContent: 'center',
		'& a': {
			cursor: 'pointer',
		},
	},
	editableIcon: {
		// position: 'absolute',
		// top: 0,
		// right: 0,
		order: '2',
	},
}));

const usingStylesInputWithSelect = makeStyles((theme) => ({
	input: {
		color: 'inherit',
		fontSize: 'inherit',
		backgroundColor: '#ffffff',
		paddingRight: 8,
		'& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button': {
			WebkitAppearance: 'none',
			MozAppearance: 'none',
			margin: 0,
		},
		'& input[type=number]': {
			'-webkit-appearance': 'textfield',
			'-moz-appearance': 'textfield',
			height: 'inherit', // used to fix conflicts with bootstrap in lasso app
			padding: '10.5px 6px', // used to fix conflicts with bootstrap in lasso app
		},
	},
	buttonWrapper: {
		// position: 'absolute',
		display: 'flex',
		// width: `calc(100% - ${theme.spacing(1) * 2}px)`,
		// height: '100%',
		// paddingRight: theme.spacing(2),
		// right: 0,
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	icon: {
		margin: 2,
		fontSize: '1rem',
	},
	radioWrapper: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
	},
	label: {
		fontSize: 'inherit',
	},
	textField: {
		color: 'inherit',
		fontSize: 'inherit',
	},
	textFieldInput: {
		color: 'inherit',
		fontSize: 'inherit', //paletteFontSize,
		// '&:focus': {
		// 	borderColor: 'unset',
		// },
	},
	textFieldLabel: {
		fontSize: 'inherit !important',
		color: 'inherit !important',
		// marginLeft: -8,
		// paddingLeft: theme.spacing(1), // `${theme.spacing(0)}px ${theme.spacing(2)}px`,
	},
}));

function EditableCell({ allowEdit = false, render }) {
	const classes = useStylesEditableCell();
	const [isEditing, setIsEditing] = React.useState(false);
	return (
		<div className={classes.cellWrapper}>
			{!isEditing && allowEdit && (
				<div title="Edit" className={classes.editableIcon} onClick={() => setIsEditing(true)}>
					<IconButton size="small">
						<EditIcon fontSize="small" />
					</IconButton>
				</div>
			)}
			{render({ isEditing, setIsEditing })}
		</div>
	);
}

function InputRadioBox({ value, label, handleConfirm, handleCancel }) {
	const classes = useStyles();
	const intl = useIntl();
	const [inputVal, setInputVal] = React.useState(value);

	return (
		<ClickAwayListener onClickAway={handleCancel}>
			<div className={classes.radioWrapper}>
				<div className={classes.formRightCell}>
					{intl.formatMessage({ id: 'GENERAL.No' })}
					<Switch
						key={value}
						aria-label={label}
						checked={inputVal}
						onChange={(event) => setInputVal(event.target.checked)}
						color="primary"
					/>
					{intl.formatMessage({ id: 'GENERAL.Yes' })}
				</div>
				<div className={classes.buttonWrapper}>
					<IconButton
						edge="end"
						color="inherit"
						size="small"
						aria-label="ok"
						onClick={(e) => {
							e.stopPropagation();
							handleConfirm(inputVal);
						}}
					>
						<ApplyIcon fontSize="small" className={classes.icon} />
					</IconButton>
					<IconButton
						edge="end"
						color="inherit"
						size="small"
						aria-label="cancel"
						onClick={(e) => {
							e.stopPropagation();
							handleCancel();
						}}
					>
						<CancelIcon fontSize="small" className={classes.icon} />
					</IconButton>
				</div>
			</div>
		</ClickAwayListener>
	);
}

function InputWithSelect({
	initValue, // value of the field
	options, // options array of dropdown list
	handleConfirm,
	handleCancel,
}) {
	const classes = usingStylesInputWithSelect();

	const [inputValue, setInputValue] = React.useState(initValue);
	React.useEffect(() => {
		setInputValue(initValue);
	}, [initValue]);
	return (
		<ClickAwayListener onClickAway={handleCancel}>
			<div className={classes.radioWrapper}>
				<TextField
					select
					value={inputValue}
					variant="outlined"
					onChange={(e) => {
						setInputValue(e.target.value);
					}}
					SelectProps={{
						MenuProps: { disablePortal: true },
					}}
					className={classes.textField}
					margin="dense"
					autoFocus
					InputProps={{
						className: classes.textFieldInput,
					}}
					InputLabelProps={{
						classes: {
							root: classes.textFieldLabel,
						},
						shrink: true,
					}}
					inputProps={{
						style: { marginRight: 46 },
					}}
				>
					{options.map((value, index) => (
						<MenuItem key={index} value={value}>
							{value}
						</MenuItem>
					))}
				</TextField>
				<div className={classes.buttonWrapper}>
					<IconButton
						edge="end"
						color="inherit"
						size="small"
						aria-label="ok"
						onClick={(e) => {
							e.stopPropagation();
							handleConfirm(inputValue);
						}}
					>
						<ApplyIcon fontSize="small" className={classes.icon} />
					</IconButton>
					<IconButton
						edge="end"
						color="inherit"
						size="small"
						aria-label="cancel"
						onClick={(e) => {
							e.stopPropagation();
							handleCancel();
						}}
					>
						<CancelIcon fontSize="small" className={classes.icon} />
					</IconButton>
				</div>
			</div>
		</ClickAwayListener>
	);
}
function useColumnsConfig({ allowEdit, showMinUserLevel, handlePatchDomainSettings }) {
	const intl = useIntl();
	const generateEditedUserLevelDOM = React.useCallback(
		(setIsEditing, value, row) => {
			return (
				<InputWithSelect
					initValue={value}
					handleCancel={() => setIsEditing(false)}
					handleConfirm={(val) => {
						handlePatchDomainSettings(
							{
								settingKey: row.original.key,
								value: row.original.value.toString(),
							},
							{
								settingMinUserLevel: val,
							}
						);
						setIsEditing(false);
					}}
					options={[30, 40, 50, 60]}
				/>
			);
		},
		[handlePatchDomainSettings]
	);

	const generateEditedDOM = React.useCallback(
		(setIsEditing, value, row) => {
			if (row.original.type === 'boolean') {
				return (
					<InputRadioBox
						label={'value'}
						value={value}
						handleCancel={() => setIsEditing(false)}
						handleConfirm={(newVal) => {
							handlePatchDomainSettings(
								{
									settingKey: row.original.key,
									settingMinUserLevel: row.original.settingMinUserLevel,
								},
								{
									value: newVal ? 'true' : 'false',
								}
							);
							setIsEditing(false);
						}}
					/>
				);
			} else if (row.original.type === 'array') {
				let arrValue = value.join(',');
				return (
					<InputBox
						label=""
						value={arrValue}
						handleCancel={() => setIsEditing(false)}
						handleConfirm={(newVal) => {
							// Removed blank spaces
							let val = newVal.replace(/\s/g, '');
							// Removed comma at the end of the string
							val = val.replace(/,\s*$/, '');
							// Replaced comma with the pipe
							val = val.replace(/,/g, '|');
							handlePatchDomainSettings(
								{
									settingKey: row.original.key,
									settingMinUserLevel: row.original.settingMinUserLevel,
								},
								{ value: val }
							);
							setIsEditing(false);
						}}
					/>
				);
			} else {
				return (
					<InputBox
						label=""
						value={value}
						type={row.original.type === 'number' ? 'number' : 'string'}
						handleCancel={() => setIsEditing(false)}
						handleConfirm={(newVal) => {
							// let newValue = row.original.type === 'number' ? parseInt(newVal) : newVal;
							handlePatchDomainSettings(
								{
									settingKey: row.original.key,
									settingMinUserLevel: row.original.settingMinUserLevel,
								},
								{ value: newVal }
							);
							setIsEditing(false);
						}}
					/>
				);
			}
		},
		[handlePatchDomainSettings]
	);
	const generateNonEditedDOM = React.useCallback((value, row) => {
		if (row.original.type === 'boolean') {
			return <Typography variant="inherit">{value ? 'Yes' : 'No'}</Typography>;
		} else if (row.original.type === 'array') {
			return <Typography variant="inherit">{value.length > 0 ? value.join(', ') : []}</Typography>;
		} else {
			return <Typography variant="inherit">{value}</Typography>;
		}
	}, []);
	const columnsConfig = React.useMemo(() => {
		let columnConfigData = [
			{
				Header: intl.formatMessage({
					id: 'pages.EditDomainSettings.EditDomainSettingsList.NameCol',
				}),
				accessor: 'key',
				id: 'key',
				Cell: function renderCell({ value }) {
					return <Typography variant="inherit">{value}</Typography>;
				},
			},
			{
				Header: intl.formatMessage({
					id: 'pages.EditDomainSettings.EditDomainSettingsList.DecriptionCol',
				}),
				accessor: 'description',
				id: 'description',
				Cell: function renderCell({ value }) {
					return <Typography variant="inherit">{value}</Typography>;
				},
			},
			{
				Header: intl.formatMessage({
					id: 'pages.EditDomainSettings.EditDomainSettingsList.SettingUserLevelCol',
				}),
				accessor: 'settingMinUserLevel',
				id: 'settingMinUserLevel',
				hidden: !showMinUserLevel,
				Cell: function renderCell({ value, row }) {
					return (
						<EditableCell
							allowEdit={allowEdit}
							render={({ isEditing, setIsEditing }) => {
								return isEditing ? (
									generateEditedUserLevelDOM(setIsEditing, value, row)
								) : (
									<Typography variant="inherit">{value}</Typography>
								);
							}}
						/>
					);
				},
			},
			{
				Header: intl.formatMessage({
					id: 'pages.EditDomainSettings.EditDomainSettingsList.ValueCol',
				}),
				accessor: 'value',
				id: 'value',
				Cell: function renderCell({ value, row }) {
					return (
						<EditableCell
							allowEdit={allowEdit}
							render={({ isEditing, setIsEditing }) => {
								return isEditing
									? generateEditedDOM(setIsEditing, value, row)
									: generateNonEditedDOM(value, row);
							}}
						/>
					);
				},
			},
		];
		columnConfigData = columnConfigData.filter((x) => {
			return x.hidden !== true;
		});
		return columnConfigData;
	}, [
		allowEdit,
		generateEditedDOM,
		generateEditedUserLevelDOM,
		generateNonEditedDOM,
		intl,
		showMinUserLevel,
	]);

	return columnsConfig;
}
// parameters passed must be memorized
export default useColumnsConfig;
