import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { useDropzone } from 'react-dropzone';
import asyncQueue from 'async/queue';
import config from 'config';

import { genRandomStr } from 'utils/generalHelper.js';
import { readableFileSize, getFileIconClassName } from 'utils/libHelper';
import { createS3Client } from 'utils/appHelper';
import CircularProgressLabelled from '../CircularProgressLabelled/CircularProgressLabelled.jsx';

import {
	Typography,
	Button,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	ListSubheader,
	IconButton,
	LinearProgress,
	Tooltip,
	Link,
	Popover,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material';

import {
	AddCircle as AddIcon,
	CloudUpload as UploadIcon,
	Cancel as CancelIcon,
	RemoveCircle as RemoveIcon,
	Delete as RemoveAllIcon,
	CheckCircle as DoneIcon,
	Error as ErrorIcon,
	Replay as ResetIcon,
} from '@mui/icons-material';

// intl lang
import { useIntl } from 'react-intl';

// redux
import { connect } from 'react-redux';
import { fetchAWSCredential, resetAWSCredential, notifyGeneral } from 'redux/actions'; // actions

const INVALID_FILE_TYPE_DROPZON_CODE = 'file-invalid-type';
const INVALID_FILE_TYPE_ERROR = {
	code: INVALID_FILE_TYPE_DROPZON_CODE,
	message: 'Unsupported file type',
};
const DUPLICATED_FILE_ERROR = { code: 'duplicated', message: 'Duplicate file' };
const TOO_MANY_FILE_ERROR = { code: 'too-many-files', message: 'Too many files' };
const TOTAL_FILESIZE_TOO_LARGE_ERROR = {
	code: 'filesize-total-too-large',
	message: 'Exceed max combined file size',
};

// s3 uploader constants
const S3_RESOURCE = { resource: 's3' };
const minDurationToS3CredExp = 600; // In second. 10 min. min time (in second) to s3 credential expiration. Otherwise renew the credential
// const MIN_UPLOAD_PART = 6 * 1024 * 1024; // 6mb, minimum part size on uploading

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		// height: '100%',
		// overflow: 'hidden',
		// position: 'relative',
	},
	title: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		paddingBottom: theme.spacing(1),
		textTransform: 'capitalize',
	},
	content: {
		width: '100%',
		overflow: 'auto',
		paddingBottom: theme.spacing(1),
		// height: dropzoneSectionHeight,
		// overflowY: 'auto',
		// overflowX: 'hidden',
		// minHeight: minContentHeight,
		// maxHeight: ({ hasTitle }) =>
		// 	`max(${minContentHeight}px, calc(100% - ${
		// 		hasTitle ? actionButtonsHeight + titleHeight : actionButtonsHeight
		// 	}px))`,
	},
	stickyWrapper: {
		position: 'sticky',
		zIndex: 1,
		top: 0,
		backgroundColor: '#f3f3f3',
		borderRadius: theme.spacing(1),
	},
	dropzoneContainer: {
		minHeight: 100,
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(2),
		padding: theme.spacing(1),
		borderWidth: 2,
		borderRadius: 2,
		borderColor: '#eeeeee',
		borderStyle: 'dashed',
		backgroundColor: '#fafafa',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .24s ease-in-out',
		cursor: ({ disabled }) => (disabled ? 'not-allowed' : 'pointer'),
	},
	uploadTip: {
		padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
	},
	filePreview: {
		minWidth: 56,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		'&:before': {
			fontSize: '40px !important', // TODO: remove !important after replacing lasso. Having it here is to fix the conflict to bootstrap in lasso
		},
	},
	fallbackFilePreview: {
		'&:before': {
			fontFamily: 'FontAwesome',
			content: `"\\f15c"`,
			fontSize: 40,
		},
	},
	warnFilePreview: {
		color: theme.palette.danger.main,
		'&:before': {
			fontFamily: 'FontAwesome',
			content: `"\\f12a"`,
			fontSize: 40,
		},
	},
	filesContainer: {
		width: '100%',
		padding: `${theme.spacing(2)} ${theme.spacing(0)}`,
		// height: ({ hasTitle }) =>
		// 	`calc(100% - ${hasTitle ? titleHeight + dropzoneSectionHeight : dropzoneSectionHeight}px)`,
		// // minHeight: 0,
		// // maxHeight: 500,
		// overflowY: 'auto',
		// overflowX: 'hidden',
	},
	filelistRoot: {
		width: '95%',
	},
	rejectedListHeader: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	infoListItemText: {
		width: '40%',
		maxWidth: '40%',
		flex: '1 0 auto',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		display: 'flex',
		alignItems: 'center',
	},
	progressBar: {
		height: 15,
		width: '70%',
	},
	actionButtons: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: `${theme.spacing(1)} 0px`,
	},
	button: {
		marginLeft: theme.spacing(0.5),
		marginRight: theme.spacing(0.5),
	},
	uploadCompleted: {
		color: theme.palette.success.main,
	},
	uploadError: {
		color: theme.palette.danger.main,
	},
}));

function S3Uploader({
	className,
	stickyDropzone,
	title,
	titleClassName,
	maxTotalSize,
	s3Bucket,
	s3FilepathBase,
	useRandomFilename,
	handleLargestZipFile,
	concurrency,
	actionOnFailure,
	handleFileUploadComplete,
	fetchAWSCredential,
	resetAWSCredential,
	notifyGeneral,
	s3Credential,
	domainName,
	// rest is the "rest" of react-dropzone props
	...rest
}) {
	const [openDialog, setOpenDialog] = React.useState({ type: '', open: false });
	// uploading queue instance, only available when uploading on progress
	const [uploadQueue, setUploadQueue] = React.useState(null);
	rest.disabled = Boolean(uploadQueue) || rest.disabled;
	const { disabled, maxFiles, accept } = rest;
	const intl = useIntl();

	const classes = useStyles({
		hasTitle: Boolean(title) || false,
		disabled: disabled,
	});
	// regex to ensure the leading & trailing slash in s3FilepathBase
	// ref: https://stackoverflow.com/questions/43836091/add-leading-and-trailing-slashes-to-string-if-needed-using-regex
	const leadingTrailingSlashRegex = /^\/?([^/]+(?:\/[^/]+)*)\/?$/;
	s3FilepathBase = (s3FilepathBase || '').replace(leadingTrailingSlashRegex, '/$1/') || '/';

	// popover anchor of supported files list
	const [supportedFilesListAnchor, setSupportedFilesListAnchor] = React.useState(null);

	/**
	 * uploadingFiles contains all user's files that will be uploaded
	 * In case of uploading failure, the file object will have "uploadingError" key to hold the error
	 */
	const [uploadingFiles, setUploadingFiles] = React.useState([]);
	// rejectedFiles contains all rejected files
	const [rejectedFiles, setRejectedFiles] = React.useState([]);

	React.useEffect(() => {
		fetchAWSCredential(S3_RESOURCE);
		return () => {
			// clean up when unmounting the component
			resetAWSCredential(S3_RESOURCE);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (!disabled && typeof handleLargestZipFile === 'function') {
			// get largest zip file
			let mostLargeZipfile = null;
			uploadingFiles.forEach((f) => {
				if (
					[
						'application/x-zip-compressed',
						'application/zip',
						'multipart/x-zip',
						'application/octet-stream',
					].includes(f.type) &&
					f.size > (mostLargeZipfile?.size ?? 0)
				) {
					mostLargeZipfile = f;
				}
			});
			handleLargestZipFile(mostLargeZipfile);
		}
	}, [disabled, handleLargestZipFile, uploadingFiles]);

	/**
	 * Create s3 upload manager for the given file
	 * @param {File} file
	 * @param {Object} s3Client
	 */
	const createUploadManager = (file, s3Client) => {
		let params = {
			Bucket: file.s3Bucket,
			Key: file.s3FileKey,
			Body: file,
			ContentType: file.type,
			ContentLength: file.size,
		};
		// NB: some uploading files are huge (could be 8GB), so uploading them as one single part could fail, hence using parts to upload
		let options = {
			partSize: 40 * 1024 * 1024,
			queueSize: 4,
		};

		let uploadMgr = s3Client.upload(params, options);
		return uploadMgr;
	};

	/**
	 * Worker of uploading queue
	 * @param {File} uploadManagedFile
	 * @param {Func} cb
	 */
	const uploadTask = (uploadManagedFile, cb) => {
		uploadManagedFile.uploadMgr.send(function (err, data) {
			if (err) {
				uploadManagedFile.uploadingError = new Error(err.message);
			} else {
				// uploadManagedFile.md5 = data.ETag.replace(/['"]+/g, '').substring(0, 32);
				uploadManagedFile.s3Url = `s3://${data.Bucket}/${data.Key}`;
				uploadManagedFile.progressValue = 100;
			}
			cb(null, uploadManagedFile);
		});
		uploadManagedFile.uploadMgr.on('httpUploadProgress', (progress) => {
			setUploadingFiles(
				uploadingFiles.map((file) => {
					if (file.s3FileKey === uploadManagedFile.s3FileKey) {
						file.progressValue = Math.round((progress.loaded / progress.total || file.size) * 100);
					}
					return file;
				})
			);
		});
	};

	/**
	 * Delete files from s3 bucket
	 * @param {string} bucket
	 * @param {array} fileKeys. Key of files that will be deleted. format: [{Key: file_key}, ...]. // ref: https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#deleteObjects-property
	 *
	 * @return {Promise}. s3.deleteObjects response data // ref: https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#deleteObjects-property
	 */
	const deleteFilesFromS3 = (bucket, fileKeys) => {
		return new Promise((_res, _rej) => {
			if (
				s3Credential &&
				new Date().getTime() + minDurationToS3CredExp * 1000 <
					new Date(s3Credential.Expiration).getTime()
			) {
				// use existing s3 credential
				_res(s3Credential);
			} else {
				fetchAWSCredential(S3_RESOURCE, ({ result, error }) => {
					if (error) _rej(error);
					else _res(result.data.credential);
				});
			}
		}).then((cred) => {
			let s3Client = createS3Client({
				accesskey: cred.AccessKeyId, // required
				secretkey: cred.SecretAccessKey, // required
				sessionToken: cred.SessionToken, // optional
				domain: domainName, // optional
			});

			// delete uploaded files from s3
			let params = {
				Bucket: s3Bucket,
				Delete: {
					Objects: fileKeys,
					Quiet: true,
				},
			};
			return s3Client.deleteObjects(params).promise();
		});
	};

	/**
	 * Initialize dropzone
	 */
	const {
		getRootProps,
		getInputProps,
		open: openSelectFiles, // function to open file section dialog
	} = useDropzone({
		...rest,
		onDrop: (acceptedFiles, fileRejections) => {
			let accepted = [];
			let currentTotalFileSize = uploadingFiles.reduce((accu, item) => accu + item.size, 0);
			// validate the accepted files
			acceptedFiles.forEach((file) => {
				if (
					uploadingFiles.some(
						(uploadingFile) =>
							uploadingFile.size === file.size &&
							uploadingFile.name === file.name &&
							uploadingFile.path === file.path
					)
				) {
					fileRejections.push({ file, errors: [DUPLICATED_FILE_ERROR] });
				} else if (uploadingFiles.length + accepted.length >= maxFiles) {
					fileRejections.push({ file, errors: [TOO_MANY_FILE_ERROR] });
				} else if (currentTotalFileSize + file.size > maxTotalSize) {
					fileRejections.push({ file, errors: [TOTAL_FILESIZE_TOO_LARGE_ERROR] });
				} else {
					currentTotalFileSize += file.size;
					accepted.push(file);
				}
			});

			setUploadingFiles(
				uploadingFiles.concat(
					accepted.map((f) => {
						// format of f.path: /download/xxxx/filename.ext
						let s3FilePath =
							f.path.lastIndexOf('/') !== -1
								? f.path.substring(1, f.path.lastIndexOf('/') + 1)
								: '';
						let s3Filename = useRandomFilename
							? `${genRandomStr(12)}.${f.name.split('.').pop()}`
							: f.name;
						return Object.assign(f, {
							s3Bucket: s3Bucket,
							s3FileKey: s3FilepathBase.substring(1) + s3FilePath + s3Filename,
							/**
							 * progressValue is the indicator of the status of file uploading.
							 * possible values:
							 * 	- null: added but not uploading,
							 * 	- 0: pending to upload (started, but not its turn yet),
							 *  - 100: completed,
							 * 	- 0-100: in the middle of uploading
							 */
							progressValue: null,
							previewClassname:
								// NOTE: file-icon-js lib doesn't have icon for .jpeg, hence we need to use filename of .jpg to get the icon (seems it is a bug in the lib)
								getFileIconClassName(f.name.endsWith('.jpeg') ? f.name + '.jpg' : f.name) ||
								classes.fallbackFilePreview,
						});
					})
				)
			);

			setRejectedFiles(
				rejectedFiles.concat(
					fileRejections.map((rejected) => {
						if (rejected.errors[0].code === INVALID_FILE_TYPE_DROPZON_CODE) {
							// use custom error for "invalid file type"
							rejected.errors[0] = INVALID_FILE_TYPE_ERROR;
						}
						return Object.assign(rejected.file, {
							error: rejected.errors[0] || { code: 'unknown error', message: 'unknown error' },
						});
					})
				)
			);
		},
	});

	let totalFileSize = readableFileSize(uploadingFiles.reduce((accu, item) => accu + item.size, 0));
	let numFilesUploaded = uploadingFiles.reduce((accu, file) => {
		if (file.uploadingError || file.s3Url) accu += 1;
		return accu;
	}, 0);

	return (
		<div className={cx(classes.root, className)}>
			{title && (
				<Typography variant="h4" noWrap={true} className={cx(classes.title, titleClassName)}>
					{title}
				</Typography>
			)}
			<section className={cx(classes.content, { [classes.stickyWrapper]: stickyDropzone })}>
				{/** Dropzone box */}

				<div className={classes.dropzoneContainer} {...getRootProps()}>
					<input {...getInputProps()} />
					<Typography variant="subtitle1">
						{intl.formatMessage({ id: 'components.S3Uploader.DragNDropMsg' })}
					</Typography>
					<Typography variant="body1" color="error">
						{rejectedFiles.length > 0
							? intl.formatMessage(
									{ id: 'components.S3Uploader.RejectedFilesMsg' },
									{ numOfRejectedFiles: rejectedFiles.length }
							  )
							: ''}
					</Typography>
					<Typography variant="body1" color="textPrimary">
						{uploadingFiles.length > 0
							? intl.formatMessage(
									{ id: 'components.S3Uploader.UploadingFilesMsg' },
									{ numUploadingFiles: uploadingFiles.length, totalFileSize }
							  )
							: ''}
					</Typography>
					{uploadQueue && (
						<CircularProgressLabelled
							value={Math.round((numFilesUploaded * 100) / uploadingFiles.length)}
							label={`${numFilesUploaded}/${uploadingFiles.length}`}
						/>
					)}
				</div>
				{/** Uploading tips */}
				<div className={classes.uploadTip}>
					<Typography variant="caption" color="textPrimary">
						{intl.formatMessage(
							{ id: 'components.S3Uploader.UploadingFilesTipMsg' },
							{ maxFiles, maxTotalSize: readableFileSize(maxTotalSize) }
						)}
					</Typography>
					<Link
						style={{ paddingLeft: 8 }}
						component="button"
						underline="always"
						variant="caption"
						onClick={(e) => setSupportedFilesListAnchor(e.target)}
					>
						{intl.formatMessage({ id: 'components.S3Uploader.SupportedFileListText' })}
					</Link>
				</div>

				<div className={classes.actionButtons}>
					<Button
						variant="contained"
						color={'primary'}
						disabled={disabled}
						className={classes.button}
						startIcon={<AddIcon fontSize="small" />}
						onClick={openSelectFiles}
					>
						{intl.formatMessage({ id: 'components.S3Uploader.ButtonTextSelectFiles' })}
					</Button>
					{
						<Button
							variant="contained"
							color={'primary'}
							disabled={disabled}
							className={classes.button}
							startIcon={<ResetIcon fontSize="small" />}
							onClick={() => {
								setRejectedFiles([]);
								setUploadingFiles([]);
							}}
						>
							{intl.formatMessage({ id: 'GENERAL.Reset' })}
						</Button>
					}
					{uploadingFiles.length === 0 ? null : !uploadQueue ? (
						<Button
							variant="contained"
							color={'primary'}
							disabled={disabled}
							className={classes.button}
							startIcon={<UploadIcon fontSize="small" />}
							onClick={() => {
								new Promise((_res, _rej) => {
									if (
										s3Credential &&
										new Date().getTime() + minDurationToS3CredExp * 1000 <
											new Date(s3Credential.Expiration).getTime()
									) {
										// use existing s3 credential
										_res(s3Credential);
									} else {
										fetchAWSCredential(S3_RESOURCE, ({ result, error }) => {
											if (error) _rej(error);
											else _res(result.data.credential);
										});
									}
								})
									.then((cred) => {
										/** main function to upload files in a queue */

										let s3Client = createS3Client({
											accesskey: cred.AccessKeyId, // required
											secretkey: cred.SecretAccessKey, // required
											sessionToken: cred.SessionToken, // optional
											domain: domainName, // optional
										});

										// initialize file's uploading state
										let uploadManagedFiles = uploadingFiles.map((file) => {
											delete file.uploadingError; // in case the file was uploaded and failed
											delete file.uploadMgr; // delete previous upload manager if any
											// in case the file was uploaded successfully, we don't need to upload it again
											if (file.progressValue !== 100) {
												file.uploadMgr = createUploadManager(file, s3Client);
												file.progressValue = 0;
											}
											return file;
										});

										// Create uploading queue
										let queue = asyncQueue(uploadTask, concurrency);
										// push accepted files to uploading queue
										queue.push(
											// only upload the files which were not uploaded
											uploadManagedFiles.filter((f) => f.progressValue !== 100),
											(err, uploadedFile) => {
												// handle uploaded file. err is always null because the callback in worker function "uploadTask" never return err
												setUploadingFiles(
													uploadingFiles.map((file) => {
														if (file.s3FileKey === uploadedFile.s3FileKey) {
															return uploadedFile;
														}
														return file;
													})
												);
											}
										);
										// set handler func when all files are uploaded (no matter succeed or failed)
										queue.drain(() => {
											setUploadQueue(null);

											// function to reset files to their initial state
											const resetFileInitState = () => {
												// reset the files to their initial state by deleting uploading status
												setUploadingFiles(
													uploadingFiles.map((file) => {
														delete file.uploadingError;
														delete file.s3Url;
														delete file.uploadMgr;
														file.progressValue = null;
														return file;
													})
												);
											};
											let resultFiles = { failedFiles: [], completedFiles: [] };

											uploadingFiles.forEach((f) => {
												if (f.s3Url) {
													resultFiles.completedFiles.push({
														name: f.name,
														path: f.path,
														type: f.type,
														size: f.size,
														s3Url: f.s3Url,
													});
												} else {
													resultFiles.failedFiles.push({
														name: f.name,
														path: f.path,
														type: f.type,
														size: f.size,
														error: f.uploadingError || new Error(`Unknown failure`), // 'Unknown failure' is very rare to happen, but have it here to ensure that error always has value
													});
												}
											});

											if (resultFiles.completedFiles.length === 0) {
												// no file was uploaded successfully
												notifyGeneral(
													'Can not upload the files, please check your internet connection and try again later.',
													'error'
												);
												// set the files to their initial state by deleting uploading status
												resetFileInitState();
											} else if (resultFiles.failedFiles.length > 0) {
												switch (actionOnFailure) {
													case 'CONFIRM':
														setOpenDialog({ type: 'CONFIRM', open: true });
														break;
													case 'CANCEL':
														notifyGeneral('Some of the files can not be uploaded.', 'error');
														break;
													case 'CANCEL_AND_DELETE':
														notifyGeneral(
															'Can not upload the files, please try again later.',
															'error'
														);
														// delete uploaded files from s3
														deleteFilesFromS3(
															s3Bucket,
															resultFiles.completedFiles.map((f) => {
																let key = f.s3Url.replace(`s3://${s3Bucket}/`, '');
																return { Key: key };
															})
														).catch((err) => {
															// NOTE: we ignore the error here, it is very rare and also leaving files in s3 without deleting is alright here
															console.debug(err);
															return null;
														});
														// reset the files to their initial state by deleting uploading status (no matter the files were deleted successfully or not)
														resetFileInitState();
														break;
													case 'CONTINUE':
														handleFileUploadComplete(resultFiles);
														break;
													default:
														break;
												}
											} else {
												handleFileUploadComplete(resultFiles);
											}
											// console.log('all files are uploaded');
											// kill (remove) the queue to free memory. Is it necessary?
											// queue.kill();
										});

										// // use setTimeout to update local state to ensure queue started before loal state update
										// setTimeout(() => {
										// 	// update local state
										// 	setUploadingFiles(uploadManagedFiles);
										// 	setUploadQueue(queue);
										// }, 100);

										// sometime, queued uploading can be finished less than 100ms,
										// so uploadQueue was set to null first, then it is set to the "queue", hence causing problem
										// so we need to setUploadQueue immediately (compare to using setTimeout approach above)
										setUploadingFiles(uploadManagedFiles);
										setUploadQueue(queue);
									})
									.catch((err) => {
										// we ignore the error as it is handled by redux notifier
										console.debug(err);
										return null;
									});
							}}
						>
							{intl.formatMessage({ id: 'components.S3Uploader.ButtonTextStartUpload' })}
						</Button>
					) : (
						<Button
							variant="contained"
							color={'primary'}
							// disabled={disabled}
							className={classes.button}
							startIcon={<CancelIcon fontSize="small" />}
							onClick={() => {
								// some files may not start uploading, hence file.uploadMgr.callback is null,
								// means we can't call file.uploadMgr.abort() to cancel upload, neither the queue callback can be triggered at all
								// so what we do here is removing these non-started files from queue and update its state by adding an uploadingError
								// Note, this must be done before calling abort(), otherwise queu may be drained before updating the state
								setUploadingFiles(
									uploadingFiles.map((file) => {
										if (file.uploadMgr && !file.uploadMgr.callback) {
											// Removing the file from queue,
											uploadQueue.remove(({ data }) => data.s3FileKey === file.s3FileKey);
											// the file's queue callback will not be triggered, we need to set its uploadingError here
											file.uploadingError = new Error(`Upload cancelled`);
										} else if (
											file.uploadMgr &&
											file.uploadMgr.callback &&
											!file.uploadingError &&
											file.progressValue !== 100
										) {
											// abort the file uploading so that the file's queue callback can be triggered
											file.uploadMgr.abort();
										}
										return file;
									})
								);
							}}
						>
							{intl.formatMessage({ id: 'components.S3Uploader.ButtonTextCancelUpload' })}
						</Button>
					)}
				</div>
			</section>
			{/** files list (container) */}
			<div className={classes.filesContainer}>
				<List className={classes.filelistRoot}>
					{/**############# subheader of rejected files  ###################*/}
					{rejectedFiles.length > 0 && (
						<ListSubheader className={classes.rejectedListHeader} disableSticky>
							<span>
								{intl.formatMessage({ id: 'components.S3Uploader.RejectedFilesSectionTitle' })}
							</span>
							<Tooltip
								title={intl.formatMessage({
									id: 'components.S3Uploader.RemoveRejectedFilesTooltip',
								})}
							>
								<IconButton edge="end" aria-label="remove-all" onClick={() => setRejectedFiles([])}>
									<RemoveAllIcon />
								</IconButton>
							</Tooltip>
						</ListSubheader>
					)}
					{
						/** rejected files list */
						rejectedFiles.map((rejectedFile, idx) => {
							return (
								<ListItem key={rejectedFile.name + '-' + idx} dense onClick={() => null}>
									<div className={cx(classes.warnFilePreview, classes.filePreview)}></div>

									<ListItemText
										title={rejectedFile.path}
										primary={rejectedFile.path}
										primaryTypographyProps={{ noWrap: true }}
										secondary={`${readableFileSize(rejectedFile.size)}`}
									/>
									<ListItemText
										className={classes.infoListItemText}
										primaryTypographyProps={{ color: 'error', variant: 'subtitle2' }}
										primary={`Error: ${rejectedFile.error.message}`}
									/>
								</ListItem>
							);
						})
					}
					{/**##################### subheader of accepted files #######################*/}
					{uploadingFiles.length > 0 && (
						<ListSubheader disableSticky>
							{intl.formatMessage({
								id: 'components.S3Uploader.AcceptedFilesSectionTitle',
							})}
						</ListSubheader>
					)}
					{
						/** accepted files list */
						uploadingFiles.map((file, idx) => {
							return (
								<ListItem key={file.name + '-' + idx} dense>
									{file.previewClassname && (
										<div className={cx(file.previewClassname, classes.filePreview)}></div>
									)}
									<ListItemText
										title={file.path}
										primary={file.path}
										primaryTypographyProps={{ noWrap: true }}
										secondary={readableFileSize(file.size)}
									/>
									{!file.uploadingError && typeof file.progressValue === 'number' && (
										<div className={classes.infoListItemText}>
											<LinearProgress
												className={classes.progressBar}
												variant="determinate"
												value={file.progressValue}
											/>
											<Typography component="span" style={{ paddingLeft: 8 }}>
												{file.progressValue === 0 ? 'Pending...' : `${file.progressValue}%`}
											</Typography>
										</div>
									)}
									{file.uploadingError && (
										<div className={classes.infoListItemText}>
											<Typography
												component="span"
												className={classes.uploadError}
												style={{ paddingLeft: 8 }}
											>
												{intl.formatMessage(
													{ id: 'components.S3Uploader.UploadingErrorMsg' },
													{ errorMsg: file.uploadingError.message }
												)}
											</Typography>
										</div>
									)}
									<ListItemSecondaryAction>
										{typeof file.progressValue !== 'number' && (
											<Tooltip title={intl.formatMessage({ id: 'GENERAL.Remove' })}>
												<IconButton
													edge="end"
													aria-label="remove"
													onClick={() => {
														let currentFiles = uploadingFiles.slice();
														currentFiles.splice(idx, 1);
														setUploadingFiles(currentFiles);
													}}
												>
													<RemoveIcon />
												</IconButton>
											</Tooltip>
										)}
										{file.uploadingError ? (
											<ErrorIcon className={classes.uploadError} />
										) : file.progressValue === 100 ? (
											<DoneIcon className={classes.uploadCompleted} />
										) : null}
									</ListItemSecondaryAction>
								</ListItem>
							);
						})
					}
				</List>
			</div>
			{/** popover of supported file list */}
			<Popover
				open={Boolean(supportedFilesListAnchor)}
				anchorEl={supportedFilesListAnchor}
				onClose={() => setSupportedFilesListAnchor(null)}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'left',
				}}
			>
				<div
					style={{
						width: 300,
						display: 'flex',
						flexDirection: 'row',
						flexWrap: 'wrap',
						justifyContent: 'space-between',
						alignItems: 'flex-start',
						alignContent: 'flex-start',
						margin: 20,
					}}
				>
					{accept.split(',').map((filetype) => {
						return (
							<span key={filetype} style={{ width: '20%', padding: 4 }}>
								{filetype.trim().toLowerCase()}
							</span>
						);
					})}
				</div>
			</Popover>
			{/** Dialog */}
			<Dialog open={openDialog.open} fullWidth maxWidth="sm">
				{
					<DialogTitle>
						{openDialog.type === 'CONFIRM'
							? intl.formatMessage({ id: 'components.S3Uploader.ChooseActionDialogTitle' })
							: ''}
					</DialogTitle>
				}
				<DialogContent>
					{openDialog.type === 'CONFIRM' && (
						<React.Fragment>
							<Typography>
								{intl.formatMessage({ id: 'components.S3Uploader.ConfimationText1' })}
							</Typography>
							<ul className={classes.uploadError}>
								{uploadingFiles.map((file, idx) => {
									if (file.uploadingError) {
										return (
											<li key={file.path + '-' + idx}>
												<span>{file.path}: </span>
												<span style={{ fontSize: '0.9em' }}>{file.uploadingError.message}</span>
											</li>
										);
									} else {
										return null;
									}
								})}
							</ul>
							<Typography variant="body2">
								{intl.formatMessage({ id: 'components.S3Uploader.ConfimationText2' })}
							</Typography>
						</React.Fragment>
					)}
				</DialogContent>

				{openDialog.type === 'CONFIRM' && (
					<DialogActions>
						<Button
							variant="contained"
							onClick={() => {
								// delete uploaded files from s3
								deleteFilesFromS3(
									s3Bucket,
									uploadingFiles
										.map((f) => {
											if (!f.uploadingError && f.s3Url) {
												let key = f.s3Url.replace(`s3://${s3Bucket}/`, '');
												return { Key: key };
											} else {
												return null;
											}
										})
										.filter((f) => f)
								).catch((err) => {
									// NOTE: we ignore the error here, it is very rare and also leaving files in s3 without deleting is alright here
									console.debug(err);
									return null;
								});

								setOpenDialog({ type: '', open: false });
								// reset the files to their initial state by deleting uploading status (no matter the files were deleted successfully or not)
								setUploadingFiles(
									uploadingFiles.map((file) => {
										delete file.uploadingError;
										delete file.s3Url;
										delete file.uploadMgr;
										file.progressValue = null;
										return file;
									})
								);
							}}
						>
							{intl.formatMessage({ id: 'GENERAL.Cancel' })}
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								let resultFiles = { failedFiles: [], completedFiles: [] };

								uploadingFiles.forEach((f) => {
									if (f.uploadingError) {
										resultFiles.failedFiles.push({
											name: f.name,
											path: f.path,
											type: f.type,
											size: f.size,
											error: f.uploadingError,
										});
									} else {
										resultFiles.completedFiles.push({
											name: f.name,
											path: f.path,
											type: f.type,
											size: f.size,
											s3Url: f.s3Url,
										});
									}
								});
								handleFileUploadComplete(resultFiles);
								setOpenDialog({ type: '', open: false });
							}}
						>
							{intl.formatMessage({ id: 'GENERAL.Continue' })}
						</Button>
					</DialogActions>
				)}
			</Dialog>
		</div>
	);
}

S3Uploader.propTypes = {
	/**
	 * css class name of root container <div>
	 * NOTE: if you specify "height", you must handle scrolling by specify "overflow"
	 */
	className: PropTypes.string,

	/**
	 * if true, dropzone area is sticky. Default: true
	 */
	stickyDropzone: PropTypes.bool,

	/**
	 * title text. If not available, <div> of title will not be rendered
	 */
	title: PropTypes.string,
	/**
	 * css class name of title wrapper <div>
	 */
	titleClassName: PropTypes.string,

	/**
	 * if true, the preview of file will be rendered
	 */
	// enablePreview: PropTypes.bool,

	/**
	 * Maximum combined file size (in bytes)
	 */
	maxTotalSize: PropTypes.number,

	/**
	 * name of s3 bucket where the uploaded files go into
	 */
	s3Bucket: PropTypes.string.isRequired,

	/**
	 * the base path where the files are uploaded. (Require leading & trailing slash)
	 * In case of folder uploading, the files in a folder will have their relative path to the folder,
	 * the file's relative path is attached to this base path
	 *
	 * For example:
	 * 			s3Bucket: visualid-mediafiles
	 * 			s3FilepathBase: /spar/20201201/basepath/
	 * 			useRandomFilename: true
	 * 		the final file s3 url:
	 * 				- not folder uploading: s3://visualid-mediafiles/spar/20201201/basepath/randomfilename-ersdfsaf.jpg
	 * 				- folder uploading, file relative path is "/marketing/christmas/": s3://visualid-mediafiles/spar/20201201/basepath/marketing/christmas/randomfilename-ersdfsaf.jpg
	 *
	 */
	s3FilepathBase: PropTypes.string.isRequired,

	/**
	 * If true, the uploaded files will use random file name rather than original name.
	 * default: true
	 */
	useRandomFilename: PropTypes.bool,

	/**
	 * handle the largest zip file whenever user's uploading file changed
	 * @param {object|null} file object. null or {size: xxxx, path: 'xxxx', type: 'xxxx', ...}.
	 * 		NB: It could be null if no zip file at all; could be same as last found largest zip file
	 */
	handleLargestZipFile: PropTypes.func,

	/**
	 * An integer for determining how many file uploading can be run in parallel. Default: 2
	 */
	concurrency: PropTypes.number,

	/**
	 * pre-defined actions on uploading faulure (as long as one file fails to upload)
	 *
	 * - CONFIRM: confirmation dialog with "cancel" & "continue" actions for user to choose. "cancel" is same as CANCEL_AND_DELETE action except notifying error message; "continue" is same as CONTINUE action;
	 * - CANCEL: stop and notify user about faulure (keep the files on the page with error message), no further callback action
	 * - CANCEL_AND_DELETE: stop, notify user about faulure, reset files to initial state and delete any uploaded files from s3, no further callback action
	 * - CONTINUE: continue with callback funtion
	 */
	actionOnFailure: PropTypes.oneOf(['CONFIRM', 'CANCEL', 'CANCEL_AND_DELETE', 'CONTINUE']),

	/**
	 * Handler of file uploading completion
	 * @param {object}
	 	{
			 failedFiles: [
				 {
					 name: 'xxx', // filename
					 path: '/xxx/xxx', // file path if folder drop
					 type: 'xxxx/xxx', // file mime type
					 size: Number,
					 error: Error
				 },
				 ...
			 ],
			 completedFiles:[
				 {
					 name: 'xxx', // filename
					 path: '/xxx/xxx', // file path if folder drop
					 type: 'xxxx/xxx', // file mime type
					 size: Number,
					 s3Url: 's3://xxxx/xxxx/xxx', // s3 url
				 },
				 ...
			 ]
		 }
	 */
	handleFileUploadComplete: PropTypes.func.isRequired,

	// redux actions & props
	fetchAWSCredential: PropTypes.func.isRequired,
	resetAWSCredential: PropTypes.func.isRequired,
	notifyGeneral: PropTypes.func.isRequired,
	s3Credential: PropTypes.object,
	domainName: PropTypes.string.isRequired,

	/////////////// below are react-dropzone props (ref: https://react-dropzone.js.org/#src) /////////////////
	/**
	 * accepted file types
	 * Ref: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#Unique_file_type_specifiers
	 */
	accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),

	/**
	 * Enable/disable the dropzone
	 */
	disabled: PropTypes.bool,

	/**
	 * Allow drag 'n' drop (or selection from the file dialog) of multiple files
	 */
	multiple: PropTypes.bool,

	/**
	 * If true, disables click to open the native file selection dialog
	 */
	noClick: PropTypes.bool,

	/**
	 * If true, disables SPACE/ENTER to open the native file selection dialog.
	 * Note that it also stops tracking the focus state.
	 */
	noKeyboard: PropTypes.bool,

	/**
	 * If true, disables drag 'n' drop
	 */
	noDrag: PropTypes.bool,

	/**
	 * Minimum file size (in bytes)
	 */
	minSize: PropTypes.number,

	/**
	 * Maximum individual file size (in bytes)
	 */
	maxSize: PropTypes.number,
	/**
	 * Maximum accepted number of files
	 * The default value is 0 which means there is no limitation to how many files are accepted.
	 */
	maxFiles: PropTypes.number,
};

S3Uploader.defaultProps = {
	className: null,
	stickyDropzone: true,
	title: null,
	titleClassName: null,
	// rootStyles: {},
	// enablePreview: true,
	maxTotalSize: 2.5 * 1024 * 1024 * 1024, // 2.5GB. Max total combined size of files
	useRandomFilename: true,
	concurrency: 2,
	actionOnFailure: 'CONFIRM',
	// below are react-dropzone props
	accept: config.supportedFileTypes, // 'image/*, video/*, audio/*, application/*', //  application/*, 'image/jpeg, image/png', '/(\.|\/)(3gp|ai|aif|avi|bmp|doc|docx|eps|epsf|gif|jpeg|jpg|m4v|mov|mp3|mp4|mpeg|mpg|odt|pdf|png|ppt|pptx|ps|psd|swf|tif|tiff|wav|wmv|xls|xlsx|zip)$/i'
	disabled: false,
	maxSize: 2.5 * 1024 * 1024 * 1024, // 2.5GB. Max individual file size
	minSize: 0,
	multiple: true,
	maxFiles: 50, // max number of files to upload
	noClick: false,
	noKeyboard: true,
	noDrag: false,
};

const mapStateToProps = (state) => {
	return {
		s3Credential: state.awsResource.credentials.s3,
		domainName: state.authentication.domainName,
	};
};

export default connect(mapStateToProps, {
	fetchAWSCredential,
	resetAWSCredential,
	notifyGeneral,
})(S3Uploader);
