/* eslint-disable react/no-string-refs */
import React from 'react';

import PropTypes from 'prop-types';

import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { InputLabel, InputAdornment, SvgIcon, Tooltip, Typography } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import { _ } from 'utils/libHelper';
import {
	StyledOutlinedTextField,
	SimpleSwitchRow,
	SimplifiedOutlinedTextField,
	StyledOutlinedTextFieldSelection,
	StyledOutlinedSelectionWithSubheader,
	SimpleNumberSlider,
	OutlinedNumberTextField,
	DividerHor,
} from '../../CustomMUI/CustomMUI';
import { CreatableSelection } from 'components';
import { ReactComponent as InsertionOnVideoExplainSvg } from './insertionOnVideo-explain.svg';

// intl lang
import { useIntl } from 'react-intl';

// Constants
import { ART_VARIABLES, DesignContext } from '../../../Constants';
// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
	generalTabWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		backgroundColor: 'inherit',
	},
	creatableSelectionContainer: {
		margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
	},
	groupsDiv: {
		margin: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
		width: `calc(100% - calc(${theme.spacing(2)} * 2))`,
		color: 'inherit',
		display: 'flex',
		// fontSize: '0.8rem',
		alignItems: 'center',
		flexDirection: 'column',
		borderColor: 'rgba(0, 0, 0, 0.23)',
		border: '1px solid',
		borderRadius: 4,
		'&:hover': {
			borderColor: 'rgba(0, 0, 0, 1)',
		},
	},
	groupTitle: {
		alignSelf: 'flex-start',
		padding: '0px 4px',
		background: 'white',
		marginBottom: -theme.spacing(1),
	},
	groupRowDiv: {
		width: '100%',
		padding: `0px ${theme.spacing(2)}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	selectMenuItem: {
		fontSize: 'inherit',
	},
	listSubheaderGrouping: {
		fontWeight: 800,
		// fontSize: '1rem',
		paddingLeft: theme.spacing(1),
	},
	twoColumnsRowDiv: {
		justifyContent: 'space-between',
		padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
	},
	labelColumn: {
		paddingRight: 8,
		width: 90,
		textAlign: 'left',
		display: 'flex',
		alignItems: 'center',
	},
	valueColumn: {
		width: `calc(100% - 90px)`,
		display: 'inline-flex',
		alignItems: 'center',
	},
	denseTextbox: {
		// width: '100%',
		margin: 0,
		fontSize: 'inherit',
		color: 'inherit',
	},
	insertionExplain: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		margin: theme.spacing(1, 0),
	},
}));

function GeneralTab({
	field,
	templateFields,
	updateField,
	handleNewGroup,
	// handleDelGroup,
	groups,
	isVideoArtwork,
	// zoom,
	...rest
}) {
	const classes = useStyles();
	const intl = useIntl();
	const designContext = React.useContext(DesignContext);
	const groupedTemplateFields = {};
	templateFields.forEach((f) => {
		// if (f.id !== field.id) {
		if (!groupedTemplateFields[f.groupName]) {
			groupedTemplateFields[f.groupName] = [];
		}
		groupedTemplateFields[f.groupName].push({
			value: f.id,
			label: f.name,
			disabled: f.id === field.id,
		});
		// }
	});
	return (
		<div className={classes.generalTabWrapper}>
			<StyledOutlinedTextField
				label={intl.formatMessage({
					id: 'pages.Artwork.components.ElementPalette.GENERAL.labelText',
				})}
				required
				value={field.name || ''}
				onChange={(e) => updateField({ name: e.target.value || '' })}
			/>
			{
				<StyledOutlinedTextField
					label={intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.GENERAL.displayOrderText',
					})}
					disabled
					value={typeof field.displayOrder === 'number' ? field.displayOrder : ''}
				/>
			}
			<CreatableSelection
				label={intl.formatMessage({
					id: 'pages.Artwork.components.ElementPalette.GENERAL.groupText',
				})}
				value={field.groupName || ''}
				options={groups.map((groupName) => ({ value: groupName, label: groupName }))}
				isClearable={false}
				required
				outlined
				className={classes.creatableSelectionContainer}
				// handleDelOption={handleDelGroup}
				handleCreation={(groupName) => {
					handleNewGroup(groupName);
					updateField({ groupName: groupName || '' });
				}}
				handleSelection={(groupName) => {
					updateField({ groupName: groupName || '' });
				}}
			/>
			<StyledOutlinedTextField
				label={intl.formatMessage({
					id: 'pages.Artwork.components.ElementPalette.GENERAL.helpTextLabel',
				})}
				multiline
				rows={3}
				value={field.helperText || ''}
				onChange={(e) => updateField({ helperText: e.target.value || '' })}
			/>
			<div className={classes.groupsDiv}>
				<InputLabel shrink variant="outlined" className={classes.groupTitle}>
					{intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.GENERAL.controlsText',
					})}
				</InputLabel>
				<SimpleSwitchRow
					label={intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.GENERAL.hideInputText',
					})}
					checked={Boolean(field.hideInput)}
					onChange={(e, checked) => updateField({ hideInput: checked })}
				/>
				<DividerHor />
				<SimpleSwitchRow
					label={intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.GENERAL.hideOutputText',
					})}
					checked={Boolean(field.hideOutput)}
					onChange={(e, checked) => updateField({ hideOutput: checked })}
				/>
				<DividerHor />
				<div className={cx(classes.groupRowDiv)}>
					<span className={classes.labelColumn}>
						{intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.outputDependsOnText',
						})}
					</span>
					<div className={classes.valueColumn}>
						<StyledOutlinedSelectionWithSubheader
							fullWidth
							select
							label=""
							value={field.outputDependsOn || ''}
							onChange={(e) => updateField({ outputDependsOn: e.target.value || '' })}
							noneOption
							options={groupedTemplateFields}
						/>
					</div>
				</div>
			</div>
			<div className={classes.groupsDiv}>
				<InputLabel shrink variant="outlined" className={classes.groupTitle}>
					{intl.formatMessage({
						id: 'pages.Artwork.components.ElementPalette.GENERAL.positionText',
					})}
				</InputLabel>

				{field.type === 'image' && (
					<>
						<SimpleSwitchRow
							style={{ padding: `8px 8px` }}
							label={intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.GENERAL.allowRepositionText',
							})}
							checked={Boolean(field.imageRepositioning)}
							onChange={(e, checked) => updateField({ imageRepositioning: checked })}
						/>
						<DividerHor />
					</>
				)}
				{[
					{
						label: intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.horXText',
						}),
						posKey: 'left',
					},
					{
						label: intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.verYText',
						}),
						posKey: 'top',
					},
					{
						label: intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.widthText',
						}),
						posKey: 'width',
						min: 10,
					},
					{
						label: intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.heightText',
						}),
						posKey: 'height',
						min: 10,
					},
				]
					.map((item) => (
						<div key={item.posKey} className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
							<span className={classes.labelColumn}>{item.label}</span>
							<div className={classes.valueColumn}>
								<OutlinedNumberTextField
									fullWidth
									className={classes.denseTextbox}
									label=""
									value={
										typeof field.position[item.posKey] === 'number'
											? (field.position[item.posKey].toString().split('.')[1] || '').length > 2
												? field.position[item.posKey].toFixed(2)
												: field.position[item.posKey]
											: field.position[item.posKey] || ''
									}
									step={1}
									min={item.min}
									handleOnChange={(num) =>
										updateField({
											position: {
												...field.position,
												[item.posKey]: num,
											},
										})
									}
								/>
							</div>
						</div>
					))
					.reduce(
						(accu, ele, idx) =>
							accu.length === 0 ? [ele] : [...accu, <DividerHor key={idx} />, ele],
						[]
					)}
				<DividerHor />
				<div className={cx(classes.groupRowDiv, classes.twoColumnsRowDiv)}>
					<SimpleNumberSlider
						label={intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.rotationText',
						})}
						value={field.position.angle}
						handleOnChange={(angle) => {
							// if (angle === 360) angle = 0;
							updateField({ position: { ...field.position, angle: angle } });
						}}
						step={1}
						min={0}
						max={359}
					/>
				</div>
			</div>
			{!isVideoArtwork && (
				<div className={classes.groupsDiv}>
					<InputLabel shrink variant="outlined" className={classes.groupTitle}>
						{intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.animationTitle',
						})}
					</InputLabel>
					<div className={cx(classes.groupRowDiv)}>
						<span className={classes.labelColumn}>
							{intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.GENERAL.animationEntranceText',
							})}
						</span>
						<div className={classes.valueColumn}>
							<StyledOutlinedSelectionWithSubheader
								fullWidth
								select
								label=""
								value={field.animation.entrance || ''}
								onChange={(e) =>
									updateField({
										animation: {
											...field.animation,
											entrance: e.target.value || '',
											delay:
												e.target.value || field.animation.exit
													? field.animation.delay || ART_VARIABLES.DEFAULT_ANIMATION_DELAY
													: null,
											duration:
												e.target.value || field.animation.exit
													? field.animation.duration || ART_VARIABLES.DEFAULT_ANIMATION_DURATION
													: null,
										},
									})
								}
								noneOption
								options={ART_VARIABLES.animationEntranceOptions}
							/>
						</div>
					</div>
					<DividerHor />
					<div className={cx(classes.groupRowDiv)}>
						<span className={classes.labelColumn}>
							{intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.GENERAL.animationDelayAfterText',
							})}
						</span>
						<div className={classes.valueColumn}>
							<StyledOutlinedTextFieldSelection
								select
								label=""
								value={
									field.animation && typeof field.animation.delay === 'number'
										? field.animation.delay.toFixed(1)
										: ''
								}
								onChange={(e) =>
									updateField({
										animation: {
											...field.animation,
											delay: Number(e.target.value),
										},
									})
								}
								options={_.range(0.0, 10.1, 0.1).map((delay) => ({
									label: `${delay.toFixed(1)}s`,
									value: delay.toFixed(1),
								}))}
							/>
						</div>
					</div>
					<DividerHor />
					<div className={cx(classes.groupRowDiv)}>
						<span className={classes.labelColumn}>
							{intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.GENERAL.animationDurationText',
							})}
						</span>
						<div className={classes.valueColumn}>
							<StyledOutlinedTextFieldSelection
								select
								fullWidth
								label=""
								value={
									field.animation && typeof field.animation.duration === 'number'
										? field.animation.duration.toFixed(1)
										: ''
								}
								onChange={(e) =>
									updateField({
										animation: {
											...field.animation,
											duration: Number(e.target.value),
										},
									})
								}
								options={_.range(0.0, 10.1, 0.1).map((duration) => ({
									label: `${duration.toFixed(1)}${intl.formatMessage({
										id: 'pages.Artwork.components.GENERAL.secondShort',
									})}`,
									value: duration.toFixed(1),
								}))}
							/>
						</div>
					</div>
					<DividerHor />
					<div className={cx(classes.groupRowDiv)}>
						<span className={classes.labelColumn}>
							{intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.GENERAL.animationExitText',
							})}
						</span>
						<div className={classes.valueColumn}>
							<StyledOutlinedSelectionWithSubheader
								fullWidth
								select
								label=""
								value={field.animation && field.animation.exit ? field.animation.exit : ''}
								onChange={(e) =>
									updateField({
										animation: {
											...field.animation,
											exit: e.target.value || '',
											delay:
												e.target.value || field.animation.entrance
													? field.animation.delay || ART_VARIABLES.DEFAULT_ANIMATION_DELAY
													: null,
											duration:
												e.target.value || field.animation.entrance
													? field.animation.duration || ART_VARIABLES.DEFAULT_ANIMATION_DURATION
													: null,
										},
									})
								}
								noneOption
								options={ART_VARIABLES.animationExitOptions}
							/>
						</div>
					</div>
				</div>
			)}
			{isVideoArtwork && ART_VARIABLES.supportedFieldTypesInVideoArtwork.includes(field.type) && (
				<div className={classes.groupsDiv}>
					<InputLabel shrink variant="outlined" className={classes.groupTitle}>
						{intl.formatMessage({
							id: 'pages.Artwork.components.ElementPalette.GENERAL.insertionOnVideoTitle',
						})}
					</InputLabel>
					<div className={cx(classes.groupRowDiv)}>
						<div className={classes.insertionExplain}>
							<SvgIcon
								component={InsertionOnVideoExplainSvg}
								viewBox="0 0 214 116"
								style={{ width: '90%', height: 130 }}
							/>
						</div>
					</div>
					<DividerHor />
					<div className={cx(classes.groupRowDiv)}>
						<span className={classes.labelColumn}>
							{intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.GENERAL.insertionOnVideoStartTimeText',
							})}
							<Tooltip
								arrow
								style={{ marginLeft: 4 }}
								placement="top"
								enterDelay={500}
								title={
									<div>
										<Typography variant="body1">{`Format: HH:MM:SS:FF (hour:min:sec:NoOfFrames)`}</Typography>
										<Typography paragraph variant="body2">
											{`The timecode of the insertion that you want the overlay to first appear on`}
										</Typography>
									</div>
								}
							>
								<InfoIcon fontSize="small" />
							</Tooltip>
						</span>
						<div className={classes.valueColumn}>
							<SimplifiedOutlinedTextField
								label={null}
								// className={classes.dialogContentTextField}
								fullWidth={true}
								placeholder="HH:MM:SS:FF"
								onBlur={(e) => {
									let regex = new RegExp(/^(?:[\d]{2}):(?:[\d]{2}):(?:[\d]{2}):(?:[\d]{2})$/);
									if (e.target.value & !regex.test(e.target.value)) {
										designContext.notifyGeneral &&
											designContext.notifyGeneral(
												`${intl.formatMessage({
													id:
														'pages.Artwork.components.ElementPalette.GENERAL.insertionOnVideoStartTimeWrongValueMsg',
												})}`,
												'error'
											);
									} else {
										updateField({
											insertionOnVideo: {
												...(field.insertionOnVideo || {}),
												startTime: e.target.value,
											},
										});
									}
								}}
								value={field.insertionOnVideo ? field.insertionOnVideo.startTime : ''}
							/>
						</div>
					</div>
					<DividerHor />
					{[
						{
							label: intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.GENERAL.insertionOnVideoDurationText',
							}),
							insertionOnVideoPropKey: 'duration',
						},
						{
							label: intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.GENERAL.insertionOnVideoFadeInText',
							}),
							insertionOnVideoPropKey: 'fadeIn',
						},
						{
							label: intl.formatMessage({
								id: 'pages.Artwork.components.ElementPalette.GENERAL.insertionOnVideoFadeOutText',
							}),
							insertionOnVideoPropKey: 'fadeOut',
						},
					]
						.map((item, idx) => {
							return (
								<div key={`insertionOnVideo-UI-field-${idx}`} className={cx(classes.groupRowDiv)}>
									<span className={classes.labelColumn}>{item.label}</span>
									<div className={classes.valueColumn}>
										<SimplifiedOutlinedTextField
											label={null}
											// className={classes.dialogContentTextField}
											fullWidth={true}
											inputProps={{ step: 1, min: 1, type: 'number' }}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end" disablePointerEvents disableTypography>
														{intl.formatMessage({ id: 'GENERAL.MilliSecond' })}
													</InputAdornment>
												),
											}}
											onBlur={(e) => {
												let val = Number(e.target.value);
												if (!Number.isFinite(val) || val < 0) {
													designContext.notifyGeneral &&
														designContext.notifyGeneral(
															`${intl.formatMessage({ id: 'GENERAL.GreaterZeroErrorMsg' })}`,
															'error'
														);
												} else {
													updateField({
														insertionOnVideo: {
															...(field.insertionOnVideo || {}),
															[item.insertionOnVideoPropKey]: val,
														},
													});
												}
											}}
											value={
												field.insertionOnVideo
													? field.insertionOnVideo[item.insertionOnVideoPropKey] || 0
													: 0
											}
										/>
									</div>
								</div>
							);
						})
						.reduce(
							(accu, ele, idx) =>
								accu.length === 0
									? [ele]
									: [...accu, <DividerHor key={`insertionOnVideo-UI-divider-${idx}`} />, ele],
							[]
						)}
				</div>
			)}
		</div>
	);
}

GeneralTab.propTypes = {
	field: PropTypes.object.isRequired,
	updateField: PropTypes.func.isRequired,
	handleNewGroup: PropTypes.func.isRequired,
	// handleDelGroup: PropTypes.func.isRequired,
	groups: PropTypes.array.isRequired,
	// zoom: PropTypes.number.isRequired, // the current zoom. e.g. 0.1, 0.25, 1, 1.35, 2, etc.
	templateFields: PropTypes.array.isRequired,
	isVideoArtwork: PropTypes.bool.isRequired,
};

GeneralTab.defaultProps = {};
export default GeneralTab;
