// ##############################
// // // Constants of filters
// #############################
import {
	addDays,
	endOfDay,
	startOfDay,
	startOfMonth,
	startOfWeek,
	subMonths,
	subYears,
} from 'date-fns';

const datetimeRanges = [
	{
		label: 'Today',
		range: () => ({
			startDate: startOfDay(new Date()),
			endDate: endOfDay(new Date()),
		}),
	},
	{
		label: 'Yesterday',
		range: () => ({
			startDate: startOfDay(addDays(new Date(), -1)),
			endDate: endOfDay(addDays(new Date(), -1)),
		}),
	},
	{
		label: 'This Week',
		range: () => ({
			startDate: startOfWeek(new Date()),
			endDate: endOfDay(new Date()),
		}),
	},
	{
		label: 'This Month',
		range: () => ({
			startDate: startOfMonth(new Date()),
			endDate: endOfDay(new Date()),
		}),
	},
	{
		label: 'Last 3 Months',
		range: () => ({
			startDate: subMonths(new Date(), 3),
			endDate: endOfDay(new Date()),
		}),
	},
	{
		label: 'Last 6 Months',
		range: () => ({
			startDate: subMonths(new Date(), 6),
			endDate: endOfDay(new Date()),
		}),
	},
	{
		label: 'Last 1 year',
		range: () => ({
			startDate: subYears(new Date(), 1),
			endDate: endOfDay(new Date()),
		}),
	},
	{
		label: 'Last 3 years',
		range: () => ({
			startDate: subYears(new Date(), 3),
			endDate: endOfDay(new Date()),
		}),
	},
	{
		label: 'Last 5 years',
		range: () => ({
			startDate: subYears(new Date(), 5),
			endDate: endOfDay(new Date()),
		}),
	},
];
/**
 * Applicable filters.
 * NB:
 * 	- Each section coresponds to a filter in the API endpoint
 * 	- title acts as the key of each section, must be unique
 * Format:
	[
		{title: 'SECTION_TITLE', type: 'checkbox|switch', options: [{label: 'xxx', value: 'xxx'}]},
		{title: 'SECTION_TITLE', type: 'checkbox|switch', options: [{label: 'xxx', value: 'xxx'}]},
	]
 */
export const FILTERS = [
	{
		title: 'Domain',
		searchableKey: 'domain',
		type: 'select',
		multiple: true,
		width: 160, // to make the alignment of each option item
		options: [
			{
				label: 'aryzta',
				value: 'aryzta',
			},
			{
				label: 'centra',
				value: 'centra',
			},
			{
				label: 'centrani',
				value: 'centrani',
			},
			{
				label: 'daybreak',
				value: 'daybreak',
			},
			{
				label: 'demo',
				value: 'demo',
			},
			{
				label: 'eurospar',
				value: 'eurospar',
			},
			{
				label: 'interactivedisplays',
				value: 'interactivedisplays',
			},
			{
				label: 'londis',
				value: 'londis',
			},
			{
				label: 'mace',
				value: 'mace',
			},
			{
				label: 'maceni',
				value: 'maceni',
			},
			{
				label: 'spar',
				value: 'spar',
			},
			{
				label: 'startle',
				value: 'startle',
			},
			{
				label: 'supermacs',
				value: 'supermacs',
			},
			{
				label: 'supervalu',
				value: 'supervalu',
			},
			{
				label: 'supervaluni',
				value: 'supervaluni',
			},
		],
	},
	{
		title: 'Orientation',
		searchableKey: 'orientation',
		type: 'select',
		multiple: false,
		width: 160,
		options: [
			{
				label: 'portrait',
				value: 'portrait',
			},
			{
				label: 'landscape',
				value: 'landscape',
			},
		],
	},
	{
		title: 'Status',
		searchableKey: 'status',
		type: 'select',
		multiple: false,
		width: 160, // to make the alignment of each option item
		options: [
			{
				label: 'ACTIVE',
				value: 'ACTIVE',
			},
			{
				label: 'INACTIVE',
				value: 'INACTIVE',
			},
			{
				label: 'NOT ACTIVATED',
				value: 'NEVER_SEEN',
			},
		],
	},
	// NB: For datetime or date, in case the search filter name is XYZFrom and XYZTo, the searchableKey should be set to "XYZ"
	{
		title: 'Created At',
		searchableKey: 'created',
		type: 'date',
		options: datetimeRanges,
	},
	{
		title: 'Updated At',
		searchableKey: 'updated',
		type: 'date',
		options: datetimeRanges,
	},
	{
		title: 'Last Connected',
		searchableKey: 'lastConnected',
		type: 'date',
		options: datetimeRanges,
	},
];
