import React from 'react';

import PropTypes from 'prop-types';
// import cx from 'classnames';
import { makeStyles } from '@mui/styles';
import EditDomainSettingsTable from '../EditDomainSettingsTable/EditDomainSettingsTable';
import EditDomainSettingsFilters from '../EditDomainSettingsFilters/EditDomainSettingsFilters';
import { _ } from 'utils/libHelper';

// MUI components
import { Backdrop, CircularProgress } from '@mui/material';

// intl lang
import { useIntl } from 'react-intl';

// custom hooks
// import useColumnsConfig from '../../../Hooks/useColumnsConfig';
import useColumnsConfig from '../../Hooks/useColumnsConfig';

// redux
import { connect } from 'react-redux';
import { editDomainSettings } from 'redux/actions'; // actions
// constants
import { MAX_UL_EDIT_SETTINGS } from '../../Constants';
const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(1),
		// paddingBottom: theme.spacing(1),
		position: 'relative',
		width: '100%',
		height: '100%',
		overflow: 'auto',
		display: 'grid',
		gridTemplateRows:
			'minmax(30px, max-content) minmax(30px, max-content) minmax(30px, max-content)',
		gridGap: theme.spacing(1, 1),
		gridTemplateAreas: `
		"filter"
		"content"
		"pagination"
		`,
	},
	filter: {
		gridArea: 'filter',
		// marginBottom: theme.spacing(1),
	},
	content: {
		gridArea: 'content',
		// position: 'relative',
		overflow: 'auto',
	},
	pagination: {
		gridArea: 'pagination',
		// marginTop0: theme.spacing(1),
	},
	backdropLoading: {
		zIndex: 999,
		position: 'absolute',
		// alignItems: 'flex-start',
		backgroundColor: 'rgba(0, 0, 0, 0.2)',
		// color: theme.palette.primary.main,
		borderRadius: 4,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	// loadingCircularProgress: {
	// 	position: 'sticky',
	// 	top: 'calc(50% - 20px)',
	// },
}));

function EditDomainSettingsList({ domainSettings, editDomainSettings, userLevel, ...rest }) {
	const classes = useStyles();
	const intl = useIntl();
	const [sortBy, setSortBy] = React.useState([]);
	const [domainSettingVal, setDomainSettingVal] = React.useState([]);

	const [isLoading, setIsLoading] = React.useState(false);

	const [searchKeywords, setSearchKeywords] = React.useState('');

	// Function to apply filters
	const applyFilters = (settings, keywords, userLevel) => {
		let filteredSettings = JSON.parse(JSON.stringify(settings));
		if (userLevel !== MAX_UL_EDIT_SETTINGS) {
			filteredSettings = filteredSettings.filter((x) => x.settingMinUserLevel <= userLevel);
		}
		if (keywords !== '') {
			filteredSettings = filteredSettings.filter((row) => {
				const matchesMinUserLevel = row.settingMinUserLevel <= MAX_UL_EDIT_SETTINGS;
				const matchesKeywords = row.key.toLowerCase().includes(keywords.toLowerCase());
				return matchesMinUserLevel && matchesKeywords;
			});
		}
		return filteredSettings;
	};
	// handle setting search keywords
	const handleSearchKeywords = (keywords) => {
		setSearchKeywords(keywords);
		const filteredSettings = applyFilters(domainSettings.settings, keywords, userLevel);
		setDomainSettingVal(filteredSettings);
	};

	// handle sortBy updates with delay
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSortByWithDelay = React.useCallback(
		_.debounce((newSortBy) => {
			setSortBy((prevSortBy) =>
				prevSortBy[0]?.id !== newSortBy[0]?.id || prevSortBy[0]?.desc !== newSortBy[0]?.desc
					? newSortBy
					: prevSortBy
			);
			// setPageIndex(0);
		}, 600),
		[]
	);

	const handlePatchDomainSettings = React.useCallback(
		(domainData, patchDomainSettingVal) => {
			return editDomainSettings(
				{ reqData: { ...domainData, ...patchDomainSettingVal } },
				({ error }) => {
					if (!error) {
						setDomainSettingVal((prevDomainSettings) =>
							prevDomainSettings.map((settingVal) => {
								if (settingVal.key === domainData.key) {
									return { ...settingVal, ...patchDomainSettingVal };
								} else {
									return settingVal;
								}
							})
						);
					}
				}
			);
		},
		[editDomainSettings]
	);
	// Get columns configure by use hook.
	// NB: parameter passed to useColumnsConfig must be memorized
	// it is used for sorting selection in filter component, and used as column configure in table view component
	const columnsConfigure = useColumnsConfig({
		handlePatchDomainSettings,
		allowEdit: true,
		showMinUserLevel: userLevel >= MAX_UL_EDIT_SETTINGS,
	});

	// GET api integration.
	React.useEffect(() => {
		if (domainSettings.settings) {
			setIsLoading(false);
			const filteredSettings = applyFilters(domainSettings.settings, searchKeywords, userLevel);
			setDomainSettingVal(filteredSettings);
		}
	}, [domainSettings, searchKeywords, userLevel]);
	React.useEffect(() => {
		// initialize redux user data
		function descendingComparator(a, b, orderBy) {
			if (b[orderBy] < a[orderBy]) {
				return -1;
			}
			if (b[orderBy] > a[orderBy]) {
				return 1;
			}
			return 0;
		}
		function getComparator(order, orderBy) {
			return order === 'desc'
				? (a, b) => descendingComparator(a, b, orderBy)
				: (a, b) => -descendingComparator(a, b, orderBy);
		}
		function stableSort(array, comparator) {
			const stabilizedThis = array.map((el, index) => [el, index]);
			stabilizedThis.sort((a, b) => {
				const order = comparator(a[0], b[0]);
				if (order !== 0) {
					return order;
				}
				return a[1] - b[1];
			});
			return stabilizedThis.map((el) => el[0]);
		}
		if (sortBy.length !== 0) {
			setDomainSettingVal((prev) => {
				return stableSort(prev, getComparator(sortBy[0].desc ? 'desc' : 'asc', sortBy[0].id));
			});
		}
	}, [sortBy]);

	return (
		<div className={classes.root}>
			<div className={classes.filter}>
				<EditDomainSettingsFilters
					searchKeywords={searchKeywords}
					setSearchKeywords={handleSearchKeywords}
				/>
			</div>
			<div className={classes.content}>
				{
					// <Backdrop className={classes.backdropLoading} open={isLoading}>
					// 	<CircularProgress color="primary" size={65} thickness={5.5} />
					// </Backdrop>
				}
				<EditDomainSettingsTable
					domainSettingVal={domainSettingVal}
					columnsConfigure={columnsConfigure}
					appliedSortBy={sortBy}
					handleSortBy={handleSortByWithDelay}
					noDataMsg={
						isLoading
							? intl.formatMessage({
									id: 'ReactTable.LoadingText',
							  })
							: intl.formatMessage({
									id: 'pages.MyFiles.components.MyFilesList.NoFilesMsg',
							  })
					}
				/>
			</div>
			{/* <div className={classes.pagination}>
				<MUITablePagination
					// pagination props
					handleChangePage={handleChangePage}
					handleChangeRowsPerPage={handleChangeRowsPerPage}
					rowsPerPage={itemsPerPage}
					disableRowsPerPage={false}
					pageIndex={pageIndex}
					totalNumRows={totalNumItems}
					labelRowsPerPage={intl.formatMessage({
						id: 'pages.UserMessages.components.UserMessages.ItemsPerPageLabel',
					})}
				/>
			</div> */}
			{/** Circular progress loader */}
			<Backdrop className={classes.backdropLoading} open={isLoading}>
				<CircularProgress color="primary" size={65} thickness={5.5} />
			</Backdrop>
		</div>
	);
}
EditDomainSettingsList.propTypes = {
	classes: PropTypes.object,
	// below are redux actions
	editDomainSettings: PropTypes.func.isRequired,
	// below are redux state
	domainSettings: PropTypes.object.isRequired,
	userLevel: PropTypes.number.isRequired,
};

EditDomainSettingsList.defaultProps = {};

const mapStateToProps = (state) => {
	return {
		domainSettings: state.domainSettings,
		userLevel: state.authentication.userLevel,
	};
};

export default connect(mapStateToProps, {
	editDomainSettings,
})(EditDomainSettingsList);
