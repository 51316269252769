import React from 'react';
// import cx from 'classnames';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

// Constants
// import { ART_VARIABLES } from '../../Constants';

import {
	Typography,
	IconButton,
	DialogTitle,
	DialogContent,
	DialogActions,
	// TablePagination,
	// // CircularProgress,
	// Card,
	// CardContent,
	// Avatar,
	Button,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	// Pagination,
} from '@mui/material';
// import Pagination from '@mui/lab/Pagination';
import { Close as CloseIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
// import { deepOrange } from '@mui/material/colors';

// import SVGPreview from '../SVGPreview/SVGPreview';

import { convertS3FileParamsToS3Url } from 'utils/appHelper.js';
import { openPrintDialogForPdf } from 'utils/libHelper.js';
import { getCloudFrontUrlOfS3File } from 'utils/artwork/artUtilsCommon.js';
import { fileMgrFetchSSById, awsCreateS3Client, logEsignGeneration } from 'restful';
import {
	printArtwork,
	printArtworkFullServerSide,
	convertSSContentToRTableData,
	buildPrintData,
	getPrintFileName,
	getNumOfItemsInPage,
} from './esignUtils.jsx';

// custom components
import { DraggableMUIDialog } from 'components';

// intl lang
import { useIntl } from 'react-intl';

import { isFirefox, isChrome, isEdge } from 'react-device-detect';
import printInstructionFirefoxPng from './printDialogFirefox.png';
import printInstructionEdgePng from './printDialogEdge.png';
import printInstructionChromePng from './printDialogChrome.png';

const useStyles = makeStyles((theme) => ({
	title: {
		margin: 0,
		padding: theme.spacing(2),
		cursor: 'move',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	accordion: {
		marginTop: theme.spacing(1),
	},
	// accordionHeading: {
	// 	fontSize: theme.typography.pxToRem(15),
	// 	fontWeight: theme.typography.fontWeightRegular,
	// },
	pdfActionsWrapper: {
		display: 'flex',
		flexWrap: 'wrap',
		// flexDirection: 'column',
	},
	pdfActionRow: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		padding: theme.spacing(1),
	},
	pdfActionBtnGroup: {
		flex: '1 0 auto',
		justifyContent: 'flex-end',
		display: 'flex',
	},
	pdfActionBtn: {
		marginLeft: theme.spacing(0.5),
		marginRight: theme.spacing(0.5),
	},
	// flexBreakHor: {
	// 	// works when flexDirection is "row"
	// 	flexBasis: '100%',
	// },
	// flexBreakVer: {
	// 	// works when flexDirection is "column"
	// 	flexBasis: '100%',
	// 	width: 0,
	// },
}));

function PrintDialog({
	open,
	adjustOutput,
	printData,
	ssContent,
	userId,
	userEmail,
	artworkExtra,
	setExportArtworkStatus,
	handleClose,
	// notifyGeneral,
	activeArtTemplate,
	ssId,
	filters,
	searchKeywords,
	domainName,
}) {
	const classes = useStyles();
	const intl = useIntl();

	/**
	 * generatedPdfs contains generated pdf blobs per template. Format:
	 	  [
			 {
				 artTemplateId: 'xxxx',
				 name: 'xxxx', // template name
				 pdfWebUrl: weburl, // if this is available, pdfBlobUrl won't be used
				 errMsg: ERROR_MSG
				},
				...
			]
	 */
	const [generatedPdfs, setGeneratedPdfs] = React.useState([]);

	// ##############################
	// Side Effects
	// #############################
	React.useEffect(() => {
		setGeneratedPdfs([]);
	}, [open]);

	// ##############################
	// Helper functions
	// #############################
	/**
	 *
	 * @param {object} param
	 *
	 * @returns {object} s3FileParams
	 */
	const retrievePrintFile = ({ artTemplate, printFileName, s3Client }) => {
		let esignFileS3Conf = artTemplate.esignPrintFiles;
		let s3FileParams = {
			Bucket: esignFileS3Conf.s3Bucket,
			Key: esignFileS3Conf.s3PathPrefix + `/${artTemplate.mediaId}/${printFileName}.pdf`,
		};
		return s3Client
			.headObject(s3FileParams)
			.promise()
			.then(() => {
				// file exists
				return s3FileParams;
			});
	};

	// /**
	//  *
	//  * @param {object} param0
	//  * @returns {object} s3FileParams
	//  */
	// const uploadPrintFile = ({ artTemplate, printFileName, pdfBlob, s3Client }) => {
	// 	const MIN_S3_UPLOAD_PART = 6 * 1024 * 1024; // 6mb, minimum part size on uploading to avoid AWS S3 SDK error

	// 	let esignFileS3Conf = artTemplate.esignPrintFiles;
	// 	let s3FileParams = {
	// 		Bucket: esignFileS3Conf.s3Bucket,
	// 		Key: esignFileS3Conf.s3PathPrefix + `/${artTemplate.mediaId}/${printFileName}.pdf`,
	// 		Body: pdfBlob,
	// 		ContentType: 'application/pdf',
	// 		ContentLength: pdfBlob.size,
	// 	};
	// 	return s3Client
	// 		.upload(s3FileParams, {
	// 			partSize: Math.max(s3FileParams.ContentLength + 1 * 1024 * 1024, MIN_S3_UPLOAD_PART),
	// 			queueSize: 1,
	// 		})
	// 		.promise()
	// 		.then(() => {
	// 			return {
	// 				Bucket: esignFileS3Conf.s3Bucket,
	// 				Key: esignFileS3Conf.s3PathPrefix + `/${artTemplate.mediaId}/${printFileName}.pdf`,
	// 			};
	// 		});
	// };

	const createSearchResultData = ({
		// ssId,
		// filters,
		// searchKeywords,
		// activeArtTemplate,
		artTemplate,
	}) => {
		let selectedFilters = filters.filter((filter) => Boolean(filter.value));
		return fileMgrFetchSSById({
			ssId: ssId,
			queryParams: {
				...(searchKeywords
					? {
							searchString: searchKeywords,
							searchColumnIndices: activeArtTemplate.metadata.searchColumnIndices.join(','),
					  }
					: {}),
				...(selectedFilters.length > 0
					? {
							esignFilterIndices: selectedFilters.map((filter) => filter.id).join('|'),
							esignFilterValues: selectedFilters
								.map((filter) => encodeURIComponent(filter.value)) // using encodeURIComponent() to escape the special chars (particularly "|"). There is another time of encodeURIComponent() invoked by axios automatically
								.join('|'),
					  }
					: {}),
			},
		}).then((response) => {
			let searchedResultRows = convertSSContentToRTableData(response.data, {});
			let rowQty = searchedResultRows.reduce((accu, row) => {
				return { ...accu, [row.rowId]: 1 };
			}, {});
			let multiplePrintData = buildPrintData(searchedResultRows, rowQty, artTemplate);
			return multiplePrintData;
		});
	};

	// ##############################
	// Handling functions
	// #############################
	// eslint-disable-next-line no-unused-vars
	const handlePrintSearchResult = async (printArtTemplates) => {
		let s3Client = await awsCreateS3Client().catch(() => null); // ignore the error, because we can still try to create pdf for user without using s3
		let printFiles = []; // final print files

		for (let i = 0; i < printArtTemplates.length; i++) {
			let { artTemplate } = printArtTemplates[i];
			let s3FileParams = null,
				pdfUrl = null,
				printFileName = null;
			printFiles[i] = {
				artTemplateId: artTemplate.mediaId,
				name: artTemplate.name,
				pdfWebUrl: null,
				errMsg: null,
			};
			// 1. try to retrieve the file from s3
			setExportArtworkStatus({
				status: 'OK',
				message: `(${artTemplate.name}) Retrieve generated print file...`,
			});

			printFileName = getPrintFileName({
				cacheId: artTemplate.esignPrintFiles.cacheId,
				artTemplateId: artTemplate.mediaId,
				ssId: ssId,
				filters: filters,
				searchKeywords: searchKeywords,
				adjustOutput: adjustOutput,
			});
			if (s3Client) {
				s3FileParams = await retrievePrintFile({ artTemplate, printFileName, s3Client }).catch(
					() => null
				);
			}
			if (s3FileParams) {
				// convert s3FileParams to web url
				printFiles[i].pdfWebUrl = getCloudFrontUrlOfS3File(s3FileParams);
				continue;
			}

			// 2. file doesn't exist, let's generate it
			setExportArtworkStatus({
				status: 'OK',
				message: `(${artTemplate.name}) Generating print file...`,
			});
			let esignFileS3Conf = artTemplate.esignPrintFiles;
			try {
				let multiplePrintData = await createSearchResultData({ artTemplate });
				pdfUrl = await printArtwork({
					multiplePrintData,
					artworkExtra,
					setExportArtworkStatus,
					artTemplate,
					adjustOutput,
					domainName,
					outputFileS3Url: convertS3FileParamsToS3Url({
						Bucket: esignFileS3Conf.s3Bucket,
						Key: esignFileS3Conf.s3PathPrefix + `/${artTemplate.mediaId}/${printFileName}.pdf`,
					}),
				});
			} catch (err) {
				// error of pdf generation
				let errMsg = err.response?.data.message || err.message;
				printFiles[i].errMsg = errMsg;
			}
			if (!pdfUrl) continue; // failed to generate the pdf, continue to next template

			// assign pdf cloudfront url
			printFiles[i].pdfWebUrl = pdfUrl;
		}

		setGeneratedPdfs(printFiles);
		setExportArtworkStatus({}); // finally, reset export status
	};

	const handlePrintSearchResultServerSide = async (
		printArtTemplates,
		ssContent,
		userId,
		userEmail
	) => {
		let s3Client = await awsCreateS3Client().catch(() => null); // ignore the error, because we can still try to create pdf for user without using s3
		let printFiles = []; // final print files
		const multipleTemplatesPrintData = []; // template print data that to be generated to pdf

		const logReqBodyForCachedEsign = [];
		for (let i = 0; i < printArtTemplates.length; i++) {
			let { artTemplate } = printArtTemplates[i];
			let s3FileParams = null,
				printFileName = null;
			// 1. try to retrieve the file from s3
			setExportArtworkStatus({
				status: 'OK',
				message: `(${artTemplate.name}) Retrieve generated print file...`,
			});

			printFileName = getPrintFileName({
				cacheId: artTemplate.esignPrintFiles.cacheId,
				artTemplateId: artTemplate.mediaId,
				ssId: ssId,
				filters: filters,
				searchKeywords: searchKeywords,
				adjustOutput: adjustOutput,
			});
			if (s3Client) {
				s3FileParams = await retrievePrintFile({ artTemplate, printFileName, s3Client }).catch(
					() => null
				);
			}

			let esignFileS3Conf = artTemplate.esignPrintFiles;
			let multiplePrintData = await createSearchResultData({ artTemplate }).catch((err) => {
				printFiles.push({
					artTemplateId: artTemplate.mediaId,
					name: artTemplate.name,
					errMsg: err.response?.data.message || err.message,
				});
				return null;
			});
			// multiplePrintData is null, we can't get the search data, we have already pushed the error message, so continue to next template
			if (multiplePrintData === null) continue;
			const isPrintAll =
				!searchKeywords && filters.filter((filter) => Boolean(filter.value)).length === 0;

			const printRows = isPrintAll
				? []
				: multiplePrintData.map((rowPrintData) => {
						return {
							rowId: rowPrintData.rowId,
							rowName: rowPrintData.rowName,
							quantity: rowPrintData.num,
						};
				  });

			// (VID-3986) Because we will log esign generation even it is cached, so we still need to retrieve the spreadsheet content
			// hence move this process down to here
			if (s3FileParams) {
				// esign file was generated already, convert s3FileParams to web url
				printFiles.push({
					artTemplateId: artTemplate.mediaId,
					name: artTemplate.name,
					pdfWebUrl: getCloudFrontUrlOfS3File(s3FileParams),
				});
				// (VID-3986) still log when the file already generated
				logReqBodyForCachedEsign.push({
					artTemplateId: artTemplate.mediaId,
					name: artTemplate.name,
					spreadsheetId: `${ssContent.id}`,
					spreadsheetName: ssContent.spreadsheetName,
					userId,
					userEmail,
					printRows,
					rowCount: multiplePrintData.length,
					printMode: isPrintAll ? 'PRINT_ALL' : 'PRINT_SEARCH_RESULT',
				});
				continue;
			}

			multipleTemplatesPrintData.push({
				multiplePrintData,
				artworkExtra,
				artTemplate,
				adjustOutput,
				domainName,
				outputFileS3Url: convertS3FileParamsToS3Url({
					Bucket: esignFileS3Conf.s3Bucket,
					Key: esignFileS3Conf.s3PathPrefix + `/${artTemplate.mediaId}/${printFileName}.pdf`,
				}),
				meta: {
					artTemplateId: artTemplate.mediaId,
					name: artTemplate.name,
					spreadsheetId: `${ssContent.id}`,
					spreadsheetName: ssContent.spreadsheetName,
					userId,
					userEmail,
					printRows,
					rowCount: multiplePrintData.length,
					printMode: isPrintAll ? 'PRINT_ALL' : 'PRINT_SEARCH_RESULT',
				},
			});
		}

		setExportArtworkStatus({
			status: 'OK',
			message: `Generating artwork pdf...`,
		});
		// now call api to generate pdf
		/**
		 * format of returned data from api call
		 * [
		 * 	{
		 * 		artTemplateId: templatePrintData.artTemplate.mediaId,
					name: templatePrintData.artTemplate.name,
					pdfWebUrl: pdfUrl,
					errMsg: err.response?.data.message || err.message,
		 * 	},
				...
			 ]
		 */
		printArtworkFullServerSide(multipleTemplatesPrintData)
			.then((artworkPdfs) => {
				setGeneratedPdfs([...printFiles, ...artworkPdfs]);
			})
			.catch((err) => {
				const artworkPdfs = multipleTemplatesPrintData.map((printData) => {
					return { ...printData.meta, errMsg: err.response?.data.message || err.message };
				});
				setGeneratedPdfs([...printFiles, ...artworkPdfs]);
			})
			.finally(() => {
				setExportArtworkStatus({}); // finally, reset export status
				if (logReqBodyForCachedEsign.length > 0) {
					// log cached esign no matter the generation is successful or failed,
					// because the cached esign file will be displayed to user
					// Note: ignore error of the request
					logEsignGeneration({ bodyParams: logReqBodyForCachedEsign }).catch(() => null);
				}
			});
	};

	// eslint-disable-next-line no-unused-vars
	const handlePrintSelections = (artTemplatesPrintData) => {
		// print selected rows
		return artTemplatesPrintData
			.reduce((promiseChain, templatePrintData) => {
				return promiseChain.then((accu) => {
					return printArtwork({
						multiplePrintData: templatePrintData.multiplePrintData,
						artworkExtra,
						setExportArtworkStatus,
						artTemplate: templatePrintData.artTemplate,
						adjustOutput,
						domainName,
					})
						.then((pdfUrl) => {
							accu.push({
								artTemplateId: templatePrintData.artTemplate.mediaId,
								name: templatePrintData.artTemplate.name,
								pdfWebUrl: pdfUrl,
							});
							return accu;
						})
						.catch((err) => {
							accu.push({
								artTemplateId: templatePrintData.artTemplate.mediaId,
								name: templatePrintData.artTemplate.name,
								errMsg: err.response?.data.message || err.message,
							});
							return accu;
						});
				});
			}, Promise.resolve([]))
			.then((printPdfs) => {
				setGeneratedPdfs(printPdfs);
				setExportArtworkStatus({}); // finally, reset export status
			});
	};

	const handlePrintSelectionsServerSide = async (
		artTemplatesPrintData,
		ssContent,
		userId,
		userEmail
	) => {
		// print selected rows of all templates
		const multipleTemplatesPrintData = artTemplatesPrintData.map((templatePrintData) => {
			const printRows = templatePrintData?.multiplePrintData.map((rowPrintData) => {
				return {
					rowId: rowPrintData.rowId,
					rowName: rowPrintData.rowName,
					quantity: rowPrintData.num,
				};
			});
			return {
				multiplePrintData: templatePrintData?.multiplePrintData,
				artworkExtra,
				artTemplate: templatePrintData?.artTemplate,
				adjustOutput,
				domainName,
				meta: {
					artTemplateId: templatePrintData?.artTemplate.mediaId,
					name: templatePrintData?.artTemplate.name,
					spreadsheetId: `${ssContent.id}`,
					spreadsheetName: ssContent.spreadsheetName,
					userId,
					userEmail,
					printRows,
					rowCount: templatePrintData?.multiplePrintData.length,
					printMode: 'PRINT_SELECTIONS',
				},
			};
		});

		setExportArtworkStatus({
			status: 'OK',
			message: `Generating artwork pdf...`,
		});
		/**
		 * format of returned data from api call
		 * [
		 * 	{
		 * 		artTemplateId: templatePrintData.artTemplate.mediaId,
					name: templatePrintData.artTemplate.name,
					pdfWebUrl: pdfUrl,
					errMsg: err.response?.data.message || err.message,
		 * 	},
				...
			 ]
		 */
		printArtworkFullServerSide(multipleTemplatesPrintData)
			.then((printPdfs) => {
				setGeneratedPdfs(printPdfs);
			})
			.catch((err) => {
				const printPdfs = multipleTemplatesPrintData.map((printData) => {
					return { ...printData.meta, errMsg: err.response?.data.message || err.message };
				});
				setGeneratedPdfs(printPdfs);
			})
			.finally(() => {
				setExportArtworkStatus({}); // finally, reset export status
			});
	};

	return (
		<DraggableMUIDialog
			disableBackdropClick
			disableEscapeKeyDown
			open={open}
			maxWidth="sm"
			fullWidth={true}
			scroll="paper"
			onClose={handleClose}
			dragHandle={`.${classes.title}`}
		>
			<DialogTitle className={classes.title}>
				{printData.mode === 'PRINT_ALL'
					? intl.formatMessage(
							{ id: 'pages.Artwork.components.Esign.PrintAllDialogTitle' },
							{ templateName: printData.selectedArtTemplates[0].artTemplate.name }
					  )
					: printData.mode === 'PRINT_SELECTIONS'
					? intl.formatMessage({ id: 'pages.Artwork.components.Esign.PrintSelectionsDialogTitle' })
					: printData.mode === 'PRINT_SEARCH_RESULT'
					? intl.formatMessage({
							id: 'pages.Artwork.components.Esign.PrintSearchResultDialogTitle',
					  })
					: ''}
				<IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			{generatedPdfs.length === 0 && open && (
				<DialogContent dividers>
					<Typography variant="body1">
						Are you sure you want to print{' '}
						{printData.mode === 'PRINT_ALL'
							? `all ${printData.totalItems}`
							: `the ${printData.totalItems}`}{' '}
						items?
					</Typography>
					{printData.mode === 'PRINT_ALL' && (
						<Typography variant="body1">
							You will need{' '}
							<strong>
								{Math.ceil(
									printData.totalItems /
										(adjustOutput !== 0
											? getNumOfItemsInPage({
													artTemplate: printData.selectedArtTemplates[0].artTemplate,
													adjustOutput,
											  })
											: printData.selectedArtTemplates[0].artTemplate.metadata.numOfItemsInPage)
								)}
							</strong>{' '}
							sheets of paper.
						</Typography>
					)}
					{(printData.mode === 'PRINT_SELECTIONS' || printData.mode === 'PRINT_SEARCH_RESULT') && (
						<ul>
							{printData.selectedArtTemplates.map((templatePrintData) => {
								return (
									<li
										key={`number-sheets-paper-${templatePrintData.artTemplate.mediaId}`}
										style={{ fontSize: '0.8rem' }}
									>
										{templatePrintData.artTemplate.name}:{' '}
										<strong>
											{Math.ceil(
												printData.totalItems /
													(adjustOutput !== 0
														? getNumOfItemsInPage({
																artTemplate: templatePrintData.artTemplate,
																adjustOutput,
														  })
														: templatePrintData.artTemplate.metadata.numOfItemsInPage)
											)}
										</strong>{' '}
										sheets of paper
									</li>
								);
							})}
						</ul>
					)}
					<Accordion className={classes.accordion} defaultExpanded={true}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel2a-content"
							id="panel2a-header"
						>
							<Typography /* className={classes.accordionHeading} */>Print Instructions</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography variant="body2">
								{isEdge ? (
									<div>
										<strong>IMPORTANT:</strong> In the{' '}
										<em>
											<strong>Print</strong>
										</em>{' '}
										window, under{' '}
										<em>
											<strong>More settings</strong>
										</em>
										, make sure that the {`"Scale (%)"`} button is set to{' '}
										<em>
											<strong>Actual Size</strong>
										</em>{' '}
										as per the example below:
									</div>
								) : null}
								{isFirefox ? (
									<div>
										<strong>IMPORTANT:</strong> In the{' '}
										<em>
											<strong>Print</strong>
										</em>{' '}
										window, under{' '}
										<em>
											<strong>More settings</strong>
										</em>
										, make sure that the {`"Scale"`} button is set to {`"Scale"`}
										with a value of{' '}
										<em>
											<strong>100</strong>
										</em>{' '}
										as per the example below:
									</div>
								) : null}
								{isChrome ? (
									<div>
										<strong>IMPORTANT:</strong> In the{' '}
										<em>
											<strong>Print</strong>
										</em>{' '}
										window, under{' '}
										<em>
											<strong>More settings</strong>
										</em>
										, make sure that the dropdown {`"Scale"`} is set to{' '}
										<em>
											{' '}
											<strong>Custom</strong>
										</em>{' '}
										with a value of{' '}
										<em>
											<strong>100</strong>
										</em>{' '}
										as per the example below:
									</div>
								) : null}
							</Typography>
							{isEdge ? (
								<img
									src={printInstructionEdgePng}
									alt="print instruction"
									style={{ paddingLeft: 8 }}
								/>
							) : null}
							{isFirefox ? (
								<img
									src={printInstructionFirefoxPng}
									alt="print instruction"
									style={{ paddingLeft: 8 }}
								/>
							) : null}
							{isChrome ? (
								<img
									src={printInstructionChromePng}
									alt="print instruction"
									style={{ paddingLeft: 8 }}
								/>
							) : null}
						</AccordionDetails>
					</Accordion>
				</DialogContent>
			)}
			{generatedPdfs.length > 0 && (
				<DialogContent dividers>
					<div className={classes.pdfActionsWrapper}>
						{generatedPdfs.map((templatePdf, idx) => {
							let pdfUrl = templatePdf.pdfWebUrl;

							return (
								<div key={`pdf-actions-${idx}`} className={classes.pdfActionRow}>
									<Typography>{`${templatePdf.name}:`}</Typography>
									{pdfUrl && (
										<div className={classes.pdfActionBtnGroup}>
											<Button
												className={classes.pdfActionBtn}
												// component="a"
												size="small"
												variant="contained"
												color="primary"
												onClick={() => openPrintDialogForPdf(pdfUrl)}
											>
												Print Pdf
											</Button>
											{
												// 	<Button
												// 	className={classes.pdfActionBtn}
												// 	// component="a"
												// 	size="small"
												// 	variant="contained"
												// 	color="primary"
												// 	onClick={() => {
												// 		triggerDownload(pdfUrl, [
												// 			{ name: 'download', value: `${templatePdf.name}.pdf` },
												// 			{ name: 'target', value: 'download' },
												// 		]);
												// 	}}
												// >
												// 	Download Pdf
												// </Button>
											}
											<Button
												className={classes.pdfActionBtn}
												component="a"
												href={pdfUrl}
												target="_blank"
												size="small"
												variant="contained"
												color="primary"
											>
												Open Pdf
											</Button>
										</div>
									)}
									{templatePdf.errMsg && (
										<Typography color="error">{templatePdf.errMsg}</Typography>
									)}
								</div>
							);
						})}
					</div>
				</DialogContent>
			)}
			<DialogActions>
				<Button onClick={handleClose} size="small" variant="contained">
					{intl.formatMessage({ id: 'GENERAL.Cancel' })}
				</Button>

				{generatedPdfs.length === 0 && (
					<Button
						// disabled={generatedPdfs.length > 0}
						onClick={async () => {
							if (printData.mode === 'PRINT_SELECTIONS') {
								// print selected rows
								// handlePrintSelections(printData.selectedArtTemplates);
								handlePrintSelectionsServerSide(
									printData.selectedArtTemplates,
									ssContent,
									userId,
									userEmail
								);
							} else {
								// print searched/all rows
								// handlePrintSearchResult(printData.selectedArtTemplates);
								handlePrintSearchResultServerSide(
									printData.selectedArtTemplates,
									ssContent,
									userId,
									userEmail
								);
							}
						}}
						size="small"
						variant="contained"
						color="primary"
					>
						{intl.formatMessage({ id: 'GENERAL.Ok' })}
					</Button>
				)}
			</DialogActions>
		</DraggableMUIDialog>
	);
}

PrintDialog.propTypes = {
	// printMode: PropTypes.oneOf(['', 'PRINT_ALL', 'PRINT_SELECTIONS']).isRequired,
	// title: PropTypes.string.isRequired,
	// ssId: PropTypes.string.isRequired,
	// artTemplate: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	adjustOutput: PropTypes.number,
	/**
	 * printData contains ready-to-print data. Format:
	 	{
			mode: ''|'PRINT_ALL'|'PRINT_SELECTIONS'|'PRINT_SEARCH_RESULT',
			totalItems: NUMBER,
			selectedArtTemplates: [{
					artTemplate,
					multiplePrintData, // multiplePrintData is mandatary in PRINT_SELECTIONS mode
				},
				...
			],
		}
	 */
	printData: PropTypes.object.isRequired,
	artworkExtra: PropTypes.object.isRequired,
	setExportArtworkStatus: PropTypes.func.isRequired,
	handleClose: PropTypes.func.isRequired,
	// notifyGeneral: PropTypes.func.isRequired,
	activeArtTemplate: PropTypes.object.isRequired,
	ssId: PropTypes.string.isRequired,
	/**
	 * ssContent is response of Get /spreadsheets/{spreadsheetId} in filemanager api
{
  "id": 32,
  "spreadsheetName": "Confectionery Master File.xls",
  "spreadsheetTable": "zss_11_2019_04_16_08_58_32_confectionery_master_file_xls",
  "recordsTotal": 1025,
  "totalFound": 12,
  "columnHeaders": [
    {
      "columnId": "$ref:4",
      "type": "text",
      "refCol": "2",
      "columnName": "product_description"
    }
  ],
  "rows": [
    {
      "rowId": 0,
      "rowData": [
        {
          "columnId": "4",
          "value": "Cadbury Creme Egg",
          "mediafileId": "23554",
          "previewUrl": "https://apigw.visualid.com/preview/v1/preview/?redirect=true&prekey=XTEh1eydHiEv6nKxfj1u4HjCHw1Y5X16&fileurl=s3://visualid-mediafiles/spar/20200402/rXDNeoFViSzXf12zklqdcOUoAI1S/ySDRCdINR4BK.jpg&size=small",
          "optimisedUrl": "https://images.visualid.com/spar/20200408/91MeswFQnb486k3GxrAcj1GOu1t6/dv4Bt3nRPJUN.jpg",
          "highResUrl": "https://images.visualid.com/spar/20200408/91MeswFQnb486k3GxrAcj1GOu1t6/dv4Bt3nRPJUN.jpg"
        }
      ]
    }
  ]
}
	 */
	ssContent: PropTypes.object.isRequired,
	userId: PropTypes.string.isRequired,
	userEmail: PropTypes.string.isRequired,
	filters: PropTypes.array.isRequired,
	searchKeywords: PropTypes.string.isRequired,
	domainName: PropTypes.string.isRequired,
};

PrintDialog.defaultProps = { adjustOutput: 0 };

export default PrintDialog;
